import { Skeleton } from '@/components/ui/skeleton';

function InstructionsSkelet() {
  return (
    <div className="flex flex-col">
      <Skeleton className="w-28 h-4 mb-3 rounded-sm mt-0.5" />
      <div className="flex gap-5">
        <Skeleton className="w-56 rounded-md h-28" />
        <Skeleton className="w-56 rounded-md h-28" />
      </div>
      <div className="flex flex-col gap-0.5 mt-5">
        <Skeleton className="w-28 h-4 mb-3 rounded-sm mt-0.5" />
        <Skeleton className="w-full h-10 rounded-md" />
      </div>
      <div className="flex flex-col gap-0.5 mt-5">
        <Skeleton className="w-28 h-4 mb-3 rounded-sm mt-0.5" />
        <Skeleton className="w-full h-10 rounded-md" />
      </div>
      <div className="flex flex-col gap-0.5 mt-5">
        <Skeleton className="w-28 h-4 mb-3 rounded-sm mt-0.5" />
        <Skeleton className="w-full h-[112px] rounded-md" />
      </div>
      <div className="flex flex-col gap-0.5 mt-9">
        <Skeleton className="h-4 mt-2 mb-3 rounded-sm w-28" />
        <Skeleton className="w-full h-[112px] rounded-md" />
      </div>
      <div className="flex flex-col gap-0.5 mt-9">
        <Skeleton className="w-28 h-4 mb-3 rounded-sm mt-0.5" />
        <Skeleton className="w-full h-10 rounded-md" />
      </div>
    </div>
  );
}

export default InstructionsSkelet;

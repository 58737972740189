/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ArrowUpDown } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import { Button } from '@/components/ui/button';
import { ECategoryMode } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import { cn } from '@/lib/utils';
import useCategoryStore from '@/store/CategoryStore';
import useResultTabStore from '@/store/ResultsTabStore';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import SingleCategoryCell from './SingleCategoryCell';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import MultipleCategoryCell from './MultipleCategoryCell';

interface ICategory {
  label: string;
  color: string;
  prompt: string;
}

export interface IProject {
  gender?: string;
  age?: number;
  started: string;
  questions: string;
  conversation_id: string;
  category: ICategory | ICategory[];
}

export const QuestionAnswersColumns = (
  isPanel: boolean,
  categoryDataSingle: any,
  questionID: string | undefined,
  categoryDataMultiple: any,
  isCategoryDataExists: boolean,
  areAllCategoriesZero: boolean,
  project_id?: string,
  workspace_id?: string,
) => {
  const { lang } = useTranslation();
  const setActiveTab = useResultTabStore((state) => state.setActiveTab);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('fid');
  const categoryType = useCategoryStore((state) => state.categoryType);
  const { isUserViewer } = useWorkspaceRoles(workspace_id);

  const [loadingRowId, setLoadingRowId] = useState<string | null>(null);

  const handleRowLoading = (rowId: string) => {
    setLoadingRowId(rowId);
  };

  const stopRowLoading = () => {
    setLoadingRowId(null);
  };

  const setOpacity = (hex: string, alpha: number): string => {
    const alphaHex = Math.floor(alpha * 255).toString(16).padStart(2, '0');
    return `${hex}${alphaHex}`;
  };

  const showConversation = (conversation_id: string) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate(filter_id ? `?fid=${filter_id}&for=${conversation_id}` : `?for=${conversation_id}`);
    setActiveTab('transcripts_tab');
  };

  let columns: ColumnDef<IProject>[] = [
    {
      accessorKey: 'category',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.category')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => {
        if (!categoryType) return '/';
        const rowId = row.id;

        if (loadingRowId === rowId) {
          <IconLoader2 className="m-auto text-primary animate-spin" size={24} />;
        }

        if (categoryType === ECategoryMode.SINGLE) {
          const category = row.original?.category as ICategory;

          return (
            <div className="w-full h-full pb-5">
              {!isUserViewer && isCategoryDataExists && !areAllCategoriesZero && (
                <SingleCategoryCell
                  categories={category}
                  rowId={rowId}
                  categoryData={categoryDataSingle}
                  questionID={questionID}
                  conversation_id={row?.original?.conversation_id}
                  project_id={project_id}
                  workspace_id={workspace_id}
                  onLoadingStart={() => handleRowLoading(rowId)}
                  onLoadingEnd={stopRowLoading}
                />
              )}

              <TooltipWrapper
                text={category?.prompt}
                className="relative mb-1 max-w-[280px] text-center flex"
              >
                <div
                  style={{
                    backgroundColor: `${setOpacity(category?.color, 0.7)}`,
                  }}
                  className="flex items-center justify-center rounded-full px-4 pt-0.5"
                >
                  <p className="font-semibold text-black max-w-[90px] truncate">
                    {category?.label || '/'}
                  </p>
                </div>
              </TooltipWrapper>
            </div>
          );
        }

        if (categoryType === ECategoryMode.MULTIPLE) {
          const categories = row.original?.category as ICategory[];

          return (
            <div className="relative">
              {!isUserViewer && isCategoryDataExists && !areAllCategoriesZero && (
                <MultipleCategoryCell
                  categories={categories}
                  categoryData={categoryDataMultiple}
                  questionID={questionID}
                  conversation_id={row?.original?.conversation_id}
                  project_id={project_id}
                  workspace_id={workspace_id}
                  onLoadingStart={() => handleRowLoading(rowId)}
                  onLoadingEnd={stopRowLoading}
                />
              )}
              {React.Children.toArray(categories.map((item) => (
                <div className="relative w-full h-full">
                  <TooltipWrapper
                    key={item?.label}
                    text={item?.prompt}
                    className="relative mb-1 max-w-[280px] text-center flex"
                  >
                    <div
                      style={{
                        backgroundColor: `${setOpacity(item?.color, 0.7)}`,
                      }}
                      className="flex items-center justify-center rounded-full px-4 py-0.5 mb-2"
                    >
                      <p className="font-semibold text-black max-w-[90px] truncate">
                        {item?.label || '/'}
                      </p>
                    </div>
                  </TooltipWrapper>
                </div>
              )))}
            </div>
          );
        }

        return '/';
      },
    },
    {
      accessorKey: 'started',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.started')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div>
          <p className="whitespace-nowrap">{dayjs(row.getValue('started')).format('YYYY.MM.DD - HH:mm')}</p>
        </div>
      ),
    },
    {
      accessorKey: 'questions',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.questions')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex flex-col items-center justify-between p-0 transition-all ease-in-out">
          <div className="flex flex-col w-full gap-5">
            <div className="flex flex-col w-full gap-5 max-w-[90%]">
              <div className="flex flex-col gap-1 font-medium text-dark-text/80">
                {React.Children.toArray(
                  row?.original?.questions
                    .split('\n')
                    ?.map((line) => {
                      const parts = line.split(/(Assistant:|User:)/);
                      return (
                        <p className="font-medium text-dark-text/80">
                          {parts?.map((part, index) => {
                            if (index % 2 === 1) {
                              return (
                                <span
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  className={cn(
                                    'font-bold',
                                    part.includes('Assistant') ? 'text-purple-500' : 'text-primary',
                                  )}
                                >
                                  {`${parts[index - 1]}${part}`}
                                </span>
                              );
                            }
                            return part;
                          })}
                          <br />
                        </p>
                      );
                    }),
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 'actions',
      maxSize: 130,
      enableHiding: false,
      cell: ({ row }) => (
        <div className="flex justify-end w-full">
          <Button
            variant="default"
            onClick={() => showConversation(row?.original?.conversation_id)}
          >
            Conversation
          </Button>
        </div>
      ),
    },
  ];

  if (isPanel) {
    columns = [
      {
        accessorKey: 'gender',
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="pl-0.5"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            {lang.get('msg.gender')}
            <ArrowUpDown className="w-4 h-4 ml-2" />
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            <p className="mr-2 whitespace-nowrap">{row?.getValue('gender') || '/'}</p>
          </div>
        ),
      },
      {
        accessorKey: 'age',
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="pl-0.5"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            {lang.get('msg.age')}
            <ArrowUpDown className="w-4 h-4 ml-2" />
          </Button>
        ),
        cell: ({ row }) => (
          <div>
            <p className="whitespace-nowrap">{row.getValue('age') || '/'}</p>
          </div>
        ),
      },
      ...columns,
    ];
  }

  return columns;
};

import React, { useContext } from 'react';
import { IconArrowBack, IconX } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { useMutateCreateProject } from '@/reactQuery/post';
import { toast } from '../ui/use-toast';
import { LangContext } from '@/context/LangProvider';
import { EStatusCode } from '@/enums';

interface IScratchProps {
  setCreationType: React.Dispatch<React.SetStateAction<string>>
  closeModalAndRemoveData:() => void;
}

const defaultValues = {
  title: '',
};

interface IformValues {
  title:string
}

type ICreationForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Project title is required'),
});

function CreateFromScratch({ setCreationType, closeModalAndRemoveData }: IScratchProps) {
  const { lang } = useContext(LangContext);
  const { workspace_id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const { mutateAsync } = useMutateCreateProject();

  const onSubmit: SubmitHandler<IformValues> = async (data: ICreationForm) => {
    try {
      await mutateAsync({ ...data, workspace_id });
      closeModalAndRemoveData();
      reset();
      toast({ description: `✅ ${data?.title} ${lang.get('msg.successfullyCreated')}` });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.PROJECT_NAME_EXISTS) {
        toast({ description: 'Tellet with the same name already exists.', variant: 'destructive' });
      } else {
        toast({ description: `${lang.get('msg.projectNotCreatedTryAgain')}`, variant: 'destructive' });
      }
    }
  };

  return (
    <div className="relative flex flex-col w-full h-full overflow-hidden overflow-y-auto">
      <div className="fixed top-0 left-0 right-0 flex items-center justify-between w-full pr-12 bg-white pl-7 h-14">
        {/* go back */}
        <button type="button" onClick={() => setCreationType('')}><IconArrowBack size={24} /></button>
        {/* close modal */}
        <button type="button" onClick={closeModalAndRemoveData}><IconX size={24} /></button>
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full text-center">
        <h2 className="text-3xl font-bold">{lang.get('msg.enterProjectTitle')}</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="flex items-center justify-center mt-10 w-fit md900:w-full">
          <Input
            {...register('title')}
            placeholder="My new Tellet"
            className={`w-full ${errors?.title?.message && 'border-red-600/70'} w-[30rem] md900:w-full mx-auto rounded-r-none`}
          />
          <Button
            variant="default"
            disabled={isSubmitting}
            className="capitalize rounded-l-none"
          >
            {lang.get('msg.createProject')}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default CreateFromScratch;

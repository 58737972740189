import useTheme from 'chat-ui/src/hooks/useTheme';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import useSidebarStore from '@/store/SidebarStore';
import { cn } from '@/lib/utils';
import useGetAllWorkspaces from '@/hooks/useGetAllWorkspaces';
import Bars3CenterLeftIcon from '../icons/Bars3CenterLeftIcon';
import XMarkIcon from '../icons/XMarkIcon';
import PrivateDropDown from './PrivateDropDown';
import SharedDropDown from './SharedDropDown';
import WorkspaceSearch from './WorkspaceSearch';
import Image from '../atoms/Image';
import TelletLogo from '@/assets/logo/tellet-logo-main.svg';
import EmpathyLogo from '@/assets/logo/empaty-logo.svg';
import { ECompanyThemeName } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import CreateWorkspaceModal from '../Modals/CreateWorkspaceModal';
import Squares2X2Icon from '../icons/Squares2X2Icon';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" height={30} className="max-w-[150px]" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" height={30} className="max-w-[140px]" />,
};

function Sidebar() {
  const [isPrivateOpen, setIsPrivateOpen] = useState(false);
  const [isSharedOpen, setIsSharedOpen] = useState(false);
  const { currentTheme, themeName } = useTheme();
  const { allWorkspacesData } = useGetAllWorkspaces();
  const { lang } = useTranslation();

  const hideSidebar = useSidebarStore((state) => state.hideSidebar);
  const setHideSidebar = useSidebarStore((state) => state.setHideSidebar);

  useEffect(() => {
    if (window.innerWidth < 450) {
      setHideSidebar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSidebarOnClick = () => {
    if (window.innerWidth < 450) {
      setHideSidebar(!hideSidebar);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <aside
        style={{ backgroundColor: currentTheme?.background }}
        className={`h-full transition-all ease-in fixed top-0 bottom-0 left-0 z-50 duration-200 overflow-y-auto min-h-screen border-r p-5 pt-16 ${hideSidebar ? 'max-w-[80px] px-2.5 w-full min450:hidden' : 'max-w-[270px] w-full min450:max-w-full'
        }`}
      >
        <div className="relative flex flex-col-reverse justify-between w-auto h-full min450:pt-5">
          {/* logo */}
          <div className="flex items-center justify-between w-full">
            <NavLink
              onClick={closeSidebarOnClick}
              to="/"
              className={cn('w-fit flex h-9', hideSidebar && 'hidden')}
            >
              {!hideSidebar && themeLogosList[themeName]}
            </NavLink>
            {/* exit button */}
            <button className="hidden min450:flex" type="button" onClick={() => setHideSidebar(!hideSidebar)}><XMarkIcon className="size-7" /></button>
          </div>

          <div className="flex flex-col">
            {!hideSidebar && (
              <div className="flex items-center justify-between w-full mt-3 mb-1.5 pr-1.5">
                <p
                  style={{ color: currentTheme?.['primary-text'] }}
                  className="flex items-center gap-1 text-sm font-semibold"
                >
                  <Squares2X2Icon className="size-5" />
                  {lang.get('msg.workspaces')}
                </p>
                <CreateWorkspaceModal hideSidebar={hideSidebar} />
              </div>
            )}
            {/* search */}
            <WorkspaceSearch hideSidebar={hideSidebar} />
            {/* menu items */}
            <div className={`flex flex-col w-full mt-3 gap-2 ${hideSidebar ? 'items-center mt-9' : ''}`}>
              <ul className={`flex flex-col w-full gap-1.5 ${hideSidebar ? 'items-center' : ''}`}>
                {/* signle dropdown management link */}
                <PrivateDropDown
                  closeSidebarOnClick={closeSidebarOnClick}
                  hideSidebar={hideSidebar}
                  isPrivateOpen={isPrivateOpen}
                  setIsPrivateOpen={setIsPrivateOpen}
                  workspaces={allWorkspacesData?.data?.priv}
                  setHideSidebar={setHideSidebar}
                  setIsSharedOpen={setIsSharedOpen}
                />
              </ul>
              <ul className={`flex flex-col w-full gap-1.5 ${hideSidebar ? 'items-center' : ''}`}>
                {/* signle dropdown management link */}
                <SharedDropDown
                  closeSidebarOnClick={closeSidebarOnClick}
                  hideSidebar={hideSidebar}
                  setIsSharedOpen={setIsSharedOpen}
                  isSharedOpen={isSharedOpen}
                  workspaces={allWorkspacesData?.data?.shared}
                  setHideSidebar={setHideSidebar}
                  setIsPrivateOpen={setIsPrivateOpen}
                />
              </ul>
            </div>

          </div>
        </div>
      </aside>
      {/* close sidebar */}
      <button
        type="button"
        aria-label="menu"
        style={{ background: currentTheme?.primary }}
        className={cn(
          'fixed z-50 rounded-full transition-all duration-200 ease-in-out min450:hidden top-[78px]',
          !hideSidebar ? 'left-64' : 'left-[68px]',
        )}
        onClick={() => setHideSidebar(!hideSidebar)}
      >
        {!hideSidebar ? (
          <IconChevronLeft
            className="p-1 text-white transition-all ease-in-out rounded-full size-6"
          />
        )
          : (
            <IconChevronRight
              className="p-1 text-white transition-all ease-in-out rounded-full size-6"
            />
          )}
      </button>
      {/* close button mobile screen */}
      {hideSidebar && (
        <button
          type="button"
          aria-label="menu"
          className="fixed z-50 hidden rounded-full min450:flex bottom-6 left-6 bg-primary"
          onClick={() => setHideSidebar(!hideSidebar)}
        >
          <Bars3CenterLeftIcon
            className="p-2 text-white transition-all ease-in-out rounded-full w-9 h-9"
          />
        </button>
      )}
    </>
  );
}

export default Sidebar;

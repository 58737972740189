import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IconDots } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import UsersTable from '@/components/userTable/UsersTable';
import { LangContext } from '@/context/LangProvider';
import axiosInstance from '@/axios/axiosInstance';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import RenameOrganizationModal from '@/components/Modals/RenameOrganizationModal';

function OrganizationalSettings() {
  const { lang } = useContext(LangContext);
  const { organization_id } = useParams();

  const { data: orgData } = useQuery({
    queryFn: () => axiosInstance.get(`/organizations/get/${organization_id}`),
    queryKey: ['current_organization_data'],
    select: (data) => data?.data,
  });

  const organizationID: string = orgData?._id;
  const currentOrgName = orgData?.name;

  return (
    <main className="flex flex-col w-full h-full">
      <div className="flex items-center gap-14">
        <h1 className="mb-2 text-xl font-semibold">{lang.get('msg.organizationalSettings')}</h1>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button type="button" variant="ghost">
              <IconDots size={17} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <RenameOrganizationModal currentOrgName={currentOrgName} />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {/* data table for users management */}
      <UsersTable organizationID={organizationID} />
    </main>
  );
}

export default OrganizationalSettings;

import useTheme from 'chat-ui/src/hooks/useTheme';
import TelletLogo from '@/assets/logo/tellet-logo-main.svg';
import EmpathyLogo from '@/assets/logo/empathy-logo-black.svg';
import Illustration from '@/assets/illustrations/invitation-expired.svg';
import Image from '@/components/atoms/Image';
import { ECompanyThemeName } from '@/enums';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" className="absolute w-24 h-fit top-6 left-5" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" className="absolute w-44 h-fit top-6 left-5" />,
};

function OrganizationInvitationExpired() {
  const { themeName } = useTheme();

  return (
    <div className="relative flex items-center justify-center min-h-screen px-4 mx-auto text-center">
      {themeLogosList[themeName]}
      <div className="flex flex-col items-center justify-center gap-2 mx-auto text-center">
        <Image
          effect="blur"
          src={Illustration}
          alt="illustration"
          className="min500:w-full w-[450px]"
        />
        <h1 className="text-5xl font-bold mt-14 text-dark min500:text-4xl">Your invitation link has expired</h1>
        <p className="mt-2 text-lg font-semibold">Get in touch with the organization owner to request a new invitation.</p>
      </div>
    </div>
  );
}

export default OrganizationInvitationExpired;

import { useQuery } from '@tanstack/react-query';
import axiosInstance from '@/axios/axiosInstance';
import { EWorkspaceRoles } from '@/enums';
import { useGetUserData } from '@/reactQuery/get';
import useGetCurrentWorkspace from './useGetCurrentWorkspace';

const useWorkspaceRoles = (workspace_id: string | undefined) => {
  const { data: user } = useGetUserData();
  const { currentWorkspaceData } = useGetCurrentWorkspace();

  const { data: usersData } = useQuery({
    queryFn: () => axiosInstance.get(`workspaces/get_members/${workspace_id}`),
    queryKey: ['table_users', { workspace_id }],
    enabled: !!workspace_id,
  });

  const userIDsMap = usersData?.data?.map((item: { _id:string }) => item._id);

  const getWorkspaceMembers = currentWorkspaceData?.data?.members?.map(
    (item: { user_id: string, role: string }) => item,
  );

  const getUsersWithRole = (role: EWorkspaceRoles) => {
    const singleRoleGroup = getWorkspaceMembers?.filter(
      (item: { role: string }) => item?.role === role,
    );

    return singleRoleGroup;
  };

  const workspaceMembersWithRole = (role: EWorkspaceRoles) => {
    const roleMap = getUsersWithRole(role)?.map(
      (item: { user_id: string }) => item?.user_id,
    );

    return roleMap;
  };

  const isUserOwner = userIDsMap?.some(
    (userId: string) => workspaceMembersWithRole(EWorkspaceRoles.OWNER)?.includes(userId)
     && userId === user?._id,
  );

  const isUserEditor = userIDsMap?.some(
    (userId: string) => workspaceMembersWithRole(EWorkspaceRoles.EDITOR)?.includes(userId)
    && userId === user?._id,
  );

  const isUserViewer = userIDsMap?.some(
    (userId: string) => workspaceMembersWithRole(EWorkspaceRoles.VIEWER)?.includes(userId)
    && userId === user?._id,
  );

  return {
    isUserOwner,
    isUserEditor,
    isUserViewer,
  };
};

export default useWorkspaceRoles;

import { useState, useEffect, useRef } from 'react';
import {
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconVolume,
  IconVolumeOff,
} from '@tabler/icons-react';

interface IAudioProps {
  url: string;
  isUser: boolean;
  transcript?: string;
}

function AudioMessage({ url, isUser, transcript }: IAudioProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const audioRef = useRef<HTMLAudioElement>(null);
  const timelineRef = useRef<HTMLInputElement>(null);

  const toggleAudio = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleSound = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = !audio.muted;
      setIsMuted(audio.muted);
    }
  };

  const playIcon = (
    <IconPlayerPlayFilled
      className="md700:w-5 md700:h-5 h-[30px] w-[30px]"
      onClick={toggleAudio}
    />
  );
  const pauseIcon = (
    <IconPlayerPauseFilled
      className="md700:w-5 md700:h-5 h-[30px] w-[30px]"
      onClick={toggleAudio}
    />
  );
  const soundIcon = (
    <IconVolume className="md700:w-5 md700:h-5 h-[30px] w-[30px]" onClick={toggleSound} />
  );
  const muteIcon = (
    <IconVolumeOff
      className="md700:w-5 md700:h-5 h-[30px] w-[30px]"
      onClick={toggleSound}
    />
  );

  const changeTimelinePosition = () => {
    const audio = audioRef.current;
    const timeline = timelineRef.current;
    if (audio && timeline) {
      const percentagePosition = (100 * audio.currentTime) / audio.duration;
      timeline.style.backgroundSize = `${percentagePosition}% 100%`;
      timeline.value = percentagePosition.toString();
      setCurrentTime(audio.currentTime);
    }
  };

  const audioEnded = () => {
    setIsPlaying(false);
  };

  const changeSeek = () => {
    const audio = audioRef.current;
    const timeline = timelineRef.current;
    if (audio && timeline) {
      const time = (Number(timeline.value) * audio.duration) / 100;
      audio.currentTime = time;
      setCurrentTime(time);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    const timeline = timelineRef.current;

    if (audio && timeline) {
      audio.addEventListener('timeupdate', changeTimelinePosition);
      audio.addEventListener('ended', audioEnded);
      timeline.addEventListener('input', changeSeek);

      return () => {
        audio.removeEventListener('timeupdate', changeTimelinePosition);
        audio.removeEventListener('ended', audioEnded);
        timeline.removeEventListener('change', changeSeek);
      };
    }
    return undefined;
  }, []);

  return (
    <div>
      <div className="flex items-center audio-player min-w-64">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio ref={audioRef} src={url} />
        <div className="flex items-center w-full py-1 controls">
          <button type="button" className="mr-5 pb-0.5">
            {isPlaying ? pauseIcon : playIcon}
          </button>
          <input
            type="range"
            className={`${isUser ? 'timeline' : 'timeline-2'} w-full`}
            max="100"
            ref={timelineRef}
          // eslint-disable-next-line no-restricted-globals
            value={
            Number.isNaN(currentTime) || !audioRef.current?.duration
              ? 0
              : (currentTime / audioRef.current.duration) * 100
          }
            onChange={changeSeek}
          />
          <button type="button" className="ml-3">
            {isMuted ? muteIcon : soundIcon}
          </button>
        </div>
      </div>
      { transcript?.length as number > 1
     && (
     <p className="pt-1 mt-1 border-t border-white">
       &quot;
       {transcript}
       &quot;
     </p>
     )}
    </div>
  );
}

export default AudioMessage;

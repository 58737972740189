import {
  closestCenter,
  DndContext,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconLoader2, IconPlus } from '@tabler/icons-react';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import Cookie from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useBlocker, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import useChatStore from 'chat-ui/src/store';
import { useMutateProjectUpdate } from '@/reactQuery/post';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { EStatusCode } from '@/enums';
import { LangContext } from '@/context/LangProvider';
import { toast } from '@/components/ui/use-toast';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import RequiredLabel from '@/components/labels/RequiredLabel';
import QuestionBox from './QuestionBox';
import { testChatRegister } from '@/components/chat/axios/axiosInstanceChat';

interface IFileData {
  id: string;
  file: File | undefined;
}

interface IformValues {
  opening_message?: string;
  interview_questions?: {
    question?: string;
    media?: IMedia;
    follow_up_question?: string;
    answer_quantification?: {
      quantify_answer?: boolean;
      value?: string;
    }[];
    id: string;
  }[] | undefined;
}

const validationSchema = Yup.object().shape({
  opening_message: Yup.string(),
  interview_questions: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      question: Yup.string(),
      media: Yup.mixed(),
      follow_up_question: Yup.string(),
      answer_quantification: Yup.array().of(
        Yup.object().shape({
          quantify_answer: Yup.boolean(),
          value: Yup.string(),
        }),
      ),
    }),
  ),
});

interface IInstructionsProps {
  projectId: string | undefined;
  singleProjectData: IProjectProps;
  workspaceID: string | undefined;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<unknown, Error>>;
}

function Script({ projectId, singleProjectData, workspaceID, isProjectPublished, isProjectCompleted, refetch }: IInstructionsProps) {
  const { lang } = useContext(LangContext);
  const [questions, setQuestions] = useState<IQuestionBoxProps[]>(singleProjectData?.interview_questions || []);
  const { isUserViewer } = useWorkspaceRoles(workspaceID);
  const { project_id: currentProject } = useParams();
  const [currentFormValues] = useState<IformValues>({});
  const [isActiveDrag, setIsActiveDrag] = useState(false);
  const [openQuestionId, setOpenQuestionId] = useState<string | null>(null);
  const [formChanged, setFormChanged] = useState(false);
  const [reorderChanged, setReorderChanged] = useState(false);
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const { setCurrentProjectToken } = useChatStore((state) => state);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isMediaUploading
      && currentLocation.pathname !== nextLocation.pathname,
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      opening_message: singleProjectData?.opening_message || '',
      interview_questions: singleProjectData?.interview_questions?.map((question) => ({
        ...question,
      })) || [],
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync, status } = useMutateProjectUpdate();

  const [fileData, setFileData] = useState<{ [key: string]: IFileData }>({});

  const handleChatRegister = async () => {
    try {
      try {
        const data = await testChatRegister(projectId);
        if (data) {
          setCurrentProjectToken(data);
        } else {
          setCurrentProjectToken('');
        }
      } catch {
        toast({
          description: lang.get('msg.errorPleaseTryAgain'),
          variant: 'destructive',
        });
      }
    } catch {
      toast({
        description: lang.get('msg.errorPleaseTryAgain'),
        variant: 'destructive',
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const onSubmit: SubmitHandler<IformValues> = async (data: IformValues) => {
    try {
      if (!isUserViewer && !isProjectPublished && !isProjectCompleted) {
        await mutateAsync({
          _id: projectId,
          ...data,
        });

        const mediaUploadPromises = Object.keys(fileData).map(async (questionId) => {
          const questionMedia = fileData[questionId].file;
          if (questionMedia) {
            setIsMediaUploading(true);
            try {
              await axios.post(
                `${import.meta.env.VITE_API_BASE_URL}/projects/media/${currentProject}/${questionId}`,
                { media: questionMedia },
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${Cookie.get('token')}`,
                  },
                },
              );
            } catch {
              toast({
                title: 'Unsupported media type',
                description: 'Please upload an image (jpg, jpeg, png, gif, webp) or a video (mp4, webm).',
                variant: 'destructive',
              });
            } finally {
              setIsMediaUploading(false);
            }
          }
        });

        await Promise.all(mediaUploadPromises);

        await refetch();

        handleChatRegister();

        setFormChanged(false);
        setReorderChanged(false);
      }
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  // eslint-disable-next-line consistent-return
  const onSubmitWithoutMedia: SubmitHandler<IformValues> = async (data: IformValues) => {
    try {
      if (!isUserViewer && !isProjectPublished && !isProjectCompleted) {
        await mutateAsync({
          _id: projectId,
          ...data,
        });

        setFormChanged(false);
        setReorderChanged(false);
        // handleChatRegister();
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const status_400 = axiosError.status === EStatusCode.BAD_REQUEST;
      const projectAlreadyPublished = axiosError.status === EStatusCode.PROJECT_ALREADY_PUBLISHED;
      if (status_400 || projectAlreadyPublished) {
        return null;
      }
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const handleFormChanged = () => {
    setFormChanged(true);
  };

  const handleReorderChanged = () => {
    setReorderChanged(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setQuestions((items) => {
        const activeIndex = items.findIndex((q) => q?.id === active?.id);
        const overIndex = items.findIndex((q) => q?.id === over?.id);

        const reorderedItems = arrayMove(items, activeIndex, overIndex);

        // Update the form values based on the reordered items
        const reorderedFormValues = reorderedItems.map((item) => {
          const matchingValue = currentFormValues?.interview_questions?.find(
            (q) => q?.id === item.id,
          );

          return matchingValue || item;
        });

        // Update the form values using setValue
        setValue('interview_questions', reorderedFormValues);
        handleReorderChanged();

        return reorderedItems;
      });
    }

    setIsActiveDrag(false);
  };

  const addQuestion = () => {
    const newQuestion: IQuestionBoxProps = {
      id: uuidv4(),
      question: '',
      follow_up_question: '0',
      media: undefined,
      answer_quantification: [
        {
          quantify_answer: false,
          value: '',
        },
      ],
    };

    // Update the state
    setQuestions((prevQuestions) => [...(prevQuestions || []), newQuestion]);

    // Retrieve the current form values
    const currentFormValuesAdd = getValues();

    // Update the form values using setValue
    setValue('interview_questions', [
      ...(currentFormValuesAdd.interview_questions || []),
      {
        id: newQuestion.id,
        question: '',
        media: undefined,
        follow_up_question: '0',
        answer_quantification: [{
          quantify_answer: false,
          value: '',
        }],
      },
    ]);
    setOpenQuestionId(newQuestion.id);
    handleSubmit(onSubmitWithoutMedia)();
  };

  const handleDeleteQuestion = (idToDelete: string) => {
    // Update the state
    setQuestions((prevQuestions) => prevQuestions.filter((question) => question.id !== idToDelete));

    // Retrieve the current form values
    const currentFormValuesDelete = getValues();

    // Filter out the question to be deleted
    const updatedInterviewQuestions = currentFormValuesDelete.interview_questions?.filter(
      (q) => q.id !== idToDelete,
    ) || [];

    // Update the form values using setValue
    setValue('interview_questions', updatedInterviewQuestions);
    handleSubmit(onSubmitWithoutMedia)();
  };

  useEffect(() => {
    setValue('opening_message', singleProjectData?.opening_message || '');

    const initialInterviewQuestions: IQuestionBoxProps[] = singleProjectData?.interview_questions || [];

    setQuestions(initialInterviewQuestions);
    setValue('interview_questions', initialInterviewQuestions);
  }, [singleProjectData?.opening_message,
    singleProjectData?.interview_questions, setValue]);

  if (blocker.state === 'blocked') {
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-screen bg-black/30 z-100">
        <div className="flex flex-col py-10 px-10 text-center items-center justify-center w-full h-fit bg-white rounded-md max-w-[500px]">
          <h3 className="text-xl font-semibold">{lang.get('msg.uploadIncomplete')}</h3>
          <p>{lang.get('msg.fileUploadingStillInProgressMessage')}</p>
          <div className="flex items-center gap-4 mt-10">
            <Button className="" variant="default" onClick={() => blocker.reset()}>
              {lang.get('msg.remainHere')}
            </Button>
            <Button className="" variant="destructive" onClick={() => blocker.proceed()}>
              {lang.get('msg.proceedAnyway')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full pl-1 pr-5">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-6 pb-10">
        {/* -- introduction -- */}
        <RequiredLabel title="openingMessage" tooltip={lang.get('msg.openingMessageInfo')}>
          <Textarea
            disabled={isUserViewer || isProjectPublished || isProjectCompleted}
            {...register('opening_message')}
            placeholder={lang.get('msg.openingMessagePlaceholder')}
            onChange={handleFormChanged}
            className="h-24"
          />
        </RequiredLabel>

        {/* -- drag & drop area -- */}
        <div className="flex flex-col w-full h-full gap-4">
          <p className="flex items-center gap-1 text-[15px] font-medium">
            {lang.get('msg.interviewQuestions')}
          </p>
          {/* wrapper */}
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={() => setIsActiveDrag(true)}
          >
            <div className="flex flex-col w-full h-full gap-5 p-6 rounded-md bg-slate-50">
              <SortableContext
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                items={questions}
                strategy={verticalListSortingStrategy}
              >
                {questions.map((question, index) => (
                  <QuestionBox
                    isProjectPublished={isProjectPublished}
                    isProjectCompleted={isProjectCompleted}
                    isActiveDrag={isActiveDrag}
                    key={question.id}
                    index={index}
                    register={register}
                    id={question.id}
                    control={control}
                    handleDeleteQuestion={handleDeleteQuestion}
                    isUserViewer={isUserViewer}
                    existingText={question.question}
                    singleProjectData={singleProjectData}
                    questions={questions}
                    openQuestionId={openQuestionId}
                    setOpenQuestionId={setOpenQuestionId}
                    handleFormChanged={handleFormChanged}
                    formChanged={formChanged}
                    setFileData={setFileData}
                    fileData={fileData}
                    isMediaUploading={isMediaUploading}
                  />
                ))}
              </SortableContext>
            </div>
          </DndContext>
          <div className="flex items-center justify-between w-full gap-2">
            {/* add question button */}
            <Button
              variant="default"
              disabled={isUserViewer || isProjectPublished || isProjectCompleted}
              type="button"
              onClick={addQuestion}
              className="flex items-center w-full gap-1"
            >
              <IconPlus size={16} />
              {lang.get('msg.addQuestion')}
            </Button>

            <Button
              variant="default"
              className="w-full"
              disabled={status === 'pending' || (!formChanged && !reorderChanged)}
            >
              {status === 'pending' && <IconLoader2 className="mr-2 animate-spin" size={20} />}
              {!formChanged && status === 'idle' && lang.get('msg.noChanges')}
              {(!formChanged && !reorderChanged) && status === 'success' && lang.get('msg.allSaved')}
              {(formChanged || reorderChanged) && lang.get('msg.saveChanges')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Script;

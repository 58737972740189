export const getInitials = (name: string) => {
  if (!name) return '';
  const names = name.split(' ');
  const initials = names[0].substring(0, 1) + (names[1] ? names[1].substring(0, 1) : '');
  return initials.toUpperCase();
};

export const transformBottomLineText = (inputText: string) => {
  if (inputText.includes('_')) {
    const words = inputText.split('_');
    const transformedText = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return transformedText;
  }

  return inputText.charAt(0).toUpperCase() + inputText.slice(1);
};

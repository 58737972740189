import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import Lang from 'lang.js';
import dayjs from 'dayjs';
import { IconCircleFilled, IconCrown, IconSend, IconUserOff } from '@tabler/icons-react';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { AxiosResponse } from 'axios';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { getInitials } from '@/utils/stringHelpers';
import { toast } from '../ui/use-toast';
import { useMutateReinviteToOrg, useMutateTransferOrganizationalOwnership, useMutationsChangeRole } from '@/reactQuery/post';
import { EOrganizationRoles } from '@/enums';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import useOrganizationRoles from '@/hooks/useOrganizationRoles';

interface IInvitation {
  reinvite_request: boolean;
}

export interface ITableProps {
  name: string;
  img: string;
  updatedAt: string;
  invite_accepted: boolean;
  email: string;
  _id: string;
  role: string;
  invitations: IInvitation[]
}

export const UserColumns = (
  lang: Lang,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rolesData: AxiosResponse<any, any> | undefined,
  setOpenDeletingModal: React.Dispatch<React.SetStateAction<boolean>>,
  setRowUserName: React.Dispatch<React.SetStateAction<string>>,
  organizationID: string,
) => {
  const { mutateAsync } = useMutationsChangeRole();
  const { mutateAsync: mutateAsyncReinvite } = useMutateReinviteToOrg();
  const { mutateAsync: mutateAsyncTransferOwnership } = useMutateTransferOrganizationalOwnership();
  const { isUserOwner: isUserOwnerOrg } = useOrganizationRoles();

  const handleRoleChange = async (data: TRoleChange) => {
    try {
      await mutateAsync(data);
      toast({
        description: `✅ ${lang.get('msg.userRoleSuccessfullyUpdated')}`,
      });
    } catch (err) {
      toast({
        variant: 'destructive',
        description: `${lang.get('msg.userRoleNotUpdated')}`,
      });
    }
  };

  const handleOwnershipTransfer = async (formData: { user_id: string }) => {
    try {
      await mutateAsyncTransferOwnership({ user_id: formData.user_id, organization_id: organizationID });
      toast({
        description: 'Ownership successfully transfered',
      });
    } catch (err) {
      toast({
        variant: 'destructive',
        description: `${lang.get('msg.errorPleaseTryAgain')}`,
      });
    }
  };

  const handleReinvitation = async (data: { email:string, role: string }) => {
    try {
      await mutateAsyncReinvite({ email: data.email, role: data.role });
      toast({
        description: `✅ ${lang.get('msg.userSuccessfullyReinvited')}`,
      });
    } catch (err) {
      toast({
        variant: 'destructive',
        description: `${lang.get('msg.errorPleaseTryAgain')}`,
      });
    }
  };

  const columns: ColumnDef<ITableProps>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.name')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-3">
          <div className="flex items-center justify-center  uppercase border rounded-full  min-w-[36px] min-h-[36px]">
            <Avatar>
              {/* add img later */}
              <AvatarImage src="" />
              <AvatarFallback className="font-semibold text-dark-text">
                {row?.getValue('name') ? getInitials(row?.getValue('name')) : <IconUserOff className="text-light-dark" size={15} />}
              </AvatarFallback>
            </Avatar>
          </div>
          <p>
            {row?.getValue('name') ?? (
              <span className="capitalize whitespace-nowrap">
                {lang.get('msg.accountCreationPending')}
                ...
              </span>
            )}
          </p>
        </div>
      ),
    },
    {
      accessorKey: 'email',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Email
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="lowercase">
          <a
            className="transition-all ease-in-out hover:text-primary"
            href={`mailto:${row.getValue('email')}`}
          >
            {row.getValue('email')}
          </a>
        </div>
      ),
    },
    {
      accessorKey: 'invite_accepted',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.status')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-1">
          <div
            className={`uppercase text-xs whitespace-nowrap rounded-full font-bold w-fit px-3.5 p-1 ${
              (row.getValue('invite_accepted') === true && 'bg-green-200/50 text-green-600')
            || (row.getValue('invite_accepted') === false && 'bg-orange-200/50 text-orange-600')
            || ''
            }`}
          >
            {row.getValue('invite_accepted') === true
              ? lang.get('msg.accepted')
              : lang.get('msg.pending')}
          </div>
          {row.getValue('invite_accepted') === false
          && (
            <TooltipWrapper text={lang.get('msg.resendInvitation')} className="mb-1">
              <Button
                className="relative px-2 py-2 h-fit"
                variant="ghost"
                onClick={() => handleReinvitation({ email: row?.original?.email, role: row?.original?.role })}
              >
                {row?.original?.invitations[0]?.reinvite_request
                && <IconCircleFilled className="absolute text-red-500 top-0.5 left-1" size={9} />}
                <IconSend size={18} />
              </Button>
            </TooltipWrapper>
          )}
        </div>
      ),
    },
    {
      accessorKey: 'role_id',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.userRole')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [selectedRole, setSelectedRole] = useState<string | undefined>(
          row.getValue('role_id'),
        );
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild disabled={row?.original?.invite_accepted === false || row.original.role === EOrganizationRoles.OWNER || !isUserOwnerOrg}>
                <div className="relative flex">
                  <Button
                    disabled={row?.original?.invite_accepted === false || row.original.role === EOrganizationRoles.OWNER || !isUserOwnerOrg}
                    variant="outline"
                    className="capitalize outline-none select-none ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0 disabled:bg-gray-100 disabled:cursor-not-allowed "
                  >
                    {row?.original?.role.toLowerCase()}
                  </Button>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-64">
                <DropdownMenuRadioGroup
                  value={selectedRole?.toString()}
                  onValueChange={(value: string) => {
                    if (value === EOrganizationRoles.OWNER) {
                      return;
                    }
                    if (value === row?.id) {
                      setOpenDeletingModal(true);
                      setRowUserName(row?.getValue('name'));
                      return;
                    }
                    setSelectedRole(value);
                    if (row?.original?.role !== value) {
                      handleRoleChange({
                        role: value,
                        user_id: row?.original?._id,
                      });
                    }
                  }}
                >
                  {rolesData?.data?.map((role: string) => (
                    <DropdownMenuRadioItem
                      key={role}
                      className={`flex items-center gap-2 px-5 py-3 cursor-pointer 
                                ${selectedRole === role && 'bg-neutral-100'}`}
                      value={role}
                    >
                      {lang.get(`msg.${role}`)}
                    </DropdownMenuRadioItem>
                  ))}
                </DropdownMenuRadioGroup>
                {isUserOwnerOrg && (
                <DropdownMenuItem
                  onClick={() => handleOwnershipTransfer({ user_id: row?.original?._id })}
                  className="flex items-center gap-2 px-5 py-3 border-none outline-none cursor-pointer hover:bg-neutral-100 text-[14px]"
                >
                  <p>Transfer Ownership</p>
                  <IconCrown className="text-yellow-600" />
                </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      accessorKey: 'updatedAt',
      header: () => <div className="text-right">{lang.get('msg.dateCreated')}</div>,
      cell: ({ row }) => (
        <div className="text-right capitalize">
          {dayjs(row.getValue('updatedAt')).format('DD/MM/YYYY')}
        </div>
      ),
    },
  ];
  return columns;
};

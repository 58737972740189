import { create } from 'zustand';

type State = {
  hideSidebar: boolean;
};

type Action = {
  setHideSidebar: (value: boolean) => void;
};

const useSidebarStore = create<State & Action>((set) => ({
  hideSidebar: false,
  setHideSidebar: (value: boolean) => set({ hideSidebar: value }),
}));

export default useSidebarStore;

import { Dispatch, SetStateAction } from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';

interface IModalProps {
  openDeletingModal: boolean;
  setOpenDeletingModal: Dispatch<SetStateAction<boolean>>;
  row_id: string;
  removeUserFromWorkspaceFunc: (user_id: string) => Promise<void>;
}

function RemoveFromWorkspaceModal({ openDeletingModal,
  setOpenDeletingModal, row_id, removeUserFromWorkspaceFunc }: IModalProps) {
  return (
    <AlertDialog open={openDeletingModal}>
      <AlertDialogContent className="border-2 shadow-2xl border-dark-text/30">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete this user account and
            remove that user data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="mt-5">
          <AlertDialogCancel onClick={() => setOpenDeletingModal(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              setOpenDeletingModal(false);
              removeUserFromWorkspaceFunc(row_id);
            }}
            className="bg-red-600 hover:bg-red-700"
          >
            Remove user
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default RemoveFromWorkspaceModal;

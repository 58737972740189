const getBrightness = (hexColor: string) => {
  let hex = hexColor;
  hex = hexColor.replace(/^#/, '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return (r * 299 + g * 587 + b * 114) / 1000;
};

const getTextColor = (hexColor: string) => {
  const brightness = getBrightness(hexColor);
  return brightness < 128 ? '#FFFFFF' : '#000000';
};

export default getTextColor;

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axiosInstance from '@/axios/axiosInstance';
import ProjectShareLink from '@/components/projects/single-project/share/ProjectShareLink';
import ProjectShareMaxRespondets from '@/components/projects/single-project/share/ProjectShareMaxRespondents';
import ProjectSharePanel from '@/components/projects/single-project/share/ProjectSharePanel';
import useErrorStore from '@/store/ErrorStore';
import { EStatusCode } from '@/enums';

function ProjectShare() {
  const { project_id } = useParams();
  const setShowError = useErrorStore((state) => state.setShowError);

  const { data: singleProjectData, error: projectDataError } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
  });

  useEffect(() => {
    if ((projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  return (
    <main className="flex flex-col w-full h-full">
      <div className="pt-5 pb-10">
        <ProjectShareLink singleProjectData={singleProjectData} />
      </div>
      <div className="py-5">
        <ProjectShareMaxRespondets singleProjectData={singleProjectData} />
      </div>
      <div className="pb-10">
        <ProjectSharePanel singleProjectData={singleProjectData} />
      </div>
    </main>
  );
}

export default ProjectShare;

import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { IconLoader2, IconRepeat } from '@tabler/icons-react';
import TelletLogo from '@/assets/logo/tellet-logo-main.svg';
import Illustration from '@/assets/illustrations/invitation-expired.svg';
import Image from '@/components/atoms/Image';
import { Button } from '@/components/ui/button';
import { useMutateRequestAnotherAccountInvitation } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import Routes from '@/router/routes';

function EmailVerificationExpired() {
  const { mutateAsync, status } = useMutateRequestAnotherAccountInvitation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      await mutateAsync({ token });
      toast({ title: 'Requested successfully sent', description: 'Your request has been successfully submitted and is being processed.' });
      navigate(Routes.login.path);
    } catch {
      toast({ title: 'Error! Please try again later.', variant: 'destructive' });
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen px-4 mx-auto text-center">
      <NavLink to="/login">
        <Image src={TelletLogo} alt="logo" className="absolute w-28 h-fit top-6 left-5" />
      </NavLink>
      <div className="flex flex-col items-center justify-center gap-2 mx-auto text-center">
        <Image
          effect="blur"
          src={Illustration}
          alt="illustration"
          className="min500:w-full w-[450px]"
        />
        <h1 className="text-5xl font-bold mt-14 text-dark min500:text-4xl">Your verification link has expired</h1>
        <p className="text-[#5A6A85] leading-6 mt-4 font-semibold text-xl">
          Request a new invitation by clicking the button below.
        </p>

        <Button disabled={status === 'pending'} onClick={onSubmit} variant="default" className="flex items-center gap-1.5 mt-10">
          {status === 'pending' ? <IconLoader2 className="animate-spin" size={20} /> : <IconRepeat size={20} />}
          Request Another Invitation
        </Button>

      </div>
    </div>
  );
}

export default EmailVerificationExpired;

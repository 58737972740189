import { cn } from '@/lib/utils';

interface IIconProps {
  className: string
}

function Bars3CenterLeftIcon({ className }: IIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={cn(className)}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5" />
    </svg>
  );
}

export default Bars3CenterLeftIcon;

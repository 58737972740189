import { IconFilterPlus, IconLoader2 } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import { CountryDropdown } from 'react-country-region-selector';
import 'rc-slider/assets/index.css';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { Input } from '../ui/input';
import useTranslation from '@/hooks/useTranslation';
import { useMutateCreateFilter } from '@/reactQuery/post';
import { toast } from '../ui/use-toast';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import XMarkIcon from '../icons/XMarkIcon';

export interface IFormFilters {
  genders?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  age?: any;
  PostalCode?: string;
  country?: string | null;
  name?: string
}

interface GenderOption {
  label: string;
  value: string;
}

interface IModal {
  project_id: string | undefined;
  onFilterChange: (id: string) => void
  openFilterModal: boolean;
  setOpenFilterModal: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultValues: IFormFilters = {
  genders: [''],
  age: [0, 0],
  PostalCode: '',
  country: '',
  name: '',
};

const validationSchema = Yup.object().shape({
  genders: Yup.array().of(Yup.string()),
  age: Yup.array().of(Yup.number()),
  PostalCode: Yup.string(),
  country: Yup.string(),
  name: Yup.string().required('Filter name is required'),
});

const ERROR_STATUS = {
  EXISTING_NAME: 409,
};

function ResultsFiltersModal({ project_id, onFilterChange, openFilterModal, setOpenFilterModal }: IModal) {
  const { lang } = useTranslation();
  const [rangeData, setRangeData] = useState<number[]>([0, 0]);
  const [gendersData, setGendersData] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState<GenderOption[]>([]);
  const { currentTheme } = useTheme();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormFilters>({ defaultValues, resolver: yupResolver(validationSchema) as Resolver<IFormFilters> });

  const { mutateAsync, status } = useMutateCreateFilter();

  const { data: getGenderData } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/filters/${project_id}/get_genders`),
    queryKey: ['get_genders'],
    select: (data) => data?.data,
  });

  useEffect(() => {
    if (getGenderData) {
      const mappedGenders = getGenderData.map((gender: { label: string, value: string }) => ({ label: gender, value: gender }));
      setGendersData(mappedGenders);
    }
  }, [getGenderData]);

  // eslint-disable-next-line consistent-return
  const onSubmit: SubmitHandler<IFormFilters> = async (formData: IFormFilters) => {
    const ageObject = !rangeData.every((value, index) => value === [0, 0][index]) ? { min: rangeData[0], max: rangeData[1] } : null;
    const selectedGendersToSend = selectedGenders?.map((option: { value: string }) => option.value);
    const data = { ...formData, age: ageObject, country: formData.country || null, genders: selectedGendersToSend };
    setOpenFilterModal(false);
    try {
      const response = await mutateAsync({ project_id, data });
      onFilterChange(response?.data);
      setRangeData([0, 0]);
      reset();
      toast({ description: 'Filter successfully created!' });
      // eslint-disable-next-line consistent-return
      return response;
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.status === ERROR_STATUS.EXISTING_NAME) {
        toast({ description: `Filter named "${data?.name}" already exists` });
      } else {
        toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
      }
    }
  };

  const onRangeChange = (newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setRangeData(newValue);
    }
  };

  return (
    <AlertDialog open={openFilterModal}>
      <AlertDialogTrigger asChild>
        <Button
          variant="default"
          className="flex items-center h-8 gap-2 px-8 text-sm"
          onClick={() => setOpenFilterModal(!openFilterModal)}
        >
          {status === 'pending' ? <IconLoader2 className="animate-spin" size={18} /> : <IconFilterPlus size={18} />}
          {status === 'pending' ? 'Setting up filter...' : lang.get('msg.addFilter')}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-xl p-8 z-100">
        <button
          type="button"
          onClick={() => setOpenFilterModal(false)}
          className="absolute top-4 right-4"
        >
          <XMarkIcon className="size-5" />
        </button>
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
          {/* gender */}
          <div className="relative z-10 flex flex-col gap-2 text-sm">
            <p
              style={{ color: currentTheme?.primary }}
              className="mb-1 font-bold"
            >
              {lang.get('msg.gender')}
            </p>
            <MultiSelect
              className="border rounded-md"
              options={gendersData}
              value={selectedGenders}
              disabled={status === 'pending'}
              onChange={(selectedOptions: { label: string; value: string }[]) => {
                setSelectedGenders(selectedOptions);
              }}
              labelledBy="Select"
            />
          </div>
          {/* age */}
          <div className="flex flex-col w-full gap-2 mt-1 text-sm">
            <div
              style={{ color: currentTheme?.primary }}
              className="flex items-center justify-between w-full gap-2 font-bold"
            >
              {lang.get('msg.age')}
              <p className="flex items-center self-center text-dark-text">
                Min -
                {' '}
                {rangeData[0]}
                <span className="mx-2">|</span>
                Max -
                {' '}
                {rangeData[1] !== 100 ? rangeData[1] : `${rangeData[1]}+`}
              </p>
            </div>
            <Controller
              name="age"
              control={control}
              render={({ field }) => (
                <div className="flex items-center px-2">
                  <Slider
                    disabled={status === 'pending'}
                    value={rangeData}
                    onChange={(newValue: number | number[]) => {
                      onRangeChange(newValue);
                      field.onChange(newValue);
                    }}
                    range
                    min={0}
                    max={100}
                    step={1}
                    styles={{
                      track: { backgroundColor: currentTheme?.primary },
                      handle: {
                        borderColor: currentTheme?.primary,
                        backgroundColor: '#FFFFFF',
                      },
                    }}
                  />
                </div>
              )}
            />
          </div>
          {/* postal code */}
          <div className="flex flex-col gap-2 mt-3 text-sm">
            <p
              style={{ color: currentTheme?.primary }}
              className="font-bold"
            >
              {lang.get('msg.postalCode')}
            </p>
            <div className="flex items-center">
              <Input disabled={status === 'pending'} {...register('PostalCode')} placeholder="Search for postal code" />
            </div>
          </div>
          {/* filter country */}
          <div className="flex flex-col gap-2 text-sm">
            <p
              style={{ color: currentTheme?.primary }}
              className="font-bold"
            >
              {lang.get('msg.country')}
            </p>
            <div className="flex items-center">
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CountryDropdown
                    disabled={status === 'pending'}
                    defaultOptionLabel={lang.get('msg.selectCountry')}
                    classes="w-full h-10 px-3.5 border rounded-md shadow outline-none cursor-pointer bg-gray-50"
                    value={value || ''}
                    onChange={(val: string) => onChange(val)}
                  />
                )}
              />
            </div>
          </div>
          {/* filter name */}
          <div className="flex flex-col gap-2 text-sm">
            <p
              style={{ color: currentTheme?.primary }}
              className="font-bold"
            >
              {lang.get('msg.filterName')}
            </p>
            <div className="flex items-center gap-3">
              <Input
                disabled={status === 'pending'}
                {...register('name')}
                placeholder="Add name for this filter"
                hasError={!!errors?.name?.message}
              />
              <Button
                variant="default"
                disabled={status === 'pending'}
                type="submit"
                className="flex items-center gap-1 px-6"
              >
                {status === 'pending' && <IconLoader2 className="animate-spin" size={17} />}
                Save & Run filter
              </Button>
            </div>
            <div className="h-4">
              <p className={cn(!errors?.name?.message && 'hidden', 'text-red-600 text-xs -mt-1')}>
                {errors?.name?.message}
              </p>
            </div>
          </div>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ResultsFiltersModal;

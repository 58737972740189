import { create } from 'zustand';
import { ECategoryMode } from '@/enums';

type State = {
  categoryType: ECategoryMode | null;
};

type Action = {
  setCategoryType: (category: ECategoryMode | null) => void;
};

const useCategoryStore = create<State & Action>((set) => ({
  categoryType: null,
  setCategoryType: (category) => set({ categoryType: category }),
}));

export default useCategoryStore;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import Image from '@/components/atoms/Image';
import Routes from '@/router/routes';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';

const defaultValues = {
  password: '',
  confirmPassword: '',
};

type TPasswordResetForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('This field is required'),
});

function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenAccept = searchParams.get('token');

  const onSubmit: SubmitHandler<TPasswordResetForm> = async (_formData: TPasswordResetForm) => {
    try {
      const { confirmPassword, ...allFormData } = _formData;
      await axiosInstanceNoAuth.post('/users/reset_password', allFormData, {
        headers: {
          Authorization: `Bearer ${tokenAccept}`,
        },
      });
      setIsLoading(true);
      toast({
        title: 'Password changed successfully',
        description: 'Password changed successfully try to login now.',
      });

      navigate(Routes.login.path);
    } catch {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while changing password. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[600px] min-h-screen center border-0 px-2 py-4 min450:bg-white min450:max-w-full">
        <div className="flex flex-col items-center w-full max-w-[600px] rounded-md h-fit p-7 min450:p-5">
          <div className="flex flex-col items-center w-full ring-1 ring-neutral-200 max-w-[500px] min450:ring-transparent min450:shadow-none gap-10 bg-white rounded-md shadow-md h-fit p-7">
            <Image effect="blur" src={TeletLogo} alt="logo" width={115} height={37} />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-start w-full gap-5 h-fit"
            >
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Password
                <Input {...register('password')} type="password" hasError={!!errors.password} />
                {errors.password?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.password?.message}
                </p>
                )}
              </label>
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Confirm password
                <Input
                  {...register('confirmPassword')}
                  type="password"
                  hasError={!!errors.confirmPassword}
                />
                {errors.confirmPassword?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.confirmPassword?.message}
                </p>
                )}
              </label>
              <div className="relative flex flex-col w-full gap-7">
                <Button
                  disabled={isSubmitting || isLoading}
                  variant="default"
                  type="submit"
                  className="flex items-center gap-0.5 mt-4"
                >
                  {isSubmitting && <IconLoader2 className="w-4 h-4 mr-2 animate-spin" />}
                  <span>Reset password</span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;

import { useContext, useState } from 'react';
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { LangContext } from '@/context/LangProvider';
import axiosInstance from '@/axios/axiosInstance';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { UserColumns } from './UserColumns';
import InviteToOrganizationModal from '../Modals/InviteToOrganizationModal';

export interface IformValues {
  email: string;
  role: string;
}

interface ITableProps {
  organizationID: string
}

export default function UsersTable({ organizationID }: ITableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const { lang } = useContext(LangContext);
  const [openDeletingModal, setOpenDeletingModal] = useState(false);
  const [rowUserName, setRowUserName] = useState<string>('');
  const { organization_id } = useParams();

  const { data: organization_members } = useQuery({
    queryFn: () => axiosInstance.get(`organizations/get_members/${organization_id}`),
    queryKey: ['organization_members'],
  });

  const { data: rolesData } = useQuery({
    queryFn: () => axiosInstance.get('/organizations/get_roles'),
    queryKey: ['roles_data_organization'],
  });

  const columns = UserColumns(
    lang,
    rolesData,
    setOpenDeletingModal,
    setRowUserName,
    organizationID,
  );

  const table = useReactTable({
    data: organization_members?.data ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 30,
      },
    },
  });

  return (
    <div className="w-full">
      {/* delete user modal */}
      <AlertDialog open={openDeletingModal}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Remove
              {' '}
              {rowUserName}
              ?
            </AlertDialogTitle>
            <AlertDialogDescription className="text-dark-text">
              If they have unshared workspaces, we&apos;ll add them to your account.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-5">
            <AlertDialogCancel onClick={() => setOpenDeletingModal(false)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => setOpenDeletingModal(false)}
              className="bg-red-600 hover:bg-red-700"
            >
              Remove from organization
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <div className="flex flex-wrap items-center justify-between gap-4 pt-4 pb-2">
        {/* invite members modal */}
        <InviteToOrganizationModal
          roles={rolesData}
        />

        {/* email filter and cols filtering */}
        <div className="flex gap-2 mb-2 max1050:w-full max1050:flex-row-reverse md700:flex-col">
          <Input
            placeholder={lang.get('msg.filterEmails')}
            value={(table.getColumn('email')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('email')?.setFilterValue(event.target.value)}
            className="w-64 md700:w-full"
          />
        </div>
        {/* filters end */}
      </div>
      <div className="border rounded-md">
        <Table className="text-dark-text">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {lang.get('msg.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end py-4 space-x-2">
        {table.getFilteredRowModel().rows.length >= 30
          && (
            <div className="space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {lang.get('msg.previous')}
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {lang.get('msg.next')}
              </Button>
            </div>
          )}
      </div>
    </div>
  );
}

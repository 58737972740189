import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button, buttonVariants } from '@/components/ui/button';
import TelletLogo from '@/assets/logo/tellet-logo-main.svg';
import EmpathyLogo from '@/assets/logo/empathy-logo-black.svg';
import Illustration from '@/assets/illustrations/accept_org_invite_illustration.svg';
import Image from '@/components/atoms/Image';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import { toast } from '@/components/ui/use-toast';
import Routes from '@/router/routes';
import { ECompanyThemeName } from '@/enums';
import useAuthStore from '@/store/AuthStore';

const PAGE_ERRORS = {
  LINK_EXPIRED: 444,
};

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" className="absolute w-24 h-fit top-6 left-5" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" className="absolute w-44 h-fit top-6 left-5" />,
};

function AcceptOrganizationInvitation() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenAccept = searchParams.get('token');
  const { themeName } = useTheme();
  const setAuthToken = useAuthStore((state) => state.setAuthToken);

  const onSubmit = async () => {
    try {
      const response = await axiosInstanceNoAuth.post('/users/accept_organization_invite', undefined, {
        headers: {
          Authorization: `Bearer ${tokenAccept}`,
        },
      });
      const { data } = response;

      Cookies.set('token', data?.token, { expires: 14 });
      setAuthToken(data?.token);

      Cookies.set('main_organization', data?.organization_id, { expires: 14 });
      Cookies.set('fallback_workspace', data?.workspace_id, { expires: 14 });
      navigate(`/organization/${data.organization_id}/workspace/${data.workspace_id}`);
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === PAGE_ERRORS.LINK_EXPIRED) {
        navigate(Routes.organization_invitation_link_expired.path);
      } else {
        toast({ description: 'Error. Please try again.', variant: 'destructive' });
      }
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen px-4 mx-auto text-center">
      {themeLogosList[themeName]}
      <div className="flex flex-col items-center justify-center gap-2 mx-auto text-center">
        <Image
          effect="blur"
          src={Illustration}
          alt="illustration"
          className="min500:w-full w-[450px]"
        />
        <h1 className="text-5xl font-bold mt-14 text-dark min500:text-4xl">Accept invitation</h1>
        <Button
          onClick={onSubmit}
          className={buttonVariants({
            variant: 'default',
            className: 'mt-10 transition-all ease-in-out hover:!bg-darker-primary',
          })}
        >
          Accept invite
        </Button>
      </div>
    </div>
  );
}

export default AcceptOrganizationInvitation;

import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import useTranslation from '@/hooks/useTranslation';

export interface ICompleteModalProps {
  title: string,
  respondents_count: number,
  respondents_max: number,
}

export const CompleteProjectColumns = () => {
  const { lang } = useTranslation();
  const columns: ColumnDef<ICompleteModalProps>[] = [
    {
      accessorKey: 'title',
      header: () => (
        <Button
          variant="ghost"
          className="pl-0"
        >
          {lang.get('msg.title')}
        </Button>
      ),
      cell: ({ row }) => (
        <>
          {row.getValue('title')}
        </>
      ),
    },
    {
      accessorKey: 'respondents_count',
      header: () => (
        <Button
          variant="ghost"
          className="pl-0"
        >
          {lang.get('msg.respondentsCount')}
        </Button>
      ),
      cell: ({ row }) => (
        <>
          {row.getValue('respondents_count')}
        </>
      ),
    },
    {
      accessorKey: 'respondents_max',
      header: () => (
        <Button
          variant="ghost"
          className="pl-0"
        >
          {lang.get('msg.maxRespondents')}
        </Button>
      ),
      cell: ({ row }) => (
        <>
          {row.getValue('respondents_max')}
        </>
      ),
    },
  ];
  return columns;
};

import useTheme from 'chat-ui/src/hooks/useTheme';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function InfoScreenCategory() {
  const { currentTheme } = useTheme();
  const [showFullText, setShowFullText] = useState(false);

  return (
    <div className="flex flex-col gap-5 p-7">
      <p>
        The categories feature allows you to choose how answers are categorised.
        In
        {' '}
        <b style={{ color: currentTheme?.primary }}>Single Category</b>
        {' '}
        mode, each answer will be assigned to one primary category.
        In
        {' '}
        <b style={{ color: currentTheme?.primary }}>Multiple Category</b>
        {' '}
        mode, answers can belong to multiple categories simultaneously.
      </p>

      {showFullText && (
      <p>
        The categories feature allows you to choose how answers are categorised.
        In
        {' '}
        <b style={{ color: currentTheme?.primary }}>Single Category</b>
        {' '}
        mode, each answer will be assigned to one primary category.
        In
        {' '}
        <b style={{ color: currentTheme?.primary }}>Multiple Category</b>
        {' '}
        mode, answers can belong to multiple categories simultaneously.
        <br />
        <br />
        In
        {' '}
        <b style={{ color: currentTheme?.primary }}>Multiple Category</b>
        {' '}
        mode, answers can fit into several categories.
        This is beneficial for open-ended questions where responses might be relevant to
        {' '}
        <b style={{ color: currentTheme?.primary }}>multiple categories</b>
        .
        For example, asking participants for suggestions on improving a product would be a
        {' '}
        <b style={{ color: currentTheme?.primary }}>Multiple Category</b>
        {' '}
        mode question,
        as you want to see how often each suggestion is mentioned across different categories.
        <br />
        <br />
        Find more information on categories
        {' '}
        <Link
          style={{ color: currentTheme?.primary }}
          target="_blank"
          className="font-semibold underline"
          to="https://tellet.ai/guides"
        >
          here
        </Link>
        .
      </p>
      )}

      <button
        onClick={() => setShowFullText(!showFullText)}
        type="button"
        style={{ color: currentTheme?.primary }}
        className="self-start mt-2 font-bold underline"
      >
        {showFullText ? 'Show Less' : 'Read more'}
      </button>
    </div>
  );
}

export default InfoScreenCategory;

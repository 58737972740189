import { useContext } from 'react';
import { ChevronDown } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { LangContext } from '@/context/LangProvider';
import { EWorkspaceRoles } from '@/enums';

interface IRoles {
  data: EWorkspaceRoles[],
}
interface IDropDownProps {
  field: {
    value: string;
    onChange: (value: string) => void;
  };
  errors?: string;
  roles?: IRoles;
}

function RoleDropDown({ field, errors, roles }: IDropDownProps) {
  const { lang } = useContext(LangContext);
  const filteredFromOwner = roles?.data?.filter((item: string) => item !== EWorkspaceRoles.OWNER);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={`flex rounded-tl-none rounded-bl-none !border-l-gray-50 shadow items-center outline-none justify-between select-none bg-gray-50
           border border-natural-700 border-l-none text-dark-text ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0 min-w-[160px] min500:min-w-full !border-l-black/20 ${
            errors && 'border border-red-600/50'
          }`}
        >
          {field.value
            ? lang.get(`msg.${field.value}`)
            : <p>{lang.get('msg.selectRole')}</p>}
          {' '}
          <ChevronDown className="w-4 h-4 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mt-1 ml-6 w-52 z-90">
        <DropdownMenuRadioGroup
          className="flex flex-col gap-0.5"
          value={field.value}
          onValueChange={(value) => field.onChange(value)}
        >
          {filteredFromOwner?.map((role: string) => (
            <DropdownMenuRadioItem
              key={role}
              className={`flex items-center gap-2 px-5 py-3 cursor-pointer ${
                field.value === role && 'bg-neutral-100'
              }`}
              value={role}
            >
              {lang.get(`msg.${role}`)}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default RoleDropDown;

import axios, { InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { EStatusCode } from '@/enums';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = Cookies.get('token');
  if (config.headers.Authorization) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    } as InternalAxiosRequestConfig;
  }

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => response,
  (error) => {
    Sentry.captureException(error);
    if (error.response.status === EStatusCode.INVALID_CREDENTIALS) {
      Cookies.remove('token');
      Cookies.remove('main_organization');
      Cookies.remove('remember_me');
      Cookies.remove('fallback_workspace');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  },
);

export default axiosInstance;

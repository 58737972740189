import { Skeleton } from '../ui/skeleton';

function SummarySkeletonLoader() {
  return (
    <div className="flex flex-col gap-1.5">
      <Skeleton className="w-full h-4 rounded-sm" />
      <Skeleton className="w-[95%] h-4 rounded-sm" />
      <Skeleton className="w-[95%] h-4 rounded-sm" />
      <Skeleton className="w-[90%] h-4 rounded-sm" />
      <Skeleton className="w-[96%] h-4 rounded-sm" />
      <Skeleton className="w-[90%] h-4 rounded-sm" />
    </div>
  );
}

export default SummarySkeletonLoader;

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { toast } from '@/components/ui/use-toast';
import { LangContext } from '@/context/LangProvider';
import { EStatusCode } from '@/enums';
import { useMutateRenameWorkspace } from '@/reactQuery/post';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

interface IRenameProps {
  name: string;
  _id?: string | undefined;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('New workspace name is required'),
});

function RenameWorkspaceModal({ currentWorkspaceName, currentWorkspaceId }:
{ currentWorkspaceName: string, currentWorkspaceId: string }) {
  const { lang } = useContext(LangContext);

  const defaultValues = {
    name: currentWorkspaceName,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const [openModal, setOpenModal] = useState(false);

  const { mutateAsync } = useMutateRenameWorkspace();

  const onSubmit: SubmitHandler<IRenameProps> = async (data) => {
    try {
      await mutateAsync({ ...data, _id: currentWorkspaceId });
      reset();
      setOpenModal(false);
      toast({ description: '✅ Workspace successfully renamed' });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.INVALID_ROLE) {
        toast({ description: `${lang.get('msg.onlyOwnerCanChangeRoles')}` });
      } else {
        toast({ description: `${lang.get('msg.workspaceNameNotChanged')}`, variant: 'destructive' });
      }
    }
  };

  return (
    <AlertDialog open={openModal}>
      <AlertDialogTrigger onClick={() => setOpenModal(true)} asChild>
        <p className="py-1 pl-2 text-sm cursor-pointer hover:bg-secondary-background">{lang.get('msg.rename')}</p>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {lang.get('msg.renameYourWorkspace')}
            </AlertDialogTitle>
            <div className="flex flex-col pt-5 pb-12">
              <label className="relative flex flex-col w-full gap-1">
                <Input
                  {...register('name')}
                  placeholder="Enter new workspace name"
                  className={`w-full ${errors?.name?.message && 'border-red-600/50'}`}
                />
                <p
                  className={`text-xs md700:hidden font-medium text-red-600 opacity-0 absolute -bottom-5 ${
                    errors?.name?.message && 'opacity-100'
                  }`}
                >
                  {errors?.name?.message}
                </p>
              </label>
            </div>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setOpenModal(false)}>{lang.get('msg.cancel')}</AlertDialogCancel>
            <Button
              variant="default"
              type="submit"
              disabled={isSubmitting}
            >
              {lang.get('msg.submit')}
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default RenameWorkspaceModal;

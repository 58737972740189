import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import Image from '@/components/atoms/Image';
import { Button, buttonVariants } from '@/components/ui/button';
import Routes from '@/router/routes';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import useAuthStore from '@/store/AuthStore';
import { EStatusCode } from '@/enums';

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const tokenURL = searchParams.get('token');
  const [isVerified, setIsVerified] = useState(false);
  const [serverError, setServerError] = useState(false);
  const setAuthToken = useAuthStore((state) => state.setAuthToken);
  const authToken = useAuthStore((state) => state.authToken);
  const [workspaceRedirect, setWorkspaceRedirect] = useState<string>('');
  const [organizationRedirect, setOrganizationRedirect] = useState<string>('');
  const [disableDashboardBtn, setDisableDashboardBtn] = useState(false);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);
  const navigate = useNavigate();

  const getWorkspaceData = async (verifyToken: string | null) => {
    try {
      const workspaces = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/workspaces/get_all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${verifyToken}`,
        },
      });

      const getAllWorkspaces = [
        ...workspaces?.data?.priv || [],
        ...workspaces?.data?.shared || []];

      setOrganizationRedirect(getAllWorkspaces[0]?.organization_id);
      Cookies.set('main_organization', getAllWorkspaces[0]?.organization_id, { expires: 14 });
      Cookies.set('fallback_workspace', getAllWorkspaces[0]?._id, { expires: 14 });
      setWorkspaceRedirect(getAllWorkspaces[0]?._id);
      setDisableDashboardBtn(false);
    } catch {
      setDisableDashboardBtn(true);
    }
  };

  // eslint-disable-next-line consistent-return
  const verifyToken = async () => {
    try {
      setServerError(false);
      const response = await axiosInstanceNoAuth.post('/users/verify_email', undefined, {
        headers: {
          Authorization: `Bearer ${tokenURL}`,
        },
      });

      const { data } = response;
      Cookies.set('token', data?.token, { expires: 14 });

      setAuthToken(data?.token);
      setIsVerified(true);
      setInvalidLink(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.INVALID_CREDENTIALS) {
        setServerError(false);
        setInvalidLink(true);
        return setIsVerified(false);
      }
      if (axiosError?.status === EStatusCode.TOKEN_EXPIRED) {
        setServerError(false);
        return navigate(Routes.verification_email_link_expired.path);
      }
      if (axiosError?.status === EStatusCode.ALREADY_VERIFIED) {
        setServerError(false);
        return setAlreadyVerified(true);
      }
      setServerError(true);
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authToken !== null) {
      getWorkspaceData(authToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[600px] min-h-screen center border-0 px-2 py-4 min450:bg-white min450:max-w-full">
        <div className="flex flex-col items-center w-full max-w-[600px] rounded-md h-fit p-7 min450:p-5">
          <div className="flex flex-col items-center min-h-[350px] w-full ring-1 ring-neutral-200 max-w-[500px]
           min450:ring-transparent min450:shadow-none gap-10 bg-white rounded-md shadow-md h-fit p-7 min450:p-2"
          >
            <Image effect="blur" src={TeletLogo} alt="logo" width={115} height={37} />

            {isVerified && !serverError && !alreadyVerified && (
              <>
                <h1 className="text-xl font-bold">Successfully verified</h1>
                <p className="text-[#5a6a85] leading-6">
                  Your email has been verified successfully.
                  <br />
                  You can enter the dashboard.
                </p>
                <Button disabled={disableDashboardBtn}>
                  <Link
                    to={`/organization/${organizationRedirect}${Routes.workspace.path}/${workspaceRedirect}`}
                  >
                    Enter dashboard
                  </Link>
                </Button>
              </>
            )}
            {!isVerified && !serverError && alreadyVerified && (
            <>
              <h1 className="text-xl font-bold">Email already verified</h1>
              <p className="text-[#5a6a85] leading-6">
                Your email is already verified.
              </p>
            </>
            )}
            {!isVerified && !serverError && invalidLink && (
              <>
                <h1 className="text-xl font-bold">Invitation expired or invalid link</h1>
                <p className="text-[#5a6a85] leading-6">Login again to get a new invitation email.</p>
                <Link
                  to={Routes.login.path}
                  className={buttonVariants({
                    variant: 'default',
                    className: 'mt-5 transition-all ease-in-out hover:!bg-darker-primary',
                  })}
                >
                  Back to login
                </Link>
              </>
            )}
            {serverError && (
              <>
                <h1 className="text-xl font-bold">Error occurred</h1>
                <p className="text-[#5a6a85] leading-6 text-center">
                  An error occurred while verifying your email.
                  <br />
                  Please try again later.
                </p>
                <Link
                  to={Routes.login.path}
                  className={buttonVariants({
                    variant: 'default',
                    className: 'mt-5 transition-all ease-in-out hover:!bg-darker-primary',
                  })}
                >
                  Back to login
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default VerifyEmail;

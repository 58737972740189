import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { IconLoader2 } from '@tabler/icons-react';
import { LangContext } from '@/context/LangProvider';
import Snippets from './components/Snippets';
import { toast } from '@/components/ui/use-toast';
import { useMutateProjectUpdate } from '@/reactQuery/post';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { Button } from '@/components/ui/button';
import { EStatusCode } from '@/enums';

const validationSchema = Yup.object().shape({
  snippets: Yup.array(),
});

interface ITextValues {
  snippets?: string[];
  links?: string[];
  documents?: { document?: string | undefined }[] | undefined;
}

interface IInstructionsProps {
  projectId: string | undefined;
  singleProjectData: IProjectProps;
  workspaceID: string | undefined;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
}

function Context({ projectId, singleProjectData, workspaceID, isProjectPublished, isProjectCompleted }: IInstructionsProps) {
  const { lang } = useContext(LangContext);
  const { isUserViewer } = useWorkspaceRoles(workspaceID);
  const [formChanged, setFormChanged] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      snippets: singleProjectData?.snippets || [],
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync, status } = useMutateProjectUpdate();

  const onSubmit: SubmitHandler<ITextValues> = async (data: ITextValues) => {
    try {
      await mutateAsync({
        _id: projectId,
        ...data,
      });
      setFormChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const handleFormChanged = () => {
    setFormChanged(true);
  };

  useEffect(() => {
    setValue('snippets', singleProjectData?.snippets);
  }, [singleProjectData?.snippets, setValue]);

  return (
    <div className="w-full h-full pl-1 pr-5">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-5 pb-10">
        {/* -- snippets -- */}
        <Snippets
          control={control}
          watch={watch}
          register={register}
          isUserViewer={isUserViewer}
          isProjectPublished={isProjectPublished}
          isProjectCompleted={isProjectCompleted}
          handleFormChanged={handleFormChanged}
        />
        <Button
          variant="default"
          className="w-full mt-2"
          disabled={status === 'pending' || !formChanged}
        >
          {status === 'pending' && <IconLoader2 className="mr-2 animate-spin" size={20} />}
          {!formChanged && status === 'idle' && lang.get('msg.noChanges')}
          {!formChanged && status === 'success' && lang.get('msg.allSaved')}
          {formChanged && lang.get('msg.saveChanges')}
        </Button>
      </form>
    </div>
  );
}

export default Context;

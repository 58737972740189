import { useQuery } from '@tanstack/react-query';
import axiosInstance from '@/axios/axiosInstance';
import axiosInstanceChat from '@/components/chat/axios/axiosInstanceChat';

export const useGetRoles = () => useQuery({
  queryKey: ['roles'],
  queryFn: async () => {
    const { data } = await axiosInstance.get('/roles/get');
    return data;
  },
});

export const useGetUserData = () => useQuery<TUser>({
  queryKey: ['user'],
  queryFn: async () => {
    const { data } = await axiosInstance.get('/users/profile');
    return data;
  },
});

export const useChatHistory = (token:string | undefined) => useQuery<TMessageData[]>({
  queryKey: ['chatHistory', token],
  enabled: !!token,
  retry: 1,
  refetchIntervalInBackground: false,
  refetchOnWindowFocus: false,
  queryFn: async () => {
    const { data } = await axiosInstanceChat.get('/test/history', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  },
});

export default useGetRoles;

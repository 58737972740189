interface IToneOfVoice {
  label: string;
  value: string;
}

const toneOfVoiceList: IToneOfVoice[] = [
  {
    label: 'friendly',
    value: 'friendly',
  },
  {
    label: 'formal',
    value: 'formal',
  },
  {
    label: 'neutral',
    value: 'neutral',
  },
  {
    label: 'empathetic',
    value: 'empathetic',
  },
  {
    label: 'challenging',
    value: 'challenging',
  },
  {
    label: 'humorous',
    value: 'humorous',
  },
];

export default toneOfVoiceList;

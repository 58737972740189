import { IconX, IconCheck } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { cn } from '@/lib/utils';

interface ISwitch {
  value: boolean;
  func: () => void;
  isDisabled: boolean;
}

function Switch({ value, func, isDisabled }: ISwitch) {
  const { currentTheme } = useTheme();
  return (
    <button
      type="button"
      onClick={func}
      disabled={isDisabled}
      style={{ backgroundColor: value ? currentTheme?.primary : '' }}
      className={cn('flex w-12 h-6 bg-gray-300 rounded-full transition-all ease-in-out')}
    >
      <span className={cn(
        'h-6 w-6 border border-gray-300 flex items-center justify-center bg-white rounded-full transition-all ease-in-out',
        value && 'ml-6',
      )}
      >
        {value ? <IconCheck className="text-green-600" size={15} /> : <IconX className="text-red-600" size={15} />}
      </span>
    </button>
  );
}

export default Switch;

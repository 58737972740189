import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import axiosInstance from '@/axios/axiosInstance';
import { EStatusCode } from '@/enums';
import Routes from '@/router/routes';

const useGetCurrentWorkspace = () => {
  const { workspace_id, organization_id } = useParams();
  const navigate = useNavigate();

  const { data: currentWorkspaceData, error: currentWorkspaceError,
    isLoading: isCurrentWorkspaceLoading } = useQuery<IWorkspaceResponse>({
    queryFn: () => axiosInstance.get(`workspaces/get/${organization_id}/${workspace_id}`),
    queryKey: ['workspace_data', { workspace_id, organization_id }],
    enabled: !!organization_id,
  });

  const currentWorkspaceName = currentWorkspaceData?.data?.name as string;
  const currentWorkspaceId = currentWorkspaceData?.data?._id as string;

  const axiosError = currentWorkspaceError as AxiosError;

  useEffect(() => {
    if (axiosError?.status === EStatusCode.NOT_CORRECT_ORGANIZATION) {
      navigate(Routes.error.path);
    }
  }, [axiosError, navigate]);

  return {
    currentWorkspaceName,
    currentWorkspaceId,
    currentWorkspaceData,
    currentWorkspaceError,
    isCurrentWorkspaceLoading,
  };
};

export default useGetCurrentWorkspace;

import { MoreHorizontal } from 'lucide-react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '../ui/button';
import { useMutateCopyProject, useMutateDeleteTellet, useMutateMoveProject } from '@/reactQuery/post';
import { toast } from '../ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import Routes from '@/router/routes';
import { AlertDialogTrigger } from '../ui/alert-dialog';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import RenameTelletModal from '../Modals/RenameTelletModal';
import { EStatusCode } from '@/enums';

interface IActionProps {
  mergedWorkspacesList: IWorkspace[]
  targettedProjectID: string,
  isUserOwner: boolean,
  isUserEditor: boolean
}

interface IFormData {
  projectID: string,
  newWorkspaceId: string,
  oldWorkspaceId: string | undefined,
}

interface IFormDataCopy {
  projectID: string,
  targetWorkspaceId: string,
}

function WorkspaceProjectActionsDD({
  mergedWorkspacesList, targettedProjectID, isUserOwner, isUserEditor }: IActionProps) {
  const { mutateAsync } = useMutateMoveProject();
  const { mutateAsync: mutateAsyncCopyProject } = useMutateCopyProject();
  const { mutateAsync: mutateAsyncDeleteTellet, isPending: isDeletingProjectPending } = useMutateDeleteTellet();
  const [openDeletingModal, setOpenDeletingModal] = useState(false);
  const { workspace_id } = useParams();
  const { lang } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleDeleteTellet = async (projectId: string) => {
    try {
      await mutateAsyncDeleteTellet({ projectId });
      toast({ description: 'Project deleted' });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.CANT_DELETE_PUBLISHED) {
        toast({ description: lang.get('Project with status "Published" cannot be deleted.') });
      } else {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const moveProject = async (formData: IFormData) => {
    try {
      await mutateAsync(formData);
      toast({ description: lang.get('msg.projectSuccessfullyMoved') });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.INVALID_ROLE) {
        toast({ description: lang.get('msg.projectCanOnlyBeMovedByOwnerOfWorkspace') });
      } else {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const copyProject = async (formData: IFormDataCopy) => {
    try {
      await mutateAsyncCopyProject(formData);
      toast({ description: lang.get('msg.projectSuccessfullyCopied') });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.INVALID_ROLE) {
        toast({ description: lang.get('msg.projectCanOnlyBeCopiedByOwnerOfWorkspace') });
      } else {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="w-8 h-8 p-0">
          <MoreHorizontal className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-16 w-52">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => navigate(`${pathname}${Routes.project_design.path}/${targettedProjectID}`)}
          >
            {lang.get('msg.view')}
          </DropdownMenuItem>
          {/* rename */}
          {(isUserOwner || isUserEditor)
          && <RenameTelletModal project_id={targettedProjectID} />}
          {/* copy to */}
          {(isUserOwner || isUserEditor)
          && (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>{lang.get('msg.copyTo')}</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {mergedWorkspacesList?.map((item) => (
                  <DropdownMenuItem
                    key={item?._id}
                    onClick={() => copyProject({
                      projectID: targettedProjectID,
                      targetWorkspaceId: item?._id,
                    })}
                    className={`flex items-start py-2 pl-2 cursor-pointer pr-7
                       ${item?._id === workspace_id && 'bg-neutral-100'}`}
                  >
                    {item?.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
            {/* move to */}
            {(isUserOwner)
            && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>{lang.get('msg.moveTo')}</DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {mergedWorkspacesList?.map((item) => (
                    <DropdownMenuItem
                      key={item?._id}
                      onClick={() => !(item?._id === workspace_id)
                        && moveProject({
                          projectID: targettedProjectID,
                          newWorkspaceId: item?._id,
                          oldWorkspaceId: workspace_id,
                        })}
                      className={`flex items-start py-2 pl-2 cursor-pointer pr-7
                       ${item?._id === workspace_id && 'bg-neutral-100'}`}
                    >
                      {item?.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
            )}
          </DropdownMenuSub>
          )}
        </DropdownMenuGroup>
        {isUserOwner
        && (
          <AlertDialog open={openDeletingModal}>
            <AlertDialogTrigger
              className="flex w-full px-2 py-1 mt-1 text-sm text-red-600 border-t rounded-sm hover:bg-gray-100"
              onClick={() => setOpenDeletingModal(true)}
            >
              {lang.get('msg.delete')}
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{lang.get('msg.areYouSure')}</AlertDialogTitle>
                <AlertDialogDescription>{lang.get('msg.projectDeleteWarning')}</AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter className="mt-5">
                <AlertDialogCancel onClick={() => setOpenDeletingModal(false)}>
                  {lang.get('msg.cancel')}
                </AlertDialogCancel>
                <AlertDialogAction
                  disabled={isDeletingProjectPending}
                  onClick={() => {
                    setOpenDeletingModal(false);
                    handleDeleteTellet(targettedProjectID);
                  }}
                  className="bg-red-600 hover:bg-red-900"
                >
                  {lang.get('msg.delete')}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default WorkspaceProjectActionsDD;

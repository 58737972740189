import { create } from 'zustand';

type State = {
  activeTab: string;
};

type Action = {
  setActiveTab: (value: string) => void;
};

const useResultTabStore = create<State & Action>((set) => ({
  activeTab: 'overview_tab',
  setActiveTab: (value: string) => set({ activeTab: value }),
}));

export default useResultTabStore;

import axios from 'axios';
import * as Sentry from '@sentry/react';

const axiosInstanceNoAuth = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstanceNoAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error.response);
  },
);

export default axiosInstanceNoAuth;

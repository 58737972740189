import { IconLoader2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { ECategoryMode } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import { translateCategories } from '@/pages/projects/single-project/Content/project_results/resultsPageTranslations';
import { useMutateGenerateAiCategories } from '@/reactQuery/post';
import useCategoryStore from '@/store/CategoryStore';
import useQuestionsLoadingStore from '@/store/QuestionsLoadingStore';
import getTextColor from '@/utils/getTextColorContrast';
import CogIcon from '../icons/CogIcon';
import SparklesIcon from '../icons/SparklesIcon';
import EditCategoriesModal from '../Modals/EditCategoriesModal';
import { Button } from '../ui/button';
import { Skeleton } from '../ui/skeleton';
import { toast } from '../ui/use-toast';
import useCategoriesLoadingStore from '@/store/CategoriesLoadingStore';

interface ICategories {
  id: number;
  label: string;
  count: number;
  color: string;
  prompt: string;
}

interface IProps {
  projectID: string | undefined;
  questionID: string | undefined;
  analysisLanguage: string;
}

function Categories({ projectID, questionID, analysisLanguage }: IProps) {
  const { lang } = useTranslation();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('fid');
  const { currentTheme } = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const categoryType = useCategoryStore((state) => state.categoryType);
  const setCategoryType = useCategoryStore((state) => state.setCategoryType);
  const questionsLoading = useQuestionsLoadingStore((state) => state.questionsLoading);
  const setCategoriesLoading = useCategoriesLoadingStore((state) => state.setCategoriesLoading);

  const { mutateAsync: mutateAsyncGenerateAICategories, status: generatingAICategoriesStatus } = useMutateGenerateAiCategories();

  const { data: categoryData, isLoading, isFetching } = useQuery({
    queryFn: () => {
      const baseUrl = `/analyzer/quantification/${projectID}/${questionID}`;
      const url = filter_id ? `${baseUrl}?filter_id=${filter_id}` : baseUrl;
      return axiosInstanceAnalyze.get(url);
    },
    queryKey: ['category_data', { questionID, filter_id }],
    enabled: !!projectID && !!questionID,
    select: (category_data) => category_data?.data,
  });

  const manualyCreateCategoriesModal = () => {
    setOpenModal(true);
    setCategoryType(ECategoryMode.SINGLE);
  };

  const generateAICategories = async () => {
    try {
      await mutateAsyncGenerateAICategories({ projectID, questionID, filter_id });
      setOpenModal(true);
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const conversationsNumber = categoryData?.conversations_num;
  const categoriesPicked = categoryType === ECategoryMode.SINGLE ? categoryData?.categories : categoryData?.categories_multi;
  const isCategoryDataExists = categoryData?.categories?.length !== 0 || categoryData?.categories_multi?.length !== 0;

  const maxCount = categoriesPicked && categoriesPicked.length > 0
    ? Math.max(...categoriesPicked.map((cat: { count: number }) => cat.count)) || 1
    : 1;

  useEffect(() => {
    setCategoriesLoading(isLoading && isFetching);
  }, [setCategoriesLoading, isLoading, isFetching]);

  useEffect(() => {
    setCategoryType(categoryData?.last_ran);
  }, [categoryData, setCategoryType]);

  return (
    <div className="flex flex-col max-w-[1300px]">
      {/* edit categories */}
      <div className="flex items-center justify-between w-full mb-3">
        <div className="flex flex-col">
          <h3
            style={{ color: currentTheme?.primary }}
            className="mb-1 font-semibold"
          >
            {translateCategories[analysisLanguage] || translateCategories.English}
          </h3>
          {isCategoryDataExists && !questionsLoading && (
            <p>
              Category mode:
              <b
                style={{ color: currentTheme?.primary }}
                className="ml-1 capitalize"
              >
                {(!isLoading && !isFetching) && categoryData?.last_ran}
              </b>
            </p>
          )}
        </div>

        {!questionsLoading && !isFetching && (
          <EditCategoriesModal
            data={categoriesPicked}
            project_id={projectID}
            question_id={questionID}
            setOpenModal={setOpenModal}
            openModal={openModal}
            isCategoryDataExists={isCategoryDataExists}
            conversationsNumber={conversationsNumber}
            isLoading={isLoading && isFetching}
          />
        )}
      </div>

      {/*  eslint-disable-next-line no-nested-ternary */}
      {isLoading || isFetching || questionsLoading ? (
        <div
          style={{ borderColor: currentTheme?.['secondary-text'] }}
          className="flex w-full p-8 border rounded-lg"
        >
          <div className="flex w-full max1100:flex-col max1100:gap-6 max1100:items-center max1100:justify-center">
            {/* skeleton loaders */}
            <div
              style={{ borderColor: currentTheme?.['secondary-text'] }}
              className="flex w-[400px] max1100:w-full max1100:pr-0 items-start border-r pr-3 max1100:border-none"
            >
              <div className="flex flex-col gap-3.5 w-full">
                {[1, 2, 3, 4]?.map((item: number) => (
                  <div key={item} className="flex items-start gap-3">
                    <div className="flex flex-col w-full gap-2">
                      <Skeleton className="w-full h-4 rounded-sm max-w-[50%]" />
                      <Skeleton className="w-full h-3 rounded-sm" />
                      <Skeleton className="w-full h-3 rounded-sm max-w-[90%]" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="items-center justify-center w-full max1100:flex max1100:flex-col">
              <div className="flex justify-end w-full h-full">
                <div className="w-full h-full max1100:flex max1100:mt-5 max1100:items-center max1100:justify-center">
                  <div className="flex flex-col max1100:max-w-full max-w-[700px] w-full max1100:gap-4 justify-between ml-12 max1100:ml-0 min-h-full h-full border-black/30 pr-4">
                    {[1, 2, 3, 4]?.map((item: number) => (
                      <div key={item} className="flex items-start gap-3">
                        <Skeleton className="w-full rounded-sm h-7" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !isCategoryDataExists ? (
        // buttons to choose category if no category data exists
        <div className="flex items-center gap-4 mx-auto">
          <div className="flex items-center justify-center gap-6">
            <Button disabled={generatingAICategoriesStatus === 'pending'} onClick={generateAICategories} variant="default">
              {generatingAICategoriesStatus === 'pending'
                ? (
                  <div className="flex items-center gap-1">
                    <IconLoader2 className="size-5 animate-spin" />
                    {lang.get('msg.generatingCategories')}
                    ...
                  </div>
                ) : (
                  <div className="flex items-center gap-1">
                    <SparklesIcon className="size-5" />
                    {lang.get('msg.aiSuggestedCategories')}
                  </div>
                )}
            </Button>
          </div>

          <div className="flex items-center justify-center gap-6">
            <Button disabled={generatingAICategoriesStatus === 'pending'} onClick={manualyCreateCategoriesModal} variant="default">
              <CogIcon className="mr-1 size-5" />
              {lang.get('msg.createMyOwnCategories')}
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{ borderColor: currentTheme?.['secondary-text'] }}
          className="flex w-full p-8 border rounded-lg"
        >
          <div className="flex w-full max1100:flex-col max1100:gap-6 max1100:items-center max1100:justify-center">
            {/* labels */}
            <div
              style={{ borderColor: currentTheme?.['secondary-text'] }}
              className="flex w-[400px] max1100:w-full max1100:pr-0 items-start border-r pr-3 max1100:border-none"
            >
              <div className="flex flex-col gap-3.5">
                {categoriesPicked?.map((item: ICategories, index: number) => (
                  <div key={item?.id} className="flex items-start gap-3 text-[15px]">
                    <span
                      style={{ backgroundColor: `${item?.count > 0 && item?.color}`, color: getTextColor(item?.color) }}
                      className="flex items-center justify-center w-5 h-5 mt-0.5 font-semibold rounded-md"
                    >
                      {index + 1}
                    </span>
                    <div className="flex flex-col w-full">
                      <p className="font-medium">{item?.label}</p>
                      <p className="mt-0.5 text-xs">{item?.prompt}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="items-center justify-center w-full max1100:flex max1100:flex-col">
              <div className="flex justify-end w-full h-full">
                <div className="w-full h-full max1100:flex max1100:mt-5 max1100:items-center max1100:justify-center">
                  <div className="flex flex-col max1100:max-w-full max-w-[700px] w-full max1100:gap-4 justify-between ml-12 max1100:ml-0 min-h-full h-full border-black/30 pr-4">
                    {categoriesPicked?.map((item: ICategories) => {
                      const widthPercentage = (item.count / maxCount) * 100;
                      return (
                        <div
                          key={item?.id}
                          style={{
                            backgroundColor: `${item?.count > 0 && item.color}`,
                            width: `${widthPercentage}%`,
                            color: getTextColor(item?.color),
                          }}
                          className="flex mb-3 font-medium rounded-sm pl-7 max1100:mt-0 first:mt-0"
                        >
                          <p className="-ml-5">{item?.count}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Categories;

import axios from 'axios';

const axiosInstanceChat = axios.create({
  baseURL: import.meta.env.VITE_CHAT_URL,
});

export const testChatRegister = async (projectId: string | undefined): Promise<string> => {
  if (!projectId) {
    throw new Error('Project ID is not defined');
  }
  const { data } = await axiosInstanceChat.get(`/chat/test/token/${projectId}`);
  if (data?.conversationId) localStorage.setItem(`current_conversation_id/test/${projectId}`, data.conversationId);
  return data.token;
};

export const getTestChatHistory = async (token: string | undefined): Promise<TMessageData[]> => {
  const reponse = await axiosInstanceChat.get('/chat/test/history', {
    headers: { Authorization: `Bearer ${token}` },
  });

  return reponse.data;
};

export default axiosInstanceChat;

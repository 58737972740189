import { create } from 'zustand';

type State = {
  questionsLength: number;
};

type Action = {
  setQuestionsLength: (value: number) => void;
};

const useQuestionsStore = create<State & Action>((set) => ({
  questionsLength: 1,
  setQuestionsLength: (value: number) => set({ questionsLength: value }),
}));

export default useQuestionsStore;

import { create } from 'zustand';

type State = {
  categoriesLoading: boolean;
};

type Action = {
  setCategoriesLoading: (category: boolean) => void;
};

const useCategoriesLoadingStore = create<State & Action>((set) => ({
  categoriesLoading: false,
  setCategoriesLoading: (loading) => set({ categoriesLoading: loading }),
}));

export default useCategoriesLoadingStore;

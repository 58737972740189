import { useContext, useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button } from '../ui/button';
import {
  AlertDialog,
  AlertDialogContent,
} from '@/components/ui/alert-dialog';
import { LangContext } from '@/context/LangProvider';
import CreateFromScratch from '../project-creation/CreateFromScratch';
import CreateWithAi from '../project-creation/CreateWithAi';
import ChooseProjectCreationType from '../project-creation/ChooseProjectCreationType';
import CreateFromTemplate from '../project-creation/CreateFromTemplate';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import FolderPlusIcon from '../icons/FolderPlusIcon';
import { ECompanyThemeName } from '@/enums';

interface IModalProps {
  currentWorkspace: string | undefined
}

function CreateProjectModal({ currentWorkspace }: IModalProps) {
  const { lang } = useContext(LangContext);
  const [openCreatingProject, setOpenCreatingProject] = useState(false);
  const [creationType, setCreationType] = useState('');
  const { isUserOwner, isUserEditor } = useWorkspaceRoles(currentWorkspace);
  const { themeName } = useTheme();

  const closeModalAndRemoveData = () => {
    setOpenCreatingProject(false);
    setCreationType('');
  };

  return (
    <>
      {/* trigger */}
      <Button
        disabled={!(isUserOwner || isUserEditor)}
        type="button"
        variant="default"
        className="flex gap-2 md900:w-full"
        onClick={() => setOpenCreatingProject(true)}
      >
        <FolderPlusIcon className="size-5" />
        {themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.createNewTellet_empathy')
          : lang.get('msg.createNewTellet')}
      </Button>
      {/* modal */}
      <AlertDialog open={openCreatingProject}>
        <AlertDialogContent className="w-full max-w-[90%] h-[85%] text-dark-text">
          {/* picking choice */}
          {creationType === ''
          && (
          <ChooseProjectCreationType
            setCreationType={setCreationType}
            closeModalAndRemoveData={closeModalAndRemoveData}
          />
          )}
          {/* if scratch */}
          {creationType === 'scratch'
            && (
            <CreateFromScratch
              setCreationType={setCreationType}
              closeModalAndRemoveData={closeModalAndRemoveData}
            />
            )}
          {/* if scratch */}
          {creationType === 'template'
            && (
            <CreateFromTemplate
              setCreationType={setCreationType}
              closeModalAndRemoveData={closeModalAndRemoveData}
            />
            )}
          {/* if ai */}
          {creationType === 'ai'
            && (
            <CreateWithAi
              setCreationType={setCreationType}
              closeModalAndRemoveData={closeModalAndRemoveData}
            />
            )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default CreateProjectModal;

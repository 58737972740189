type RouteType =
  | 'home'
  | 'login'
  | 'sign_up'
  | 'error'
  | 'forgot_password'
  | 'reset_password'
  | 'check_your_email'
  | 'email_verify'
  | 'unauthorized'
  | 'accept_account_invitation'
  | 'accept_account_organization_invitation'
  | 'manage_account'
  | 'organizational_settings'
  | 'workspace'
  | 'organization'
  | 'project'
  | 'project_design'
  | 'project_results'
  | 'project_share'
  | 'organization_invitation_link_expired'
  | 'verification_email_link_expired';

const Routes: Record<RouteType, { path: string }> = {
  home: {
    path: '/',
  },
  login: {
    path: '/login',
  },
  sign_up: {
    path: '/sign-up',
  },
  error: {
    path: '/error',
  },
  forgot_password: {
    path: '/forgot-password',
  },
  reset_password: {
    path: '/reset-password',
  },
  check_your_email: {
    path: '/check-your-email',
  },
  email_verify: {
    path: '/email-verify',
  },
  unauthorized: {
    path: '/unauthrorized',
  },
  organization_invitation_link_expired: {
    path: '/organization-invitation-expired',
  },
  verification_email_link_expired: {
    path: '/verification-email-expired',
  },

  accept_account_invitation: {
    path: '/accept-account-invitation',
  },
  accept_account_organization_invitation: {
    path: '/accept-organization-invitation',
  },

  // -- manage users start --
  manage_account: {
    path: '/profile',
  },
  organizational_settings: {
    path: '/organizational-settings',
  },
  // -- manage users end --

  // -- projects start --
  workspace: {
    path: '/workspace',
  },

  organization: {
    path: '/organization',
  },

  // single project
  project: {
    path: '/project',
  },
  project_design: {
    path: '/project-design',
  },
  project_results: {
    path: '/project-results',
  },
  project_share: {
    path: '/project-share',
  },
  // -- projects end --

};

export default Routes;

/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode, useContext, useEffect, useState } from 'react';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import EnglishIcon from '@/assets/country_flags/english.svg';
import DutchIcon from '@/assets/country_flags/dutch.svg';
import { LangContext } from '@/context/LangProvider';
import useTranslation from '@/hooks/useTranslation';
import { toast } from '../ui/use-toast';

interface ILanguageList {
  id: number;
  value: string;
  label: string;
  icon: ReactNode;
}

interface ILangProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutateAsync: UseMutateAsyncFunction<AxiosResponse<any, any>, Error, TUpdateUserDto, unknown>
}

const languageList: ILanguageList[] = [
  {
    id: 1,
    value: 'en',
    label: 'English (UK)',
    icon: (
      <img
        src={EnglishIcon}
        alt="English Icon"
        className="object-cover w-full h-full rounded-full"
      />
    ),
  },
  {
    id: 2,
    value: 'nl',
    label: 'Dutch',
    icon: (
      <img src={DutchIcon} alt="English Icon" className="object-cover w-full h-full rounded-full" />
    ),
  },
];

function LanguageDropDown({ mutateAsync }:ILangProps) {
  const { lang } = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useContext(LangContext);
  const [pickedLanguage, setPickedLanguage] = useState<string>(
    () => localStorage.getItem('selectedLanguage') || selectedLanguage,
  );
  const [showLangIcon, setShowLangIcon] = useState<ILanguageList>(
    () => languageList.find((item) => item.value === pickedLanguage) || languageList[0],
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', pickedLanguage);
  }, [pickedLanguage]);

  const onLanguageSave = async (language: string) => {
    try {
      const data = { language };
      await mutateAsync(data);
      toast({
        title: `✅ ${lang.get('msg.profileUpdated')}`,
        description: lang.get('msg.profileUpdatedDescription'),
      });
    } catch (error) {
      toast({
        title: `✅ ${lang.get('msg.profileUpdated')}`,
        description: lang.get('msg.profileUpdatedDescription'),
      });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center gap-1">
          <Button
            variant="ghost"
            className="w-[38px] hover:bg-secondary/20 h-[38px] p-2 border-none rounded-full outline-none select-none ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0"
          >
            <>{showLangIcon.icon}</>
          </Button>
          <p className="cursor-pointer">{showLangIcon.label}</p>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="relative w-52 z-60">
        <DropdownMenuRadioGroup
          className="flex flex-col gap-0.5"
          value={pickedLanguage}
          onValueChange={setPickedLanguage}
        >
          {languageList.map((item) => (
            <DropdownMenuRadioItem
              key={item.id}
              className={`flex items-center gap-2 px-5 py-3 cursor-pointer ${
                pickedLanguage === item.value && 'bg-neutral-100'
              }`}
              value={item.value}
              onClick={() => {
                setShowLangIcon(item);
                setSelectedLanguage(item.value);
                onLanguageSave(item.value);
              }}
            >
              <span className="w-6 h-6 rounded-full">{item.icon}</span>
              {item.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default LanguageDropDown;

import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import Lang from 'lang.js';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button, buttonVariants } from '@/components/ui/button';
import Routes from '@/router/routes';
import WorkspaceProjectActionsDD from '../dropdowns/WorkspaceProjectActionsDD';
import { EProjectStatus } from '@/enums';
import { cn } from '@/lib/utils';
import { projectStatusColors, projectStatusLabel } from '@/utils/projectStatusLabelAndColors';

export interface IProject {
  name: string;
  img: string;
  updatedAt: string;
  email_verified: boolean;
  email: string;
  _id: string;
  respondents_max: number;
  completed_count: number;
}

export const MyWorkspaceColumns = (
  lang: Lang,
  mergedWorkspacesList: IWorkspace[],
  isUserOwner: boolean,
  isUserEditor: boolean,
  pathname: string,
) => {
  const getStatusLabel = (status: EProjectStatus): string => projectStatusLabel[status];
  const getStatusColor = (status: EProjectStatus): string => projectStatusColors[status];
  const { currentTheme } = useTheme();

  const columns: ColumnDef<IProject>[] = [
    {
      accessorKey: 'title',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.title')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex items-center justify-between gap-3">
          <Link
            className="flex items-center justify-between w-full group"
            to={`${pathname}${Routes.project_design.path}/${row?.original?._id}`}
          >
            <span className="mr-2 whitespace-nowrap">{row?.getValue('title')}</span>
            <span
              style={{ backgroundColor: currentTheme?.primary }}
              className={buttonVariants({
                className:
                  '!text-xs !py-0.5 !px-1.5 !h-8 bg-opacity-80 opacity-0 group-hover:opacity-100 !transition-all !ease-in-out',
              })}
            >
              {lang.get('msg.open')}
            </span>
          </Link>
        </div>
      ),
    },
    {
      accessorKey: 'responses',
      header: () => (
        <Button
          variant="ghost"
          className="pl-0.5"
        >
          {lang.get('msg.responses')}
        </Button>
      ),
      cell: ({ row }) => (
        <div>
          {row?.original?.respondents_max
            ? (
              <p>
                {row?.original?.completed_count}
                /
                {row?.original?.respondents_max}
              </p>
            )
            : <p>/</p>}
        </div>
      ),
    },
    {
      accessorKey: 'status',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.status')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => {
        const status = row.getValue('status') as EProjectStatus;
        return (
          <div
            className={cn(
              'uppercase text-xs whitespace-nowrap rounded-full font-semibold w-fit p-1',
              getStatusColor(status),
            )}
          >
            <p className="px-2">
              {getStatusLabel(status)}
            </p>
          </div>
        );
      },
    },
    {
      accessorKey: 'updatedAt',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0.5"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.updatedAt')}
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{dayjs(row.getValue('updatedAt')).format('DD/MMM/YYYY')}</div>
      ),
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => (
        <WorkspaceProjectActionsDD
          mergedWorkspacesList={mergedWorkspacesList}
          targettedProjectID={row?.original?._id}
          isUserOwner={isUserOwner}
          isUserEditor={isUserEditor}
        />
      ),
    },
  ];
  return columns;
};

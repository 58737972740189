import React, { useState } from 'react';
import * as Yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { IconCrown, IconLoader2 } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { AxiosError } from 'axios';
import { Button } from '../ui/button';
import {
  useMutateRemoveUserFromWorkspace, useMutateTransferWorkspaceOwnership,
  useMutateUserWorkspaceInvitation, useMutationsChangeRoleWorkspace,
} from '@/reactQuery/post';
import axiosInstance from '@/axios/axiosInstance';
import { toast } from '../ui/use-toast';
import RoleDropDown from '../userTable/RoleDropDown';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { getInitials } from '@/utils/stringHelpers';
import { DropdownMenu, DropdownMenuContent, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuTrigger } from '../ui/dropdown-menu';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import InviteOrgMembersInputSearch from '../search/InviteOrgMembersInputSearch';
import RemoveFromWorkspaceModal from './RemoveFromWorkspaceModal';
import { useGetUserData } from '@/reactQuery/get';
import useTranslation from '@/hooks/useTranslation';
import Routes from '@/router/routes';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../ui/alert-dialog';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { EStatusCode, EWorkspaceRoles } from '@/enums';
import UserPlusIcon from '../icons/UserPlusIcon';
import XMarkIcon from '../icons/XMarkIcon';

const defaultValues = {
  email: '',
  role: '',
};

interface IRoles {
  data: EWorkspaceRoles[],
}

export interface IMapProps {
  email: string;
  role: string;
  user_id: number;
  original: string;
  _id: string;
  email_verified: string,
  name: string
}

export interface IformValues {
  email: string;
  role: string;
}

export interface IRoleChangeWorkspace {
  role: string;
  user_id: string;
  workspace_id: string | undefined;
}

interface IWorkspaceModalProps {
  currentWorkspace: string | undefined;
  firstWorkspaceInList: string;
}

interface IWorkspaceOwners {
  role: string;
  email_verified: boolean;
}

export interface ITransferOwnershipProps {
  user_id: string,
  workspace_id: string | undefined,
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  role: Yup.string().required('User role is required'),
});

function WorkspaceInviteModal(
  { currentWorkspace, firstWorkspaceInList }: IWorkspaceModalProps,
) {
  const { isUserOwner, isUserEditor } = useWorkspaceRoles(currentWorkspace);
  const { lang } = useTranslation();
  const { data: user } = useGetUserData();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [input, setInput] = useState('');
  const navigate = useNavigate();
  const { organization_id } = useParams();

  const {
    handleSubmit,
    control,
    reset, trigger,
    formState: { errors, isSubmitting },
  } = useForm<IformValues>({ defaultValues, resolver: yupResolver(validationSchema) });

  const [rowModalData, setRowModalData] = useState('');

  const { data: usersData } = useQuery({
    queryFn: () => axiosInstance.get(`workspaces/get_members/${currentWorkspace}`),
    queryKey: ['table_users', currentWorkspace],
    enabled: !!currentWorkspace,
  });

  const { data: rolesData } = useQuery<IRoles>({
    queryFn: () => axiosInstance.get('workspaces/get_roles'),
    queryKey: ['roles_data'],
  });

  const numberOfTheWorkspaceOwners = usersData?.data?.filter(
    (item: IWorkspaceOwners) => item?.role === EWorkspaceRoles.OWNER && item?.email_verified === true,
  );

  const isOwner = (userRole: string) => userRole === EWorkspaceRoles.OWNER;

  const [selectedRole, setSelectedRole] = useState<string | undefined>('');
  const [openDeletingModal, setOpenDeletingModal] = useState(false);

  const { mutateAsync } = useMutateUserWorkspaceInvitation();
  const { mutateAsync: mutateAsyncChangeRole } = useMutationsChangeRoleWorkspace();
  const { mutateAsync: mutateAsyncRemoveUser } = useMutateRemoveUserFromWorkspace();
  const { mutateAsync: mutateAsyncTransferOwnership } = useMutateTransferWorkspaceOwnership();

  const handleTransferOwnership = async (formData: ITransferOwnershipProps) => {
    try {
      await mutateAsyncTransferOwnership(formData);
      toast({ description: `✅ ${lang.get('msg.ownershipSuccessfullyTransferred')}` });
    } catch {
      toast({ description: lang.get('errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  // invite user
  const onSubmit: SubmitHandler<IformValues> = async (data: IformValues) => {
    try {
      const formData = {
        email: data?.email,
        role: data?.role,
        workspace_id: currentWorkspace,
      };
      await mutateAsync(formData);
      toast({
        description: `✅ ${lang.get('msg.theUserNowHasAccessToThisWorkspace')}`,
      });
      setInput('');
      reset();
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.EMAIL_TAKEN) {
        toast({
          title: `${lang.get('msg.userAlreadyInvited')}!`,
          description: `${lang.get('msg.alreadyInvitedReminder')}.`,
        });
      } else if (axiosError?.status === EStatusCode.NOT_ORGANIZATION_MEMBER) {
        toast({ description: `${lang.get('msg.noUserWithThisEmailInTheOrg')}` });
      } else if (axiosError?.status === EStatusCode.ALREDY_WORKSPACE_MEMBER) {
        toast({ description: `${lang.get('msg.userIsAlreadyMemberOfThisWorkspace')}` });
      } else if (axiosError?.status === EStatusCode.INVALID_ROLE) {
        toast({ description: `${lang.get('msg.onlyOwnerCanChangeRoles')}` });
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: `${lang.get('msg.invitationNotDelivered')}.`,
        });
      }
    }
  };

  const handleRoleChange = async (data: IRoleChangeWorkspace) => {
    try {
      await mutateAsyncChangeRole(data);
      toast({
        title: `✅ ${lang.get('msg.userRoleSuccessfullyUpdated')}`,
      });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.INVALID_ROLE) {
        toast({ description: `${lang.get('msg.onlyOwnerCanChangeRoles')}` });
      } else {
        toast({
          variant: 'destructive',
          title: `${lang.get('msg.userRoleNotUpdated')}`,
        });
      }
    }
  };

  const removeUserFromWorkspaceFunc = async (user_id: string) => {
    try {
      await mutateAsyncRemoveUser({
        workspace: currentWorkspace,
        user_id,
      });

      toast({
        description: lang.get('msg.userSuccessfullyRemoved'),
      });

      if (user_id === user?._id) {
        navigate(`/organization/${organization_id}${Routes.workspace.path}/${firstWorkspaceInList}`);
        setOpenShareModal(false);
      }
    } catch {
      toast({
        description: lang.get('msg.errorPleaseTryAgain'),
      });
    }
  };

  const filteredFromOwner = rolesData?.data?.filter((item: string) => item !== EWorkspaceRoles.OWNER);

  return (
    <AlertDialog open={openShareModal}>
      <AlertDialogTrigger asChild onClick={() => setOpenShareModal(true)}>
        {(isUserOwner || isUserEditor)
          && (
            <Button type="button" className="flex items-center gap-1.5 -mb-1" variant="ghost">
              <UserPlusIcon className="size-5" />
              {lang.get('msg.invite')}
            </Button>
          )}
      </AlertDialogTrigger>
      <AlertDialogContent className="pb-16 max-w-fit">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center justify-between w-full mb-2">
            {lang.get('msg.inviteOrgMembersToWorkspace')}
            <button
              onClick={() => setOpenShareModal(false)}
              type="button"
            >
              <XMarkIcon className="size-5" />
            </button>
          </AlertDialogTitle>
          <hr />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex items-center md700:flex-wrap"
          >
            <label className="relative flex flex-col mt-6 -mr-px">
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InviteOrgMembersInputSearch
                    field={field}
                    errors={errors}
                    trigger={trigger}
                    setInput={setInput}
                    input={input}
                  />
                )}
              />
            </label>
            <label className="relative flex flex-col mt-6">
              <Controller
                name="role"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <RoleDropDown
                    field={field}
                    errors={errors?.role?.message}
                    roles={rolesData}
                  />
                )}
              />
              <p
                className={`text-xs  font-medium text-red-600 opacity-0 absolute -bottom-5 ${errors?.role?.message && 'opacity-100'
                }`}
              >
                {errors?.role?.message}
              </p>
            </label>
            <Button
              variant="default"
              style={{ opacity: !isSubmitting ? 1 : 0.8 }}
              disabled={isSubmitting}
              type="submit"
              className="flex items-center ml-4 mt-6 gap-1.5 w-fit select-none"
            >
              {isSubmitting ? (
                <IconLoader2 className="w-4 h-4 animate-spin" />
              ) : (
                <>
                  <UserPlusIcon className="size-5" />
                  {' '}
                  {lang.get('msg.inviteUser')}
                </>
              )}
            </Button>
          </form>
          {/* members */}
          <div className="flex flex-col w-full gap-4 pt-8">
            <h3 className="text-sm font-bold uppercase">
              {lang.get('msg.workspaceMembers')}
              {' '}
              (
              {usersData?.data?.filter((item: { email_verified: boolean }) => item?.email_verified).length}
              )
            </h3>
            <div className="flex flex-col w-full gap-6 mt-3 overflow-y-auto max-h-80">
              {React.Children.toArray(usersData?.data?.map((row: IMapProps) => (
                row?.email_verified && (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-3">
                      <div className="flex items-center gap-3 lowercase">
                        <div className="flex items-center justify-center  uppercase border rounded-full  min-w-[38px] min-h-[38px]">
                          <Avatar>
                            <AvatarImage src="" />
                            <AvatarFallback className="font-semibold text-dark-text">
                              {getInitials(row?.name)}
                            </AvatarFallback>
                          </Avatar>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-[15px] font-semibold leading-4">{row?.name}</p>
                        <p className="text-sm font-normal leading-5 text-light-gray">{row?.email}</p>
                      </div>
                    </div>
                    <div>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <div className="relative flex">
                            <TooltipWrapper
                              text={`${lang.get('msg.roleChangeNotAllowedOwnerRole')}`}
                              className={`mb-1 z-50 max-w-xs text-center hidden
                              ${isOwner(row?.role) && numberOfTheWorkspaceOwners.length <= 1 && 'flex'}`}
                            >
                              <div>
                                <Button
                                  disabled={isOwner(row.role)
                                    && numberOfTheWorkspaceOwners.length <= 1}
                                  variant="outline"
                                  className="capitalize outline-none select-none ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0 disabled:bg-gray-100 disabled:cursor-not-allowed "
                                >
                                  {lang.get(`msg.${row?.role}`)}
                                </Button>
                              </div>
                            </TooltipWrapper>
                          </div>
                        </DropdownMenuTrigger>
                        {/* if its not the owner */}
                        {!isOwner(row.role) && isUserOwner
                          && (
                            <DropdownMenuContent className="w-64 z-80">
                              <DropdownMenuRadioGroup
                                value={selectedRole?.toString()}
                                onValueChange={(value: string) => {
                                  if (value === row?._id) {
                                    setOpenDeletingModal(true);
                                    setRowModalData(row?._id);
                                    return;
                                  }
                                  setSelectedRole(value);
                                  if (row.role !== value) {
                                    handleRoleChange({
                                      role: value,
                                      user_id: row?._id,
                                      workspace_id: currentWorkspace,
                                    });
                                  }
                                }}
                              >
                                {filteredFromOwner?.map((role: string) => (
                                  <DropdownMenuRadioItem
                                    key={role}
                                    className={`flex items-center gap-2 px-5 py-3 cursor-pointer 
                                ${selectedRole === role && 'bg-neutral-100'}`}
                                    value={role}
                                  >
                                    {lang.get(`msg.${role}`)}
                                  </DropdownMenuRadioItem>
                                ))}
                                <DropdownMenuSeparator />
                                <DropdownMenuItem
                                  onClick={() => handleTransferOwnership({
                                    user_id: row._id,
                                    workspace_id: currentWorkspace,
                                  })}
                                  className="flex items-center gap-2 py-2 pl-5 pr-1 text-sm cursor-pointer hover:bg-secondary-background"
                                >
                                  Transfer Ownership
                                  <IconCrown className="text-yellow-600" size={16} />
                                </DropdownMenuItem>
                                <DropdownMenuSeparator />
                                {(isUserOwner || isUserEditor)
                                  && (
                                    <DropdownMenuRadioItem
                                      value={row?._id}
                                      className="flex items-center gap-2 py-2 pl-5 pr-1 text-sm font-bold text-red-600 cursor-pointer"
                                    >
                                      {lang.get('msg.removeUserFromWorkspace')}
                                    </DropdownMenuRadioItem>
                                  )}
                              </DropdownMenuRadioGroup>

                            </DropdownMenuContent>
                          )}
                      </DropdownMenu>
                    </div>
                    {/* remove from workspace modal */}
                    <RemoveFromWorkspaceModal
                      setOpenDeletingModal={setOpenDeletingModal}
                      openDeletingModal={openDeletingModal}
                      row_id={rowModalData}
                      removeUserFromWorkspaceFunc={removeUserFromWorkspaceFunc}
                    />
                  </div>
                )
              )))}
            </div>
          </div>
        </AlertDialogHeader>

      </AlertDialogContent>
    </AlertDialog>
  );
}

export default WorkspaceInviteModal;

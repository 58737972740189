import { usePDF, Margin } from 'react-to-pdf';
import { IconDownload, IconLoader2 } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import SummarySkeletonLoader from '@/components/loaders/SummarySkeletonLoader';
import Statistics from '../Content/overview_content/Statistics';
import DataWithSteps from '@/components/QuestionItem/DataWithSteps';
import InterestingQuotes from '@/components/QuestionItem/InterestingQuotes';
import useReportStore from '@/store/ReportStore';
import { Button } from '@/components/ui/button';
import Categories from '@/components/QuestionItem/Categories';
import { translateInterestingQuotes, translateKeyThemes, translateNoDataAll, translateOpportunities, translateSummary } from '../Content/project_results/resultsPageTranslations';
import { toast } from '@/components/ui/use-toast';

interface IReport {
  isPendingReRun: boolean;
  analysisLanguage: string;
}

function ProjectReport({ isPendingReRun, analysisLanguage }: IReport) {
  const { toPDF, targetRef } = usePDF({ filename: 'tellet-report.pdf', page: { margin: Margin.MEDIUM } });
  const { project_id } = useParams();
  const [loading, setLoading] = useState(false);
  const { currentTheme } = useTheme();
  const reportData = useReportStore((state) => state.reportData);
  const questionData = useReportStore((state) => state.questionData);
  const isStatisticChecked = useReportStore((state) => state.isStatisticChecked);
  const isSummaryChecked = useReportStore((state) => state.isSummaryChecked);
  const isKeyThemesChecked = useReportStore((state) => state.isKeyThemesChecked);
  const isQuotesChecked = useReportStore((state) => state.isQuotesChecked);
  const isOpportunitiesChecked = useReportStore((state) => state.isOpportunitiesChecked);

  const handleDownloadReport = async () => {
    setLoading(true);

    setTimeout(async () => {
      const target = targetRef.current;
      if (target && target.innerHTML.trim() !== '') {
        toPDF();
      } else {
        toast({ description: 'An error occurred while generating the PDF. Please try again.', variant: 'destructive' });
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <div
      style={{ backgroundColor: currentTheme?.background }}
      className="flex flex-col items-center max-w-[1300px] mx-auto justify-center w-full h-full"
    >
      {((reportData.length !== 0 && reportData !== undefined && Object.keys(reportData).length !== 0)
       || (questionData.length !== 0 && questionData !== undefined && Object.keys(questionData).length !== 0))
        ? (
          <div
            style={{ backgroundColor: currentTheme?.background }}
            className="w-full px-1"
          >
            <div className="flex items-center justify-center w-full pb-5">
              <Button
                variant="default"
                disabled={loading}
                onClick={handleDownloadReport}
              >
                {loading ? <IconLoader2 size={20} className="mr-2 animate-spin" /> : <IconDownload size={20} className="mr-2" />}
                Download Report
              </Button>
            </div>
            <div
              style={{ backgroundColor: currentTheme?.background }}
              className="w-full"
              ref={targetRef}
            >
              <div
                style={{ backgroundColor: currentTheme?.background }}
                className="flex flex-col w-full gap-16 pb-12"
              >
                {isStatisticChecked && <Statistics project_id={project_id} showSelect={false} data={reportData?.statistics} isLoading={isPendingReRun} />}
                {/* summary */}
                {isSummaryChecked && (
                <div className="flex flex-col w-full">
                  <h3
                    className="mb-6 font-semibold"
                    style={{ color: currentTheme?.primary }}
                  >
                    {translateSummary[analysisLanguage] || translateSummary.en}
                  </h3>
                  <div className="max-w-[1300px]">
                    {isPendingReRun
                      ? <SummarySkeletonLoader />
                      : <p>{reportData?.summary}</p>}
                  </div>
                </div>
                )}
                {/* key themes */}
                {isKeyThemesChecked && (
                <div className="flex flex-col w-full">
                  <h3
                    className="mb-6 font-semibold"
                    style={{ color: currentTheme?.primary }}
                  >
                    {translateKeyThemes[analysisLanguage] || translateKeyThemes.en}
                  </h3>
                  <DataWithSteps data={reportData?.themes} isLoading={isPendingReRun} />
                </div>
                )}
                {/* quotes */}
                {isQuotesChecked && (
                <div className="flex flex-col w-full">
                  <h3
                    className="mb-6 font-semibold"
                    style={{ color: currentTheme?.primary }}
                  >
                    {translateInterestingQuotes[analysisLanguage] || translateInterestingQuotes.en}
                  </h3>
                  <div className="flex items-center justify-center w-full">
                    <InterestingQuotes analysisLanguage={analysisLanguage} data={reportData?.quotes} isLoading={isPendingReRun} />
                  </div>
                </div>
                )}
                {/* opportunities */}
                {isOpportunitiesChecked && (
                <div className="flex flex-col w-full">
                  <h3
                    className="mb-6 font-semibold"
                    style={{ color: currentTheme?.primary }}
                  >
                    {translateOpportunities[analysisLanguage] || translateOpportunities.en}
                  </h3>
                  <DataWithSteps data={reportData?.opportunities} isLoading={isPendingReRun} />
                </div>
                )}
              </div>

              {/* questions */}
              {/*  eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {questionData && questionData.map((item: any) => (
                <div key={item?.question?._id} className="flex flex-col w-full gap-4 pb-10">
                  <div
                    style={{ borderColor: currentTheme?.primary, color: currentTheme?.primary }}
                    className="flex items-center w-full max-w-[700px] justify-between px-5 mb-2 font-semibold border rounded-lg shadow-md"
                  >
                    <p className="my-2 text-lg">
                      Question:
                      {' '}
                      {item?.question?.title}
                    </p>
                  </div>
                  <div className="flex flex-col w-full gap-10 py-4 pl-1">
                    {/* summary */}
                    <div className="flex flex-col w-full">
                      <h3
                        className="mb-4 font-semibold"
                        style={{ color: currentTheme?.primary }}
                      >
                        {translateSummary[analysisLanguage] || translateSummary.en}
                      </h3>
                      <div className="max-w-[1300px]">
                        {isPendingReRun
                          ? <SummarySkeletonLoader />
                          : <p>{item?.question?.summary}</p>}
                      </div>
                    </div>
                    {/* categories */}
                    <div className="flex flex-col w-full">
                      <Categories
                        analysisLanguage={analysisLanguage}
                        projectID={project_id}
                        questionID={item?.question?.question_id}
                      />
                    </div>
                    {/* key themes */}
                    <div className="flex flex-col w-full">
                      <h3
                        className="mb-4 font-semibold"
                        style={{ color: currentTheme?.primary }}
                      >
                        {translateKeyThemes[analysisLanguage] || translateKeyThemes.en}
                      </h3>
                      <DataWithSteps data={item?.question?.themes} isLoading={isPendingReRun} />
                    </div>
                    {/* quotes */}
                    <div className="flex flex-col w-full">
                      <h3
                        className="mb-4 font-semibold"
                        style={{ color: currentTheme?.primary }}
                      >
                        {translateInterestingQuotes[analysisLanguage] || translateInterestingQuotes.en}
                      </h3>
                      <div className="flex items-center justify-center w-full">
                        <InterestingQuotes analysisLanguage={analysisLanguage} data={item?.question?.quotes} isLoading={isPendingReRun} />
                      </div>
                    </div>
                    {/* opportunities */}
                    <div className="flex flex-col w-full mt-6">
                      <h3
                        className="mb-4 font-semibold"
                        style={{ color: currentTheme?.primary }}
                      >
                        {translateOpportunities[analysisLanguage] || translateOpportunities.en}
                      </h3>
                      <DataWithSteps data={item?.question?.opportunities} isLoading={isPendingReRun} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
        : (
          <div className="flex flex-col items-center justify-center w-full h-full min-h-[65vh] text-center">
            <p className="text-lg">{translateNoDataAll[analysisLanguage] || translateNoDataAll.en}</p>
          </div>
        )}
    </div>
  );
}

export default ProjectReport;

import React from 'react';
import { IconArrowBack, IconX } from '@tabler/icons-react';

interface IAiProps {
  setCreationType: React.Dispatch<React.SetStateAction<string>>
  closeModalAndRemoveData:() => void;
}

function CreateWithAi({ setCreationType, closeModalAndRemoveData }: IAiProps) {
  return (
    <div className="relative flex flex-col w-full h-full">
      {/* close modal */}
      <button className="absolute top-2 right-2" type="button" onClick={closeModalAndRemoveData}><IconX size={24} /></button>
      {/* go back */}
      <button className="absolute left-2" type="button" onClick={() => setCreationType('')}><IconArrowBack size={24} /></button>
      {/* messages */}
      <div className="w-full h-full mt-14">messages</div>
    </div>
  );
}

export default CreateWithAi;

import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '@/axios/axiosInstance';
import { useGetUserData } from '@/reactQuery/get';
import Routes from '@/router/routes';
import OrganizationDropDown from './OrganizationDropDown';
import ProjectNavbar from './ProjectNavbar';
import UserIconDropDown from './UserIconDropDown';

function Navbar() {
  const location = useLocation();
  const { data: user } = useGetUserData();
  const { organization_id } = useParams();
  const { currentTheme } = useTheme();

  const { data: orgData } = useQuery({
    queryFn: () => axiosInstance.get(`/organizations/get/${organization_id}`),
    queryKey: ['current_organization_data', { organization_id }],
    select: (data) => data.data,
  });

  if (location.pathname.includes(Routes.project.path)) return <ProjectNavbar user={user} orgData={orgData} />;

  return (
    <nav
      style={{ backgroundColor: currentTheme?.background }}
      className="fixed left-0 right-0 flex items-center justify-between max-w-full px-5 py-2 transition-all ease-in border z-60"
    >
      <OrganizationDropDown currentOrganization={organization_id} orgData={orgData} />
      <div className="flex items-center gap-4">
        {/* user profile */}
        <button type="button" aria-label="user profile">
          <UserIconDropDown user={user} />
        </button>
      </div>
    </nav>
  );
}

export default Navbar;

import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IconLoader2 } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Textarea } from '@/components/ui/textarea';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { useMutateProjectUpdate } from '@/reactQuery/post';
import useTranslation from '@/hooks/useTranslation';
import InstructionsSkelet from './components/InstructionsSkelet';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import languagesList from '@/lang/languages/languagesList';
import RequiredLabel from '@/components/labels/RequiredLabel';
import { Button } from '@/components/ui/button';
import { ECompanyThemeName, EStatusCode } from '@/enums';
import DropdownProjectListLabel from './components/DropdownProjectListLabel';
import toneOfVoiceList from './components/toneOfVoiceList';
import DropdownProjectListValue from './components/DropdownProjectListValue';

export interface Option {
  id: number;
  label: string;
  value: string;
}

interface IformValues {
  type_of_interview?: string,
  interviewer_name?: string,
  company_name?: string,
  interview_goal?: string,
  interview_audience?: string,
  tone_of_voice?: string,
  privacy_policy?: string,
  language?: string;
  analysis_language?: string;
  allow_language_selection?: boolean | null;
}

const validationSchema = Yup.object().shape({
  type_of_interview: Yup.string(),
  interviewer_name: Yup.string(),
  company_name: Yup.string(),
  interview_goal: Yup.string(),
  interview_audience: Yup.string(),
  tone_of_voice: Yup.string(),
  privacy_policy: Yup.string().url('Please enter a valid URL'),
  language: Yup.string(),
  analysis_language: Yup.string(),
  allow_language_selection: Yup.boolean().nullable(),
});

interface IInstructionsProps {
  projectId: string | undefined
  singleProjectData: IProjectProps;
  workspaceID: string | undefined
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
}

function Instructions({ projectId, workspaceID, isProjectPublished, isProjectCompleted, singleProjectData }: IInstructionsProps) {
  const { lang } = useTranslation();
  const [interviewGoalCharacters, setInterviewGoalCharacters] = useState(singleProjectData?.interview_goal || '');
  const [interviewAudienceCharacters, setInterviewAudienceCharacters] = useState(singleProjectData?.interview_audience || '');
  const { isUserViewer } = useWorkspaceRoles(workspaceID);
  const [formChanged, setFormChanged] = useState(false);
  const { themeName } = useTheme();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type_of_interview: singleProjectData?.type_of_interview || '',
      interviewer_name: singleProjectData?.interviewer_name || '',
      company_name: singleProjectData?.company_name || '',
      interview_goal: singleProjectData?.interview_goal || '',
      interview_audience: singleProjectData?.interview_audience || '',
      tone_of_voice: singleProjectData?.tone_of_voice || '',
      language: singleProjectData?.language || '',
      allow_language_selection: singleProjectData?.allow_language_selection || null,
      privacy_policy: singleProjectData?.privacy_policy || '',
      analysis_language: singleProjectData?.analysis_language !== '' ? singleProjectData?.analysis_language : 'English',
    },

    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync, status } = useMutateProjectUpdate();

  const onSubmit: SubmitHandler<IformValues> = async (data: IformValues) => {
    try {
      await mutateAsync({
        _id: projectId,
        ...data,
      });
      setFormChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const handleFormChanged = () => {
    setFormChanged(true);
  };

  useEffect(() => {
    setValue('type_of_interview', singleProjectData?.type_of_interview);
    setValue('interviewer_name', singleProjectData?.interviewer_name);
    setValue('company_name', singleProjectData?.company_name);
    setValue('interview_goal', singleProjectData?.interview_goal);
    setValue('interview_audience', singleProjectData?.interview_audience);
    setValue('tone_of_voice', singleProjectData?.tone_of_voice);
    setValue('privacy_policy', singleProjectData?.privacy_policy);
    setValue('language', singleProjectData?.language);
    setValue('allow_language_selection', singleProjectData?.allow_language_selection);
    setValue('analysis_language', singleProjectData?.analysis_language !== '' ? singleProjectData?.analysis_language : 'English');
  }, [setValue, singleProjectData?.analysis_language, singleProjectData?.allow_language_selection,
    singleProjectData?.company_name, singleProjectData?.interview_audience, singleProjectData?.interview_goal,
    singleProjectData?.interviewer_name, singleProjectData?.language, singleProjectData?.privacy_policy,
    singleProjectData?.tone_of_voice, singleProjectData?.type_of_interview]);

  return (
    <div className="w-full h-full pl-1 pr-5">
      {singleProjectData
        ? (
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-5 pb-10">
            {/* -- interviewer name -- */}
            <RequiredLabel title="interviewerName" tooltip={lang.get('msg.interviewerNameInfo')}>
              <Input
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('interviewer_name')}
                type="text"
                placeholder={lang.get('msg.interviewerNamePlaceholder')}
                onChange={handleFormChanged}
              />
            </RequiredLabel>

            {/* -- company name -- */}
            <RequiredLabel title="companyName" tooltip={lang.get('msg.companyNameInfo')}>
              <Input
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('company_name')}
                type="text"
                placeholder={lang.get('msg.companyNamePlaceholder')}
                onChange={handleFormChanged}
              />
            </RequiredLabel>

            {/* -- interview goal -- */}
            <RequiredLabel title="interviewGoal" tooltip={lang.get('msg.interviewGoalInfo')}>
              <Textarea
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('interview_goal')}
                maxLength={700}
                className="h-28 rounded-xl"
                placeholder={lang.get('msg.interviewGoalPlaceholder')}
                onChange={(e) => { setInterviewGoalCharacters(e.target.value); handleFormChanged(); }}
              />
              <p className="text-xs text-right text-light-dark">
                {700 - interviewGoalCharacters.length}
                {' '}
                {lang.get('msg.charactersLeft')}
              </p>
            </RequiredLabel>

            {/* -- interview audience -- */}
            <RequiredLabel
              title="interviewAudience"
              tooltip={
                themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.interviewAudienceInfo_empathy')
                  : lang.get('msg.interviewAudienceInfo')
              }
            >
              <Textarea
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('interview_audience')}
                onChange={(e) => { setInterviewAudienceCharacters(e.target.value); handleFormChanged(); }}
                className="h-28 rounded-xl"
                maxLength={100}
                placeholder={lang.get('msg.interviewAudiencePlaceholder')}
              />
              <p className="text-xs text-right text-light-dark">
                {100 - interviewAudienceCharacters.length}
                {' '}
                {lang.get('msg.charactersLeft')}
              </p>
            </RequiredLabel>

            {/* language */}
            <RequiredLabel title="primaryLanguage" tooltip={lang.get('msg.primaryLanguageInfo')}>
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <DropdownProjectListLabel
                    value={field?.value}
                    onChange={(value) => [field.onChange(value), handleFormChanged()]}
                    isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                    data={languagesList.language}
                    translation
                    placeholder={lang.get('msg.selectInterviewLanguage')}
                  />
                )}
              />
            </RequiredLabel>

            {/* allow language changing */}
            <RequiredLabel title="allowParticipantsToChooseTheLanguage" tooltip={lang.get('msg.allowToChooseLanguageInfo')}>
              <Controller
                name="allow_language_selection"
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col gap-1.5 mb-2">
                    <div className="flex items-center gap-1.5">
                      <input
                        type="radio"
                        id="allowLanguageYes"
                        value="true"
                        checked={field.value === true}
                        disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                        onChange={() => {
                          setValue('allow_language_selection', true);
                          handleFormChanged();
                        }}
                      />
                      <label className="text-sm" htmlFor="allowLanguageYes">{lang.get('msg.yes')}</label>
                    </div>
                    <div className="flex items-center gap-1.5">
                      <input
                        type="radio"
                        id="allowLanguageNo"
                        value="false"
                        checked={field.value === false}
                        disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                        onChange={() => {
                          setValue('allow_language_selection', false);
                          handleFormChanged();
                        }}
                      />
                      <label className="text-sm" htmlFor="allowLanguageNo">{lang.get('msg.no')}</label>
                    </div>
                  </div>
                )}
              />
            </RequiredLabel>

            {/* analysis language */}
            <RequiredLabel title="analysisLanguage" tooltip={lang.get('msg.analysisLanguageInfo')}>
              <Controller
                name="analysis_language"
                control={control}
                render={({ field }) => (
                  <DropdownProjectListLabel
                    value={field?.value}
                    onChange={(value) => [field.onChange(value), handleFormChanged()]}
                    isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                    data={languagesList.language}
                    translation
                    placeholder={lang.get('msg.selectAnalysisLanguage')}
                  />
                )}
              />
            </RequiredLabel>

            {/* privacy policy */}
            <RequiredLabel title="privacyPolicy" tooltip={lang.get('msg.privacyPolicyInfo')}>
              <div className="relative w-full">
                <Input
                  disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                  {...register('privacy_policy')}
                  type="text"
                  placeholder={lang.get('msg.insertYourPrivacyPolicyHere')}
                  hasError={!!errors?.privacy_policy}
                  onChange={handleFormChanged}
                />
                {!!errors?.privacy_policy && (
                  <p className="left-0 text-xs font-medium text-red-600 -bottom-5">
                    {errors?.privacy_policy?.message}
                  </p>
                )}
              </div>
            </RequiredLabel>

            {/* -- tone -- */}
            <RequiredLabel title="toneOfVoice" tooltip={lang.get('msg.toneOfVoiceInfo')}>
              <Controller
                name="tone_of_voice"
                control={control}
                render={({ field }) => (
                  <DropdownProjectListValue
                    value={field?.value}
                    onChange={(value) => {
                      field.onChange(value);
                      handleFormChanged();
                    }}
                    isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                    data={toneOfVoiceList}
                    direction="bottom"
                    translation
                    placeholder={lang.get('msg.selectToneOfVoice')}
                  />
                )}
              />
            </RequiredLabel>
            {/* save button */}
            <Button
              variant="default"
              className="w-full mt-2"
              disabled={status === 'pending' || !formChanged}
            >
              {status === 'pending' && <IconLoader2 className="mr-2 animate-spin" size={20} />}
              {!formChanged && status === 'idle' && lang.get('msg.noChanges')}
              {!formChanged && status === 'success' && lang.get('msg.allSaved')}
              {formChanged && lang.get('msg.saveChanges')}
            </Button>
          </form>
        ) : (
          <InstructionsSkelet />
        )}
    </div>
  );
}

export default Instructions;

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import Toaster from './components/ui/toaster';
import { AbilityProvider } from './context/AbilityContext';
import RouterWrapper from './router';

const queryClient = new QueryClient();

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['*'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <AbilityProvider>
        <Toaster />
        <RouterProvider router={RouterWrapper()} />
        <ReactQueryDevtools initialIsOpen={false} />
      </AbilityProvider>
    </QueryClientProvider>
  );
}

export default Root;

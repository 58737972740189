import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { FieldErrors, UseFormTrigger } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { IconUserOff } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { LangContext } from '@/context/LangProvider';
import { Input } from '../ui/input';
import { toast } from '../ui/use-toast';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { getInitials } from '@/utils/stringHelpers';
import axiosInstance from '@/axios/axiosInstance';

interface IOrganization {
  name: string;
  email: string;
  _id: string;
}

interface IOrganization {
  name: string;
  email: string;
  _id: string;
}

interface IFormValues {
  email: string;
  name: string;
}

interface IModalProps {
  errors: FieldErrors<IFormValues>;
  field: {
    onChange: (value: string) => void;
    name: string;
    value: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>
  input: string;
  setInput: Dispatch<SetStateAction<string>>;
}

interface IResults {
  email: string;
  email_verified: boolean;
}

function InviteOrgMembersInputSearch({ errors, field, trigger, input, setInput }: IModalProps) {
  const { lang } = useContext(LangContext);
  const [resultsData, setResultsData] = useState<IOrganization[]>([]);
  const resultsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { organization_id } = useParams();

  const { data: orgData } = useQuery({
    queryFn: () => axiosInstance.get(`/organizations/get_members/${organization_id}`),
    queryKey: ['organization_members'],
  });

  const allMembers = orgData?.data;

  const filterOrganizations = (value: string) => {
    try {
      const results = allMembers.filter((org: IResults) => value && org.email
       && org.email_verified && org.email.toLowerCase().includes(value.toLowerCase()));
      setResultsData(results);
    } catch (err) {
      toast({ description: 'Error. Please try again.' });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current
        && resultsRef.current
        && !inputRef.current.contains(event.target as Node)
        && !resultsRef.current.contains(event.target as Node)
      ) {
        setResultsData([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleResultClick = (value: string) => {
    setInput(value);
    setResultsData([]);
    field.onChange(value);
    trigger(field.name);
  };

  const handleChange = (value: string) => {
    filterOrganizations(value);
    setInput(value);
  };

  return (
    <div className="relative w-full">
      <div className="flex flex-col w-full">
        <Input
          ref={inputRef}
          autoComplete="off"
          placeholder={`${lang.get('msg.enterTheUserEmail')}...`}
          value={input}
          onChange={(e) => {
            field.onChange(e.target.value);
            handleChange(e.target.value);
          }}
          className={`w-80 rounded-tr-none rounded-br-none ${resultsData.length > 0 && 'rounded-bl-none'} !border-r-transparent shadow ${
            errors?.email?.message && 'border-red-600/50'
          }`}
        />
        <p
          className={`text-xs font-medium text-red-600 opacity-0 absolute -bottom-5 ${
            errors?.email?.message && 'opacity-100'
          }`}
        >
          {errors?.email?.message}
        </p>
      </div>
      {resultsData.length > 0 && (
      <div ref={resultsRef} className="absolute z-50 flex flex-col w-[150%] md700:w-full overflow-y-auto bg-white border shadow rounded-b-md top-[39px] max-h-64">
        {resultsData.map((item) => (
          <button
            type="button"
            onClick={() => handleResultClick(item?.email)}
            key={item?._id}
            className="flex px-4 py-3 text-sm font-normal bg-white border-none outline-none cursor-pointer hover:bg-secondary-background"
          >
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-3 lowercase">
                <div className="flex items-center justify-center  uppercase border rounded-full min-w-[38px] min-h-[38px]">
                  <Avatar>
                    <AvatarImage src="" />
                    <AvatarFallback className="font-semibold text-dark-text">
                      {item?.name ? getInitials(item?.name) : <IconUserOff size={20} />}
                    </AvatarFallback>
                  </Avatar>
                </div>
              </div>
              <div className="flex flex-col text-left gap-0.5">
                {/* add backend data */}
                <p className="text-[15px] font-semibold leading-4">{item.name ?? `${lang.get('msg.accountCreationPending')}...`}</p>
                <p className="text-sm font-normal leading-5 text-light-gray">{item.email}</p>
              </div>
            </div>
          </button>
        ))}
      </div>
      )}
    </div>
  );
}

export default InviteOrgMembersInputSearch;

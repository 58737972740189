import { Listbox, Transition } from '@headlessui/react';
import { IconCheck, IconPencil } from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import ManualCategoryEditModal from '../Modals/ManualCategoryEditModal';
import { Button } from '../ui/button';
import useTranslation from '@/hooks/useTranslation';
import { toast } from '../ui/use-toast';
import { useMutateOverrideCategories } from '@/reactQuery/post';

interface ICategory {
  label: string;
  color: string;
  prompt: string;
  id?: string;
}

interface ICell {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categoryData: any;
  categories: ICategory[];
  questionID: string | undefined;
  conversation_id: string;
  project_id?: string;
  workspace_id?: string;
  onLoadingStart: () => void;
  onLoadingEnd: () => void;
}

function MultipleCategoryCell({ categoryData, categories, questionID, conversation_id, project_id, workspace_id, onLoadingStart, onLoadingEnd }: ICell) {
  const [selectedSingleCategory, setSelectedSingleCategory] = useState<{ [key: string]: ICategory }>({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const { lang } = useTranslation();

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const { mutateAsync: mutateAsyncOverrideCategories, isPending } = useMutateOverrideCategories();

  const handleManualCategoryEditMULTIPLE = async () => {
    onLoadingStart();
    try {
      const categoriesArray = Object.values(selectedSingleCategory)
        .filter((category) => category !== null)
        .map((category) => ({ id: category?.id }));

      const transformedData = [{
        conversation_id,
        categories: categoriesArray,
      }];

      await mutateAsyncOverrideCategories({ project_id, questionID, transformedData, workspace_id });
      toast({ description: lang.get('msg.categorySuccessfullyChanged') });
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    } finally {
      onLoadingEnd();
    }
  };

  useEffect(() => {
    if (categoryData?.length > 0 && categories) {
      const matchingCategories = categoryData.filter((cat: { label: string }) => categories?.some((c) => c?.label === cat?.label));

      if (matchingCategories.length > 0) {
        setSelectedSingleCategory(matchingCategories);
      }
    }
  }, [categoryData, categories]);

  return (
    <div className="relative flex justify-end w-full pb-5">
      <ManualCategoryEditModal
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        handleManualCategoryEdit={handleManualCategoryEditMULTIPLE}
        isPending={isPending}
      />

      {/* change category */}
      <Listbox
        multiple
        value={selectedSingleCategory}
        onChange={setSelectedSingleCategory}
      >
        <div className="relative">
          <Listbox.Button className="flex cursor-pointer">
            <span className="block">
              <IconPencil size={18} />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={cn(
              'absolute z-50 w-full max-h-[300px] min-w-[250px] left-1/2 -translate-x-1/2 py-1 mt-1 overflow-hidden text-base bg-white rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm',
              'flex flex-col',
            )}
            >
              <div className="flex-1 overflow-auto">
                {categoryData?.map((cat: { id: string; label: string }) => (
                  <Listbox.Option
                    key={cat.id}
                    className={({ active }) => `relative z-50 cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`}
                    value={cat}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {cat?.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <IconCheck className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </div>
              <div className="p-1.5">
                <Button
                  disabled={Object.values(selectedSingleCategory).length <= 0}
                  onClick={handleOpenEditModal}
                  className="w-full"
                  variant="default"
                >
                  {lang.get('msg.save')}
                </Button>
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default MultipleCategoryCell;

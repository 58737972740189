export enum EMessageType {
  text = 'text',
  video = 'video',
  audio = 'audio',
  error = 'error',
  image = 'image',
}

export enum EWorkspaceRoles {
  OWNER = 'OWNER',
  EDITOR = 'CAN_EDIT',
  VIEWER = 'CAN_VIEW',
}

export enum EOrganizationRoles {
  OWNER = 'OWNER',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
}

export enum EProjectStatus {
  DRAFT = 'DRAFT',
  READY = 'READY',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  REQUEST_APPROVED = 'REQUEST_APPROVED',
  PUBLISHED = 'PUBLISHED',
  COMPLETED = 'COMPLETED',
}

export enum EPanelIntegration {
  NONE = 'NONE',
  TESTING_TIME = 'TESTING_TIME',
}

export enum EStatusCode {
  BAD_REQUEST = 400,
  INVALID_CREDENTIALS = 401,
  EMAIL_UNVERIFIED = 403,
  EMAIL_TAKEN = 422,
  EMAIL_NOT_FOUND = 407,
  OLD_PASSWORD_NOT_MATCH = 408,
  USER_HAS_NO_ORGANIZATIONS = 409,
  INVALID_ROLE = 410,
  NOT_A_MEMBER = 411,
  WORKSPACE_NOT_FOUND = 412,
  ALREDY_ORGANIZATION_MEMBER = 413,
  ALREDY_WORKSPACE_MEMBER = 414,
  NOT_ORGANIZATION_MEMBER = 415,
  NOT_CORRECT_ORGANIZATION = 416,
  NO_ANOTHER_OWNER = 417,
  PROJECT_NOT_READY = 421,
  PROJECT_NOT_APPROVED = 419,
  PROJECT_ALREADY_PUBLISHED = 420,
  INVALID_WORKSPACE_ROLE = 423,
  INVALID_ORGANIZATION_ROLE = 424,
  PROJECT_NOT_PUBLISHED = 425,
  TEST_CONVERSATION_NOT_FOUND = 426,
  ONE_OWNER_ONLY = 427,
  PROJECT_NAME_EXISTS = 428,
  NOT_WORKSPACE_MEMBER = 429,
  CANT_DELETE_PUBLISHED = 430,
  PROJECT_NOT_FOUND = 431,
  CANT_DELETE_WORKSPACE = 432,
  PROJECT_RESPONDENT_EXCEEDED = 433,
  METADATA_REQUIRED = 434,
  INVALID_LANGUAGE = 435,
  NOT_SUBSCRIBED = 436,
  FILE_EMPTY = 437,
  ALREADY_VERIFIED = 438,
  LABELS_MUST_BE_UNIQUE = 439,
  INVALID_PANEL = 440,
  ORGANIZATION_INVITE_ACCEPTED = 441,
  PROJECT_IS_COMPLETED = 442,
  TOKEN_EXPIRED = 444,
}

export enum ECompanyThemeName {
  DEFAULT = 'default',
  EMPATHY = 'empathyiq',
}

export enum ECategoryMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

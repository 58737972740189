import React, { useContext, useState } from 'react';
import { Loader2 } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IconUserPlus } from '@tabler/icons-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { toast } from '../ui/use-toast';
import { useMutateUserOrganizationInvitation } from '@/reactQuery/post';
import { LangContext } from '@/context/LangProvider';
import { EStatusCode } from '@/enums';

interface IformValues {
  email: string;
  role: string;
}

interface IModalProps {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles: AxiosResponse<any, any> | undefined;
}

const defaultValues = {
  email: '',
  role: '',
};

type IInviteForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  role: Yup.string().required('Member role is required'),
});

const roleDescription = [
  'inviteAdminDescription',
  'inviteEditorDescription',
  'inviteViewerDescription',
];

function InviteToOrganizationModal({ roles }: IModalProps) {
  const { lang } = useContext(LangContext);
  const [openInvitingModal, setOpenInvitingModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string>('');

  const { mutateAsync } = useMutateUserOrganizationInvitation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });

  const onSubmit: SubmitHandler<IformValues> = async (data: IInviteForm) => {
    try {
      const formData = {
        email: data?.email,
        role: selectedRoleId.toString(),
      };
      await mutateAsync(formData);
      toast({
        title: `✅ ${lang.get('msg.invitationSent')}`,
        description: `${lang.get('msg.theInvitationHasBeenDeliveredToTheUser')}.`,
      });
      setOpenInvitingModal(false);
      reset();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.data.statusCode === EStatusCode.EMAIL_TAKEN) {
        toast({
          title: `${lang.get('msg.userAlreadyInvited')}!`,
          description: `${lang.get('msg.alreadyInvitedReminder')}.`,
        });
      } else if (err.data.statusCode === EStatusCode.ALREDY_ORGANIZATION_MEMBER) {
        toast({
          description: `${lang.get('msg.userIsAlreadyMemberOfAnOrganization')}`,
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: `${lang.get('msg.invitationNotDelivered')}.`,
        });
      }
    }
  };

  return (
    <>
      {/* trigger */}
      <Button
        variant="default"
        type="button"
        className="flex gap-2"
        onClick={() => setOpenInvitingModal(!openInvitingModal)}
      >
        <IconUserPlus size={17} />
        {lang.get('msg.inviteToOrganization')}
      </Button>
      {/* modal */}
      <AlertDialog open={openInvitingModal}>
        <AlertDialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogHeader>
              <AlertDialogTitle>{lang.get('msg.inviteToOrganization')}</AlertDialogTitle>
              <div className="flex flex-col w-full gap-2 mt-6">
                <label className="relative flex flex-col w-full mb-2">
                  <Input
                    {...register('email')}
                    placeholder={`${lang.get('msg.enterTheUserEmail')}...`}
                    defaultValue=""
                    className={`w-full ${errors?.email?.message && 'border-red-600/50'}`}
                  />
                  <p
                    className={`text-xs md700:hidden font-medium text-red-600 opacity-0 absolute -bottom-5 ${
                      errors?.email?.message && 'opacity-100'
                    }`}
                  >
                    {errors?.email?.message}
                  </p>
                </label>
                <div className="flex flex-col">
                  <p className="mt-3 mb-3 text-base font-medium text-dark-text">
                    {lang.get('msg.assignThisPersonAs')}
                    :
                  </p>
                  {/* choose role */}
                  {React.Children.toArray(roles?.data?.map((item: string, index: number) => (
                    <label className="flex items-center gap-6 px-1 py-3 border-b cursor-pointer border-dark-text/30">
                      <input
                        type="radio"
                        {...register('role', { required: true })}
                        className="rounded-full"
                        value={item}
                        checked={selectedRoleId === item}
                        onChange={() => setSelectedRoleId(item)}
                      />
                      <div className="flex flex-col">
                        <p className="text-base font-bold capitalize text-dark-text">{lang.get(`msg.${item}`)}</p>
                        <p className="text-sm select-none">
                          {lang.get(`msg.${roleDescription[index]}`)}
                        </p>
                      </div>
                    </label>
                  )))}
                  <p
                    className={`text-xs md700:hidden font-medium text-red-600 opacity-0 absolute bottom-[72px] ${
                      errors?.role?.message && 'opacity-100'
                    }`}
                  >
                    {errors?.role?.message}
                  </p>
                </div>
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter className="mt-7">
              <AlertDialogCancel onClick={() => setOpenInvitingModal(false)}>
                {lang.get('msg.close')}
              </AlertDialogCancel>
              <Button
                variant="default"
                type="submit"
                className="flex items-center gap-1"
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader2 size={17} className="animate-spin" />}
                {lang.get('msg.invite')}
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default InviteToOrganizationModal;

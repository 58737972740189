import { create } from 'zustand';

type State = {
  questionsLoading: boolean;
};

type Action = {
  setQuestionsLoading: (category: boolean) => void;
};

const useQuestionsLoadingStore = create<State & Action>((set) => ({
  questionsLoading: false,
  setQuestionsLoading: (loading) => set({ questionsLoading: loading }),
}));

export default useQuestionsLoadingStore;

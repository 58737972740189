import { create } from 'zustand';
import Cookies from 'js-cookie';

type State = {
  authToken: string | null;
  authRole: string[] | null;
};

type Action = {
  setAuthToken: (token: string | null) => void;
  setAuthRole: (roles: string[] | null) => void;
};

const useAuthStore = create<State & Action>((set) => ({
  authToken: Cookies.get('token') || null,
  setAuthToken: (token) => set({ authToken: token }),
  authRole: Cookies.get('role_id')?.split(',') || null,
  setAuthRole: (roles) => set({ authRole: roles }),
}));

export default useAuthStore;

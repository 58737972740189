import { IconGripVertical, IconTrash } from '@tabler/icons-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import { Textarea } from '../ui/textarea';
import { Input } from '../ui/input';
import { cn } from '@/lib/utils';
import getTextColor from '@/utils/getTextColorContrast';

interface IItem {
  id: string;
  color: string;
  label: string;
  prompt: string;
  index: number;
  required: boolean;
  handleDeleteQuestion: (id: string) => void;
  handleUpdateCategory: (id: string, field: string, value: string) => void;
  status: string;
}

function CategoryEditItem({ id, color, label, prompt, index, handleDeleteQuestion, handleUpdateCategory, required, status }: IItem) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const [editedLabel, setEditedLabel] = useState(label);
  const [editedPrompt, setEditedPrompt] = useState(prompt);
  const [editedColor, setEditedColor] = useState(color);
  const [labelError, setLabelError] = useState(false);
  const [promptError, setPromptError] = useState(false);
  const [colorError, setColorError] = useState(false);

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEditedLabel(value);
    handleUpdateCategory(id, 'label', value);
    if (!value.trim()) {
      setLabelError(true);
    } else {
      setLabelError(false);
    }
  };

  const handlePromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setEditedPrompt(value);
    handleUpdateCategory(id, 'prompt', value);
    if (!value.trim()) {
      setPromptError(true);
    } else {
      setPromptError(false);
    }
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEditedColor(event.target.value);
    handleUpdateCategory(id, 'color', event.target.value);
    if (!value.trim()) {
      setColorError(true);
    } else {
      setColorError(false);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: isDragging ? '2px dashed #718096' : '',
    padding: isDragging ? '10px' : '',
    opacity: isDragging ? 0.7 : 1,
    boxShadow: isDragging ? '0 4px 6px rgba(0, 0, 0, 0.4)' : 'none',
  };

  return (
    <div
      className="flex flex-col w-full"
      key={id}
      ref={setNodeRef}
      style={style}
    >
      <div className="flex justify-between w-full gap-2">
        <span
          style={{ backgroundColor: `${color}`, color: getTextColor(color) }}
          className="flex items-center justify-center mr-2 w-6 h-5 mt-2.5 font-medium rounded-md"
        >
          {index + 1}
        </span>
        <Input
          disabled={required || status === 'pending'}
          hasError={labelError}
          value={editedLabel}
          onChange={handleLabelChange}
          placeholder="Title (Required)*"
        />
        <div className="w-fit">
          <Input
            disabled={required || status === 'pending'}
            hasError={colorError}
            type="color"
            value={editedColor}
            onChange={handleColorChange}
            className="!w-14 !max-w-14 cursor-pointer"
          />
        </div>
      </div>
      <div className="flex justify-between h-full">
        <div className="w-[38px] flex flex-col mt-1">
          {status !== 'pending' && (
            <button
              disabled={status === 'pending'}
              type="button"
              className="cursor-grab"
              {...attributes}
              {...listeners}
            >
              <IconGripVertical size={20} />
            </button>
          )}
          {!required && status !== 'pending' && (
            <button
              disabled={status === 'pending'}
              onClick={() => handleDeleteQuestion(id)}
              type="button"
              className="mt-3 text-red-600 hover:text-red-700"
            >
              <IconTrash size={20} />
            </button>
          )}
        </div>
        <Textarea
          disabled={required || status === 'pending'}
          placeholder="Description (Required)*"
          value={editedPrompt}
          onChange={handlePromptChange}
          className={cn('self-end w-full py-2 mt-2 min-h-16', promptError && 'border-red-600')}
        />
      </div>
    </div>
  );
}

export default CategoryEditItem;

import { Link, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import Image from '@/components/atoms/Image';
import { Button, buttonVariants } from '@/components/ui/button';
import { useMutateResendEmailInvitation } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import Routes from '@/router/routes';
import { EStatusCode } from '@/enums';

function VerificationCheckYourEmail() {
  const { mutateAsync: mutateAsyncResend } = useMutateResendEmailInvitation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const onResendSent = async () => {
    try {
      await mutateAsyncResend(email);
      toast({
        title: 'Invitation sent',
        description: 'Please check your email to confirm registration.',
      });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.ALREADY_VERIFIED) {
        toast({
          title: 'Email already verified',
          description: 'Your account is already verified.',
        });
      } else {
        toast({
          title: 'Error',
          description: 'Error, please try again.',
          variant: 'destructive',
        });
      }
    }
  };

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[600px] min-h-screen center border-0 px-2 py-4 min450:bg-white min450:max-w-full">
        <div className="flex flex-col items-center w-full max-w-[600px] rounded-md h-fit p-7 min450:p-5">
          <div className="flex flex-col items-center w-full ring-1 ring-neutral-200 min450:ring-transparent min450:shadow-none min450:p-5 max-w-[500px] gap-10 bg-white rounded-md shadow-md h-fit p-7">
            <Image effect="blur" src={TeletLogo} alt="logo" width={115} height={37} />

            <h1 className="text-xl font-bold">Verify your Email</h1>
            <p className="text-[#5a6a85] leading-6">
              When you click the button, a verification email will be sent to you. Please open the email and click the link to verify your account.
            </p>

            <div className="flex flex-col items-center justify-center w-full gap-2">
              <Button
                onClick={onResendSent}
                className="w-full"
              >
                Resend Email Verification
              </Button>
              <Link
                to={Routes.login.path}
                className={buttonVariants({
                  variant: 'ghost',
                  className: 'transition-all w-full ease-in-out',
                })}
              >
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default VerificationCheckYourEmail;

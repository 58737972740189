import { useEffect, useState } from 'react';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import Cookies from 'js-cookie';
import { AxiosError } from 'axios';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import Image from '@/components/atoms/Image';
import Routes from '@/router/routes';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import useAuthStore from '@/store/AuthStore';

interface ISubmitData {
  token: string;
  mainWorkspace: string;
  organization_id: string;
}

const defaultValues = {
  email: '',
  password: '',
};

type TLoginForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const EmailError = {
  401: 'Invalid email or password',
  500: 'Server error occurred, please try again later',
  403: '',
  422: 'Email already registered',
};

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const { toast } = useToast();
  const [loginError, setLoginError] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const from = location.state?.from || redirectTo;
  const authToken = useAuthStore((state) => state.authToken);
  const setAuthToken = useAuthStore((state) => state.setAuthToken);

  const handleResendEmail = async (email: string) => {
    try {
      setIsLoading(true);
      await axiosInstanceNoAuth.post('/users/resend_verification_email', { email });
      toast({
        title: 'Email sent',
        description: `We've sent you an email to ${email}. Please check your inbox.`,
      });
    } catch {
      toast({
        title: 'Error occurred',
        description: 'Error occurred while sending email. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const setLoginCookies = (data: ISubmitData, remember: boolean) => {
    const options = remember ? { expires: 14 } : { expires: 1 };
    Cookies.set('token', data?.token, options);
    Cookies.set('fallback_workspace', data?.mainWorkspace, options);
    Cookies.set('main_organization', data?.organization_id, options);
    Cookies.set('remember_me', remember ? 'true' : 'false', options);
  };

  // eslint-disable-next-line consistent-return
  const onSubmit: SubmitHandler<TLoginForm> = async (formData: TLoginForm) => {
    try {
      const lowercaseEmail = formData.email.toLowerCase();
      const res = await axiosInstanceNoAuth.post('/users/login', { ...formData, email: lowercaseEmail });
      const { data } = res;
      setLoginCookies(data, rememberMe);
      setAuthToken(data?.token);
      setLoginError('');
      setRedirectTo(`${Routes.organization.path}/${data.last_organization || data.organization_id}${Routes.workspace.path}/${data?.last_workspace || data?.mainWorkspace}`);
      navigate(from, { replace: true });
    } catch (err) {
      const error = err as AxiosError;
      const status = error.status as unknown as keyof typeof EmailError;
      if (status === 403) {
        await handleResendEmail(formData.email);
        return navigate(`${Routes.check_your_email.path}?email=${formData.email}`);
      }
      setLoginError(EmailError[status]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      navigate(from, { replace: true });
    }
  }, [authToken, navigate, from]);

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[550px] min-h-screen center border-0 py-4 px-3 min450:max-w-full min450:bg-white">
        <div className="w-full max-w-[550px] flex justify-center">
          <div className="flex flex-col items-center w-full ring-1 ring-neutral-200 max-w-[500px] gap-10
           bg-white min450:ring-transparent min450:shadow-none rounded-md shadow-md h-fit p-7 min450:p-5"
          >
            <Image effect="blur" src={TeletLogo} alt="logo" width={120} height={40} />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-start w-full gap-5 h-fit"
            >
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Email
                <Input {...register('email')} hasError={!!errors.email} type="text" />
                {errors.email?.message && (
                  <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                    {errors.email?.message}
                  </p>
                )}
              </label>
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Password
                <Input {...register('password')} type="password" hasError={!!errors.password} />
                {errors.password?.message && (
                  <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                    {errors.password?.message}
                  </p>
                )}
              </label>
              <div className="relative flex flex-col w-full gap-7">
                <div className="flex items-center justify-between w-full">
                  <label className="flex cursor-pointer items-center gap-1.5 whitespace-nowrap">
                    <Input
                      type="checkbox"
                      className="cursor-pointer w-fit h-fit"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <p className="select-none text-dark-text">Remember me</p>
                  </label>

                  <a href="/forgot-password" className="font-normal text-primary">
                    Forgot Password?
                  </a>
                </div>

                <Button
                  disabled={isSubmitting || isLoading}
                  variant="default"
                  type="submit"
                  className="flex items-center gap-0.5"
                >
                  {isSubmitting && <IconLoader2 className="w-4 h-4 mr-2 animate-spin" />}
                  Sign In
                </Button>
                {loginError && (
                  <p className="absolute left-0 text-sm font-medium text-red-600 -top-3.5">
                    {loginError}
                  </p>
                )}
              </div>
            </form>
            <p className="text-sm">
              Not a member yet?
              {' '}
              <NavLink to="/sign-up" className="font-medium text-primary hover:underline">
                Create an Account.
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;

import { useNavigate } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import Cookies from 'js-cookie';
import NotFoundImg from '@/assets/illustrations/404-illustration.svg';
import TelletLogo from '@/assets/logo/tellet-logo-main.svg';
import EmpathyLogo from '@/assets/logo/empathy-logo-black.svg';
import Image from '@/components/atoms/Image';
import { Button, buttonVariants } from '@/components/ui/button';
import useErrorStore from '@/store/ErrorStore';
import SignOutUser from '@/utils/signOutUser';
import { ECompanyThemeName } from '@/enums';
import Routes from '@/router/routes';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" className="absolute w-24 h-fit top-6 left-5" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" className="absolute w-44 h-fit top-6 left-5" />,
};

function Error404Page() {
  const navigate = useNavigate();
  const setShowError = useErrorStore((state) => state.setShowError);
  const { themeName } = useTheme();
  const fallback_organization = Cookies.get('main_organization');
  const fallback_workspace = Cookies.get('fallback_workspace');

  const redirection = () => {
    if (fallback_workspace !== undefined) {
      navigate(`${Routes.organization.path}/${fallback_organization}${Routes.workspace.path}/${fallback_workspace}`);
      setShowError(false);
    } else {
      SignOutUser();
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen px-4 mx-auto text-center">
      {themeLogosList[themeName]}
      <div className="flex flex-col items-center justify-center gap-2 mx-auto text-center">
        <Image
          effect="blur"
          src={NotFoundImg}
          alt="illustration"
          className="min500:w-full w-[450px]"
        />
        <h1 className="text-5xl font-bold mt-14 text-dark min500:text-4xl">Something missing</h1>
        <p className="mt-2 text-lg font-medium text-light-dark">
          This page is unavailable or does not exist.
        </p>
        <Button
          onClick={redirection}
          className={buttonVariants({
            variant: 'default',
            className: 'mt-5 transition-all ease-in-out hover:!bg-darker-primary',
          })}
        >
          Back to home
        </Button>
      </div>
    </div>
  );
}

export default Error404Page;

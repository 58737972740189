import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import UserIconDropDown from './UserIconDropDown';
import Routes from '@/router/routes';
import axiosInstance from '@/axios/axiosInstance';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import useTranslation from '@/hooks/useTranslation';
import ProjectButtonScenarios from './ProjectButtonScenarios';
import { ECompanyThemeName, EProjectStatus } from '@/enums';
import { cn } from '@/lib/utils';
import useGetCurrentWorkspace from '@/hooks/useGetCurrentWorkspace';

interface INavProps {
  user: TUser | undefined;
  orgData: IOrganizationDataProps;
}

const projectPages = [{
  name: 'design',
  path: Routes.project_design.path,
},
{
  name: 'share',
  path: Routes.project_share.path,
},
];

function ProjectNavbar({ user, orgData }: INavProps) {
  const location = useLocation();
  const { project_id, workspace_id, organization_id } = useParams();
  const { lang } = useTranslation();
  const navigate = useNavigate();
  const { currentTheme, themeName } = useTheme();
  const { currentWorkspaceName, currentWorkspaceId, currentWorkspaceData } = useGetCurrentWorkspace();

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
    retry: 0,
  });

  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;

  return (
    <nav
      style={{ backgroundColor: currentTheme?.background }}
      className="fixed left-0 right-0 flex items-center justify-between max-w-full gap-5 px-5 py-2 transition-all ease-in border-b z-60"
    >
      <div className="flex items-center gap-2 text-[15px] w-fit whitespace-nowrap">
        <Avatar className="object-cover h-full -mr-2.5">
          {/* add img later */}
          <AvatarImage src="" className="object-cover max-w-[25px] max-h-[25px]" />
          <AvatarFallback
            style={{ backgroundColor: currentTheme?.['chat-primary'] }}
            className="h-7 w-7 font-semibold py-0.5 text-white"
          >
            {orgData?.name?.charAt(0).toUpperCase()}
          </AvatarFallback>
        </Avatar>

        {(singleProjectData && currentWorkspaceData) && (
          <div className="flex gap-1">
            <span>/</span>
            <TooltipWrapper text="Back to workspace" className="text-xs">
              <Link
                className="transition-all ease-in-out hover:underline"
                to={`${Routes.organization.path}/${organization_id}${Routes.workspace.path}/${currentWorkspaceId}`}
              >
                {currentWorkspaceName}
              </Link>
            </TooltipWrapper>
            <span>/</span>
            <p>{singleProjectData?.title}</p>
          </div>
        )}
      </div>

      <div className="flex items-center gap-10 max-w-[420px] w-full justify-center">
        {React.Children.toArray(projectPages.map((page) => (
          <Link
            style={{ color: currentTheme?.['primary-text'] }}
            className="relative font-medium transition-all ease-out"
            to={`${Routes.organization.path}/${organization_id}/workspace/${workspace_id}${page?.path}/${project_id}`}
          >
            {lang.get(`msg.${page.name}`)}
            {location?.pathname.includes(page?.path) && (
            <div
              style={{ backgroundColor: currentTheme?.primary }}
              className="h-[4px] absolute -bottom-[13px] w-full"
            >
            </div>
            )}
          </Link>
        )))}
        <TooltipWrapper
          text={
            themeName === ECompanyThemeName.EMPATHY
              ? lang.get('msg.afterTelletIsCompletedOrPublishedYouWillBeAbleToViewResultsPage_empathy')
              : lang.get('msg.afterTelletIsCompletedOrPublishedYouWillBeAbleToViewResultsPage')
          }
          className={cn((!isProjectPublished && !isProjectCompleted) ? 'flex' : 'hidden', 'relative max-w-[280px] text-center')}
        >
          <div>
            <button
              disabled={!isProjectPublished && !isProjectCompleted}
              type="button"
              style={{ color: currentTheme?.['primary-text'] }}
              className={cn(
                'font-medium relative transition-all ease-out',
                (!isProjectPublished && !isProjectCompleted) && 'cursor-not-allowed opacity-70',
              )}
              onClick={() => navigate(`${Routes.organization.path}/${organization_id}/workspace/${workspace_id}${Routes.project_results.path}/${project_id}`)}
            >
              {lang.get('msg.results')}
              {location?.pathname.includes(Routes.project_results.path) && (
                <div
                  style={{ backgroundColor: currentTheme?.primary }}
                  className="h-[4px] absolute -bottom-[13px] w-full"
                >
                </div>
              )}
            </button>
          </div>
        </TooltipWrapper>

      </div>

      <div className="flex items-center justify-end gap-4 w-fit">
        <ProjectButtonScenarios />

        {/* user profile */}
        <button type="button" aria-label="user profile">
          <UserIconDropDown user={user} />
        </button>
      </div>
    </nav>
  );
}

export default ProjectNavbar;

import { PropsWithChildren, createContext } from 'react';
import { createContextualCan } from '@casl/react';
import ability from './definedAbility';

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

export function AbilityProvider({ children }: PropsWithChildren) {
  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
}

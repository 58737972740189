import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useQueryClient } from '@tanstack/react-query';
import useAuthStore from '@/store/AuthStore';

function SignOutUser() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setAuthToken = useAuthStore((state) => state.setAuthToken);

  const handleClearCache = () => {
    queryClient.clear();
  };

  const removeAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  };

  const signOut = () => {
    setAuthToken(null);
    removeAllCookies();
    handleClearCache();
    localStorage.clear();
    navigate('/login');
  };

  return signOut;
}

export default SignOutUser;

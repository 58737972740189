import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useChatStore from 'chat-ui/src/store';
import Routes from '@/router/routes';

function ProjectDesignConversationIDWrapper({ children }: PropsWithChildren) {
  const { pathname } = useLocation();
  const { setInitialMessages } = useChatStore();

  useEffect(() => {
    if (pathname !== Routes.project_design.path) {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('current_conversation_id/test/')) {
          localStorage.removeItem(key);
          setInitialMessages([]);
        }
      });
    }
  }, [pathname]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default ProjectDesignConversationIDWrapper;

import { useParams } from 'react-router-dom';
import { IconInfoCircle, IconLoader2 } from '@tabler/icons-react';
import { useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogCancel,
} from '@/components/ui/alert-dialog';
import { Button } from '../ui/button';
import CompleteProjectTable from '../completeProjectTable/CompleteProjectTable';
import { useMutateCompleteTellet, useMutateReRunAnalyze } from '@/reactQuery/post';
import { toast } from '../ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import { ECompanyThemeName } from '@/enums';

function CompleteProjectModal() {
  const { project_id } = useParams();
  const { mutateAsync: mutateAsyncCompleteTellet } = useMutateCompleteTellet();
  const { lang } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { themeName } = useTheme();

  const { mutateAsync: mutateAsyncReRun, isPending: isPendingReRun } = useMutateReRunAnalyze();

  const onTelletComplete = async () => {
    try {
      const res = await mutateAsyncReRun({ project_id });
      if (res.status === 200) {
        await mutateAsyncCompleteTellet({ project_id });
        toast({ description: 'Tellet successfully completed!' });
        setOpenModal(!openModal);
      } else {
        toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
      }
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  return (
    <AlertDialog open={openModal}>
      <AlertDialogTrigger asChild onClick={() => setOpenModal(!openModal)}>
        <Button
          variant="default"
          type="button"
          className="h-8 px-5"
        >
          {lang.get('msg.complete')}
        </Button>
      </AlertDialogTrigger>
      <div>
        {openModal && (
          <AlertDialogContent className="max-w-2xl overflow-hidden">
            <div>
              <AlertDialogHeader>
                <AlertDialogTitle className="flex flex-wrap items-center gap-2">
                  <p>
                    {lang.get('msg.complete')}
                    {' '}
                    {themeName === ECompanyThemeName.EMPATHY ? 'project' : 'Tellet'}
                  </p>
                </AlertDialogTitle>
                <p className="text-sm text-dark-text">
                  {themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.areYouSureYouWantToCompleteThisTellet_empathy')
                    : lang.get('msg.areYouSureYouWantToCompleteThisTellet')}
                </p>
              </AlertDialogHeader>
              {/* project data */}
              <div className="flex flex-col w-full">
                <CompleteProjectTable />

                {isPendingReRun && (
                  <div className="flex gap-2 mb-8 -mt-5 text-sm">
                    <IconInfoCircle size={27} className="pt-3 text-red-600" />
                    <p>{lang.get('msg.completeTelletInfoText')}</p>
                  </div>
                )}
              </div>

              <AlertDialogFooter>
                {!isPendingReRun && <AlertDialogCancel onClick={() => setOpenModal(!openModal)}>{lang.get('msg.cancel')}</AlertDialogCancel>}

                <Button
                  variant="default"
                  disabled={isPendingReRun}
                  onClick={onTelletComplete}
                >
                  {
                    isPendingReRun ? (
                      <span className="flex items-center gap-1.5">
                        <IconLoader2 className="animate-spin" size={20} />
                        {lang.get('msg.completingAndRunningNewChanges')}
                        ...
                      </span>
                    )
                      : lang.get('msg.complete')
                  }
                </Button>
              </AlertDialogFooter>
            </div>
          </AlertDialogContent>
        )}
      </div>
    </AlertDialog>
  );
}

export default CompleteProjectModal;

import { EProjectStatus } from '@/enums';

export const projectStatusLabel: Record<EProjectStatus, string> = {
  [EProjectStatus.DRAFT]: 'Draft',
  [EProjectStatus.READY]: 'Ready',
  [EProjectStatus.WAITING_APPROVAL]: 'Waiting Approval',
  [EProjectStatus.REQUEST_APPROVED]: 'Request Approved',
  [EProjectStatus.PUBLISHED]: 'Published',
  [EProjectStatus.COMPLETED]: 'Completed',
};

export const projectStatusColors: Record<EProjectStatus, string> = {
  [EProjectStatus.DRAFT]: 'bg-gray-100 text-gray-700',
  [EProjectStatus.READY]: 'bg-blue-200 text-blue-800',
  [EProjectStatus.WAITING_APPROVAL]: 'bg-indigo-200 text-indigo-800',
  [EProjectStatus.REQUEST_APPROVED]: 'bg-purple-200 text-purple-800',
  [EProjectStatus.PUBLISHED]: 'bg-orange-200 text-orange-800',
  [EProjectStatus.COMPLETED]: 'bg-green-200 text-green-800',
};

import { IconLoader2 } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { AlertDialogAction, AlertDialogCancel, AlertDialogFooter } from '@/components/ui/alert-dialog';
import useTranslation from '@/hooks/useTranslation';
import useCategoryStore from '@/store/CategoryStore';

interface IFooterButtons {
  status: string;
  handleEditCategories: () => Promise<void>;
  openInfoScreen: boolean;
  openRemoveScreen: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCategory: React.Dispatch<React.SetStateAction<ICategoryData[]>>;
  data: ICategoryData[];
  handleRemoveScreen: () => void;
  disableSubmit: boolean;
  handleInfoScreen: () => void;
  handleDeleteCategories: () => Promise<void>;
  statusDeleteCategories: string;
}

function FooterButtonsCategory({ status, handleEditCategories,
  openInfoScreen, openRemoveScreen, setOpenModal, setCategory, data,
  handleRemoveScreen, disableSubmit, handleInfoScreen, handleDeleteCategories,
  statusDeleteCategories }: IFooterButtons) {
  const { lang } = useTranslation();
  const { currentTheme } = useTheme();
  const categoryType = useCategoryStore((state) => state.categoryType);

  return (
    <AlertDialogFooter className="items-center w-full mt-2">
      {!openInfoScreen && !openRemoveScreen && (
        <div className="flex items-center w-full gap-3">
          <AlertDialogAction
            onClick={handleRemoveScreen}
            disabled={status === 'pending'}
            className="flex items-center w-full gap-2 bg-red-500 hover:bg-red-600"
          >
            {lang.get('msg.remove')}
          </AlertDialogAction>
          <AlertDialogCancel
            disabled={status === 'pending'}
            onClick={() => [setOpenModal(false), setCategory(data || [])]}
            className="w-full"
          >
            {lang.get('msg.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            style={{ backgroundColor: currentTheme?.primary }}
            disabled={status === 'pending' || disableSubmit || !categoryType}
            onClick={handleEditCategories}
            className="flex items-center w-full gap-2"
          >
            {status === 'pending' && <IconLoader2 className="animate-spin" size={18} />}
            {lang.get('msg.save')}
          </AlertDialogAction>
        </div>
      )}
      {openInfoScreen
        && (
          <AlertDialogCancel
            onClick={handleInfoScreen}
            className="self-end"
          >
            {lang.get('msg.back')}
          </AlertDialogCancel>
        )}
      {openRemoveScreen
        && (
          <div className="flex items-center w-full gap-4 px-6">
            <AlertDialogCancel
              onClick={handleRemoveScreen}
              className="w-full"
            >
              {lang.get('msg.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteCategories}
              disabled={statusDeleteCategories === 'pending'}
              className="flex items-center w-full gap-2 bg-red-500 hover:bg-red-600"
            >
              {statusDeleteCategories === 'pending' && <IconLoader2 className="animate-spin" size={20} /> }
              {lang.get('msg.remove')}
            </AlertDialogAction>
          </div>
        )}
    </AlertDialogFooter>
  );
}

export default FooterButtonsCategory;

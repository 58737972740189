import { NavLink, useNavigate } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import Cookies from 'js-cookie';
import Image from '@/components/atoms/Image';
import NotFoundImg from '@/assets/illustrations/unauth2-illustration.svg';
import { Button, buttonVariants } from '@/components/ui/button';
import TelletLogo from '@/assets/logo/tellet-logo-main.svg';
import EmpathyLogo from '@/assets/logo/empathy-logo-black.svg';
import Routes from '@/router/routes';
import { ECompanyThemeName } from '@/enums';
import SignOutUser from '@/utils/signOutUser';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" className="absolute w-28 h-fit top-5 left-5" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" className="absolute w-28 h-fit top-5 left-5" />,
};

function Unauthorized() {
  const { themeName } = useTheme();
  const navigate = useNavigate();
  const fallback_organization = Cookies.get('main_organization');
  const fallback_workspace = Cookies.get('fallback_workspace');

  const redirection = () => {
    if (fallback_workspace && fallback_workspace !== undefined) {
      navigate(`${Routes.organization.path}/${fallback_organization}${Routes.workspace.path}/${fallback_workspace}`);
    } else {
      SignOutUser();
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen px-4 mx-auto text-center">
      <NavLink to="/">
        {themeLogosList[themeName]}
      </NavLink>
      <div className="flex flex-col items-center justify-center gap-2 mx-auto text-center">
        <Image
          effect="blur"
          src={NotFoundImg}
          alt="illustration"
          className="min500:w-full w-[450px]"
        />
        <h1 className="text-5xl font-bold mt-14 text-dark min500:text-4xl">Permission Denied</h1>
        <p className="mt-2 text-lg font-medium text-light-dark ">
          You lack the necessary permissions to view this page.
          {' '}
          <br />
          {' '}
          For access or support, please
          contact our site administrator.
        </p>
        <Button
          onClick={redirection}
          className={buttonVariants({
            variant: 'default',
            className: 'mt-5 transition-all ease-in-out hover:!bg-darker-primary',
          })}
        >
          Back to home
        </Button>
      </div>
    </div>
  );
}

export default Unauthorized;

import { Navigate, useLocation } from 'react-router-dom';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import useOrganizationRoles from '@/hooks/useOrganizationRoles';
import OrganizationalSettings from '@/pages/user-management/OrganizationalSettings';
import Routes from '@/router/routes';

function AuthOrganizationalSettings() {
  const location = useLocation();

  const { isUserAdmin, isUserOwner, isLoading } = useOrganizationRoles();

  if (isLoading) return <FullPageLoader />;

  return (isUserOwner || isUserAdmin)
    ? <OrganizationalSettings />
    : <Navigate to={Routes.unauthorized.path} state={{ from: location.pathname }} replace />;
}

export default AuthOrganizationalSettings;

import { useEffect, useRef, useState } from 'react';
import { IconSearch } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';
import { Input } from '../ui/input';
import Routes from '@/router/routes';
import useGetAllWorkspaces from '@/hooks/useGetAllWorkspaces';

interface ISearchProps {
  hideSidebar: boolean;
}

function WorkspaceSearch({ hideSidebar }: ISearchProps) {
  const [input, setInput] = useState('');
  const [resultsData, setResultsData] = useState<IWorkspace[]>([]);
  const [searchError, setSearchError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const { mergedWorkspacesList } = useGetAllWorkspaces();
  const { organization_id } = useParams();

  const fetchWorkspaces = async (value: string) => {
    try {
      const results = mergedWorkspacesList.filter((workspace) => value && workspace.name && workspace.name.toLowerCase().includes(value.toLowerCase()));
      setResultsData(results);
      setSearchError(false);
    } catch {
      setSearchError(true);
    }
  };

  const handleChange = (value: string) => {
    setInput(value);
    fetchWorkspaces(value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current
        && resultsRef.current
        && !inputRef.current.contains(event.target as Node)
        && !resultsRef.current.contains(event.target as Node)
      ) {
        setResultsData([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex flex-col">
      {/* input */}
      <div className={`flex flex-col w-full relative mt-2 ${hideSidebar && 'hidden'}`}>
        <Input
          ref={inputRef}
          placeholder="Search workspaces.."
          className="cursor-pointer pl-9 placeholder:text-light-gray"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
        <IconSearch size={17} className="absolute top-3 left-3 text-light-gray" />
        {searchError && <p className="text-xs text-red-600">Error with getting workspaces data.</p>}
      </div>
      {/* results */}
      {resultsData.length > 0 && (
      <div ref={resultsRef} className="absolute z-50 flex flex-col w-full overflow-y-auto bg-white border rounded-md shadow top-14 max-h-52">
        {resultsData.map((item) => (
          <Link
            to={`/organization/${organization_id}${Routes.workspace.path}/${item._id}`}
            key={item?._id}
            className="p-2 text-sm font-normal bg-white cursor-pointer hover:bg-neutral-100"
          >
            {item?.name}
          </Link>
        ))}
      </div>
      )}
    </div>
  );
}

export default WorkspaceSearch;

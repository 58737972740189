import {
  closestCenter,
  DndContext,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { IconCirclePlus, IconEdit, IconInfoCircleFilled, IconLoader2 } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useMutateDelteCategories, useMutateEditCategories } from '@/reactQuery/post';
import useTranslation from '@/hooks/useTranslation';
import { ECategoryMode, EStatusCode } from '@/enums';
import { Button } from '@/components/ui/button';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import CategoryEditItem from '../QuestionItem/CategoryEditItem';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import { toast } from '../ui/use-toast';
import FooterButtonsCategory from './EditOrganizationModal/FooterButtonsCategory';
import InfoScreenCategory from './EditOrganizationModal/InfoScreenCategory';
import useCategoryStore from '@/store/CategoryStore';

interface IModalProps {
  data: ICategoryData[]
  project_id: string | undefined;
  question_id: string | undefined;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isCategoryDataExists: boolean;
  conversationsNumber: number;
  isLoading: boolean;
}

const MAX_CATEGORIES = 30;

const ERRORS = {
  AT_LEAST_2_ELEMENTS: 400,
};

function EditCategoriesModal({ data, project_id, question_id, setOpenModal, openModal, isCategoryDataExists, conversationsNumber, isLoading }: IModalProps) {
  const { lang } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [category, setCategory] = useState<ICategoryData[]>(data || []);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('fid');
  const { currentTheme } = useTheme();
  const [openInfoScreen, setOpenInfoScreen] = useState(false);
  const [openRemoveScreen, setOpenRemoveScreen] = useState(false);
  const categoryType = useCategoryStore((state) => state.categoryType);
  const setCategoryType = useCategoryStore((state) => state.setCategoryType);

  const { mutateAsync, status } = useMutateEditCategories();
  const { mutateAsync: mutateAsyncDeleteCategories, status: statusDeleteCategories } = useMutateDelteCategories();

  const isCategoryModeSingle = categoryType === ECategoryMode.SINGLE;
  const isCategoryModeMultiple = categoryType === ECategoryMode.MULTIPLE;

  const handleChangeCategoryMode = (choosenCategory: ECategoryMode | null) => {
    setCategoryType(choosenCategory);
  };

  const handleInfoScreen = () => {
    setOpenInfoScreen(!openInfoScreen);
    setOpenRemoveScreen(false);
  };

  const handleRemoveScreen = () => {
    setOpenRemoveScreen(!openRemoveScreen);
    setOpenInfoScreen(false);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setCategory((items) => {
        const activeIndex = items.findIndex((q) => q?.id === active?.id);
        const overIndex = items.findIndex((q) => q?.id === over?.id);

        const reorderedItems = arrayMove(items, activeIndex, overIndex);

        return reorderedItems;
      });
    }
  };

  const scrollToBottom = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const addQuestion = () => {
    if (category?.length >= MAX_CATEGORIES) {
      toast({ description: lang.get('msg.maxNumberOfCategories') });
      return;
    }

    const newCategory = {
      id: uuidv4(),
      color: '#ECF2FF',
      label: '',
      prompt: '',
      required: false,
    };
    scrollToBottom();
    setCategory((prevCategory) => [...(prevCategory || []), newCategory]);
  };

  const handleDeleteQuestion = (idToDelete: string) => {
    setCategory((prevCategory) => prevCategory.filter((item) => item.id !== idToDelete));
  };

  const handleUpdateCategory = (id: string, field: string, value: string) => {
    setCategory((prevCategory) => prevCategory.map((item) => (item.id === id ? { ...item, [field]: value } : item)));
  };

  const handleEditCategories = async () => {
    try {
      await mutateAsync({ project_id, formData: category, categoryType, filter_id, question_id, conversationsNumber });
      setOpenModal(false);
      toast({ description: 'Categories successfully updated' });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.LABELS_MUST_BE_UNIQUE) {
        toast({ description: lang.get('msg.labelsMustBeUnique'), variant: 'destructive' });
      } else if (axiosError?.status === ERRORS.AT_LEAST_2_ELEMENTS) {
        toast({ description: lang.get('msg.categoriesMustContainAtLeastTwoCategories'), variant: 'destructive' });
      } else {
        toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
      }
    }
  };

  const handleDeleteCategories = async () => {
    try {
      await mutateAsyncDeleteCategories({ project_id, question_id, categoryType });
      toast({ description: 'Categories successfully removed' });
      setOpenModal(false);
      setOpenRemoveScreen(false);
      setOpenInfoScreen(false);
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  useEffect(() => {
    const isEmpty = category.some((item) => !item.label.trim() || !item.prompt.trim());
    setDisableSubmit(isEmpty);
  }, [category]);

  useEffect(() => {
    setCategory(data || []);
  }, [data]);

  return (
    <AlertDialog open={openModal}>
      {isCategoryDataExists && (
        <TooltipWrapper text={lang.get('msg.editCategories')} className="mb-1">
          <AlertDialogTrigger asChild>
            <Button onClick={() => setOpenModal(true)} variant="ghost" className="p-2">
              <IconEdit size={22} />
            </Button>
          </AlertDialogTrigger>
        </TooltipWrapper>
      )}
      <AlertDialogContent className="max-w-xl max-h-[98%] overflow-y-scroll overflow-x-hidden">
        {!openInfoScreen && !openRemoveScreen && (
          <div className="flex flex-col">
            <AlertDialogHeader>
              <AlertDialogTitle>{lang.get('msg.editCategories')}</AlertDialogTitle>
              <div className="flex justify-between w-full">
                <div className="flex flex-col gap-1">
                  <p className="mb-1 text-sm">Category mode:</p>
                  <div className="flex items-center gap-2">
                    <Button
                      disabled={isLoading || status === 'pending' || statusDeleteCategories === 'pending'}
                      onClick={() => handleChangeCategoryMode(ECategoryMode.SINGLE)}
                      style={{
                        backgroundColor: isCategoryModeSingle ? currentTheme?.primary : 'transparent',
                        borderColor: currentTheme?.primary,
                        color: isCategoryModeSingle ? '#FFFFFF' : currentTheme?.primary,
                      }}
                      variant="default"
                      className="px-5 font-semibold border-[3px] rounded-full h-7"
                    >
                      Single
                    </Button>
                    <Button
                      disabled={isLoading || status === 'pending' || statusDeleteCategories === 'pending'}
                      onClick={() => handleChangeCategoryMode(ECategoryMode.MULTIPLE)}
                      style={{
                        backgroundColor: isCategoryModeMultiple ? currentTheme?.primary : 'transparent',
                        borderColor: currentTheme?.primary,
                        color: isCategoryModeMultiple ? '#FFFFFF' : currentTheme?.primary,
                      }}
                      variant="default"
                      className="px-5 font-semibold border-[3px] rounded-full h-7"
                    >
                      Multiple
                    </Button>
                  </div>
                </div>
                <TooltipWrapper text={lang.get('msg.clickForMoreInfo')} className="mr-24 -mb-3">
                  <button
                    onClick={handleInfoScreen}
                    style={{ color: currentTheme?.primary }}
                    type="button"
                  >
                    <IconInfoCircleFilled size={24} />
                  </button>
                </TooltipWrapper>

              </div>
              <div className="flex flex-col items-center justify-center w-full pt-4">
                {/* categories exists and data is not loading */}
                {(category?.length !== 0) && !isLoading && !!categoryType && (
                <div ref={scrollRef} className="flex flex-col overflow-x-hidden w-full h-full max-h-[400px] pb-3 pt-1 gap-5 pr-3 overflow-y-scroll">
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={category}
                      strategy={verticalListSortingStrategy}
                    >
                      {category?.map((item, index) => (
                        <CategoryEditItem
                          key={item?.id}
                          index={index}
                          color={item?.color}
                          prompt={item?.prompt}
                          label={item?.label}
                          id={item?.id}
                          required={item?.required}
                          handleDeleteQuestion={handleDeleteQuestion}
                          handleUpdateCategory={handleUpdateCategory}
                          status={status}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                </div>
                ) }

                {/* data is loading */}
                {isLoading && (
                <div className="flex items-center justify-center w-full h-full py-10">
                  <IconLoader2
                    style={{ color: currentTheme?.primary }}
                    size={40}
                    className="animate-spin"
                  />
                </div>
                )}

                {/* data is not loading and categories does not exist */}
                {(!isLoading && category?.length === 0 && !categoryType) && <p className="py-3 text-sm">There is no categories added</p> }

                {/* if categories number is below max number then show button */}
                {category?.length < MAX_CATEGORIES && (
                  <Button
                    style={{ backgroundColor: currentTheme?.primary }}
                    onClick={addQuestion}
                    className="self-end w-full px-3 mt-8 font-semibold text-white"
                    variant="secondary"
                    disabled={status === 'pending'}
                  >
                    <IconCirclePlus size={18} className="mt-px mr-2" />
                    Add category
                  </Button>
                )}
              </div>
            </AlertDialogHeader>
          </div>
        )}

        {/* categories info screen */}
        {openInfoScreen && <InfoScreenCategory />}

        {/* remove categories screen */}
        {
          openRemoveScreen && (
            <div className="flex flex-col items-center justify-center p-5">
              <h3 className="text-lg font-semibold">Removing categories</h3>
              <p className="mt-1 font-medium">Are you sure you want to remove categories?</p>
            </div>
          )
        }

        {/* footer buttons */}
        <FooterButtonsCategory
          status={status}
          handleEditCategories={handleEditCategories}
          handleRemoveScreen={handleRemoveScreen}
          data={data}
          disableSubmit={disableSubmit}
          handleInfoScreen={handleInfoScreen}
          openInfoScreen={openInfoScreen}
          openRemoveScreen={openRemoveScreen}
          setCategory={setCategory}
          setOpenModal={setOpenModal}
          handleDeleteCategories={handleDeleteCategories}
          statusDeleteCategories={statusDeleteCategories}
        />
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default EditCategoriesModal;

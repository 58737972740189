import { Listbox, Transition } from '@headlessui/react';
import { IconCheck, IconPencil } from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { toast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import ManualCategoryEditModal from '../Modals/ManualCategoryEditModal';
import { useMutateOverrideCategories } from '@/reactQuery/post';
import useTranslation from '@/hooks/useTranslation';

interface ICategory {
  label: string;
  color: string;
  prompt: string;
  id?: string;
}

interface ICell {
  rowId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categoryData: any;
  categories: ICategory;
  questionID: string | undefined;
  conversation_id: string;
  project_id?: string;
  workspace_id?: string;
  onLoadingStart: () => void;
  onLoadingEnd: () => void;
}

function SingleCategoryCell({ rowId, categoryData, categories, questionID, conversation_id, project_id, workspace_id, onLoadingStart, onLoadingEnd }: ICell) {
  const [selectedSingleCategory, setSelectedSingleCategory] = useState<Record<string, ICategory | null>>({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const { lang } = useTranslation();

  const handleCategoryChange = ({ data }: { data: ICategory | null }) => {
    setSelectedSingleCategory((prevStates) => ({
      ...prevStates,
      [rowId]: data,
    }));
    setOpenEditModal(true);
  };

  const { mutateAsync: mutateAsyncOverrideCategories, isPending } = useMutateOverrideCategories();

  const handleManualCategoryEditSINGLE = async () => {
    onLoadingStart();
    try {
      const categoriesArray = Object.values(selectedSingleCategory)
        .filter((category) => category !== null)
        .map((category) => ({ id: category?.id }));

      const transformedData = [{
        conversation_id,
        categories: categoriesArray,
      }];

      await mutateAsyncOverrideCategories({ project_id, questionID, transformedData, workspace_id });
      toast({ description: lang.get('msg.categorySuccessfullyChanged') });
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    } finally {
      onLoadingEnd();
    }
  };

  useEffect(() => {
    if (categoryData.length > 0 && categories) {
      const matchingCategory = categoryData?.find(
        (cat: { label: string }) => cat?.label === categories?.label,
      );

      if (matchingCategory) {
        setSelectedSingleCategory((prev) => ({
          ...prev,
          [rowId]: matchingCategory,
        }));
      }
    }
  }, [categoryData, categories, rowId]);

  return (
    <div className="relative flex justify-end w-full pb-5">
      <ManualCategoryEditModal
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        handleManualCategoryEdit={handleManualCategoryEditSINGLE}
        isPending={isPending}
      />

      {/* change category */}
      <Listbox
        value={selectedSingleCategory[rowId] || null}
        onChange={(data) => {
          handleCategoryChange({ data });
        }}
      >
        <div className="relative">
          <Listbox.Button className="flex cursor-pointer">
            <span className="block">
              <IconPencil size={18} />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={cn(
              'absolute z-50 w-full min-w-[250px] left-1/2 -translate-x-1/2 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm',
            )}
            >
              {categoryData?.map((cat: { id: string; label: string }) => (
                <Listbox.Option
                  key={cat.id}
                  className={({ active }) => `relative z-50 cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                  }`}
                  value={cat}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {cat?.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <IconCheck className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default SingleCategoryCell;

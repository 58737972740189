import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconLoader2 } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import Image from '@/components/atoms/Image';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import Routes from '@/router/routes';
import { cn } from '@/lib/utils';
import { EStatusCode } from '@/enums';
import { toast } from '@/components/ui/use-toast';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

interface FormValues {
  name: string;
  last_name: string;
  phone_number: string;
  password: string;
  confirmPassword: string;
}

const defaultValues = {
  name: '',
  last_name: '',
  phone_number: '',
  password: '',
  confirmPassword: '',
};

type TSignUpForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  phone_number: Yup.string().required('Phone number is required').min(8, 'Invalid phone number').max(20, 'Invalid phone number'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('This field is required'),
});

function AcceptAccountInvitation() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const [signUpError, setSignUpError] = useState('');
  const [searchParams] = useSearchParams();
  const tokenAccept = searchParams.get('token');

  // eslint-disable-next-line consistent-return
  const onSubmit: SubmitHandler<FormValues> = async (formData: TSignUpForm) => {
    try {
      const { confirmPassword, ...allFormData } = formData;
      await axiosInstanceNoAuth.post('/users/accept_tellet_invite', allFormData, {
        headers: {
          Authorization: `Bearer ${tokenAccept}`,
        },
      });
      toast({ title: 'Login Required', description: 'Enter your credentials on the login page to access your dashboard.' });
      setSignUpError('');
      navigate(Routes.login.path);
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.status === EStatusCode.TOKEN_EXPIRED) {
        setSignUpError('');
        return navigate(`${Routes.verification_email_link_expired.path}?token=${tokenAccept}`);
      }
      if (err) {
        setSignUpError('Error. Please try again');
      }
    }
  };

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[700px] min-h-screen center border-0 px-2 py-4 min450:bg-white min450:max-w-full">
        <div className="flex flex-col items-center w-full max-w-[700px] gap-10 bg-white rounded-md h-fit p-7 min450:p-5">
          <div className="flex flex-col items-center justify-center gap-4 text-center">
            <Image effect="blur" src={TeletLogo} alt="logo" width={160} height={37} />
            <p className="text-[#5a6a85] leading-6 mt-10">
              Please enter your details to finish account creation.
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="relative flex flex-col items-start w-full gap-5 h-fit"
          >
            <div className="flex items-center w-full gap-4">
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                First name
                <Input hasError={!!errors.name} {...register('name')} type="text" />
                {errors.name?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.name?.message}
                </p>
                )}
              </label>
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Last name
                <Input hasError={!!errors.last_name} {...register('last_name')} type="text" />
                {errors.last_name?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.last_name?.message}
                </p>
                )}
              </label>
            </div>

            <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
              Phone number
              <div className="flex items-center w-full">
                <Controller
                  name="phone_number"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      className={cn(
                        'rounded-md border border-natural-700 transition-all gap-2 flex h-10 w-full shadow-sm text-gray-700 focus-within:text-gray-900 bg-gray-50 px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary disabled:pointer-events-none disabled:bg-gray-200 disabled:text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900',
                        !!errors.phone_number && 'border-red-600',
                      )}
                      placeholder="Enter phone number"
                      value={field?.value}
                      onChange={field?.onChange}
                      defaultCountry="NL"
                      international
                      countryCallingCodeEditable={false}
                    />
                  )}
                />
              </div>
              {errors.phone_number?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.phone_number?.message}
                </p>
              )}
            </label>
            <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
              Password
              <Input
                hasError={!!errors.password}
                {...register('password')}
                type="password"
              />
              {errors.password?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.password?.message}
                </p>
              )}
            </label>
            <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
              Confirm Password
              <Input
                hasError={!!errors.confirmPassword}
                {...register('confirmPassword')}
                type="password"
              />
              {errors.confirmPassword?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.confirmPassword?.message}
                </p>
              )}
            </label>
            {signUpError && (
              <p className="absolute text-sm font-medium text-red-600 left-0 bottom-[50px]">
                {signUpError}
              </p>
            )}
            <Button
              disabled={isSubmitting}
              variant="default"
              type="submit"
              className="flex items-center gap-0.5 w-full mt-4 mb-1 text-sm font-semibold text-center ease-in rounded-md"
            >
              {isSubmitting && <IconLoader2 className="w-4 h-4 mr-2 animate-spin" />}
              Sign Up
            </Button>
          </form>
        </div>
      </div>
    </main>
  );
}

export default AcceptAccountInvitation;

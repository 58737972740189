import { useContext, useState } from 'react';
import { IconLoader2, IconPlus } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button } from '../ui/button';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Input } from '../ui/input';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import { useMutateCreateWorkspace } from '@/reactQuery/post';
import { toast } from '../ui/use-toast';
import { LangContext } from '@/context/LangProvider';

const defaultValues = {
  name: '',
};

interface IformValues {
  name:string
}

interface IModalProps {
  hideSidebar:boolean;
}

type ICreationForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Workspace name is required'),
});

function CreateWorkspaceModal({ hideSidebar }: IModalProps) {
  const { lang } = useContext(LangContext);
  const [openModal, setOpenModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });

  const { mutateAsync } = useMutateCreateWorkspace();

  const onSubmit: SubmitHandler<IformValues> = async (data: ICreationForm) => {
    try {
      await mutateAsync(data);
      setOpenModal(false);
      toast({ description: `✅ ${data?.name} ${lang.get('msg.successfullyCreated')}!` });
      reset();
    } catch {
      toast({ description: `${lang.get('msg.workspaceNotCreatedTryAgain')}`, variant: 'destructive' });
    }
  };

  return (
    <>
      {/* trigger */}
      <TooltipWrapper text={lang.get('msg.createNewWorkspace')} className="absolute w-48 -left-24 top-10">
        <Button
          variant="default"
          onClick={() => setOpenModal(true)}
          className={`p-0 w-9 h-7 hover:bg-darker-primary ${hideSidebar && 'hidden'}`}
        >
          <IconPlus size={18} />
        </Button>
      </TooltipWrapper>
      {/* modal */}
      <AlertDialog open={openModal}>
        <AlertDialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogHeader>
              <AlertDialogTitle className="mb-2">{lang.get('msg.createNewWorkspace')}</AlertDialogTitle>
              <div className="flex flex-col gap-5">
                <label className="relative flex flex-col w-full gap-1">
                  <Input
                    {...register('name')}
                    placeholder={lang.get('msg.nameYourWorkspace')}
                    className={`w-full ${errors?.name?.message && 'border-red-600/50'}`}
                  />
                  <p
                    className={`text-xs md700:hidden font-medium text-red-600 opacity-0 absolute -bottom-5 ${
                      errors?.name?.message && 'opacity-100'
                    }`}
                  >
                    {errors?.name?.message}
                  </p>
                </label>
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter className="mt-6">
              <AlertDialogCancel
                onClick={() => setOpenModal(false)}
              >
                {lang.get('msg.cancel')}
              </AlertDialogCancel>
              <Button
                variant="default"
                type="submit"
                className="flex items-center gap-1"
                disabled={isSubmitting}
              >
                {isSubmitting && <IconLoader2 size={17} className="animate-spin" />}
                {lang.get('msg.createWorkspace')}
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default CreateWorkspaceModal;

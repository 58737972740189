import { Listbox, Transition } from '@headlessui/react';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { Fragment } from 'react';
import { cn } from '@/lib/utils';
import useTranslation from '@/hooks/useTranslation';

interface IListProps {
  value: string | undefined;
  onChange: (value: string) => void;
  isDisabled: boolean;
  direction?: string;
  data: { label: string; value: string }[];
  placeholder: string;
  translation: boolean;
}

function DropdownProjectListLabel({ value, onChange, isDisabled, data, direction, placeholder, translation }: IListProps) {
  const { lang } = useTranslation();
  return (
    <Listbox value={value || ''} onChange={onChange} disabled={isDisabled}>
      <div className="relative mt-1">
        <Listbox.Button className="flex relative cursor-pointer h-10 w-full items-center justify-between rounded-md border-2 shadow
        border-neutral-200 bg-gray-50 px-3 py-2 text-sm ring-offset-white placeholder:text-neutral-500
        focus:outline-none focus:ring-1 disabled:cursor-not-allowed text-black
        disabled:opacity-50 [&>span]:line-clamp-1 dark:border-neutral-800 dark:bg-neutral-950
          dark:placeholder:text-neutral-400 dark:focus:ring-neutral-300 ring-transparent appearance-none"
        >
          <span className="block truncate">{value || placeholder}</span>
          <span className="absolute right-0 flex items-center pr-2 -translate-y-1/2 pointer-events-none top-1/2">
            <IconChevronDown
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={cn(
            'absolute z-50 w-full py-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm',
            direction === 'bottom' ? 'bottom-full mb-1' : 'mt-1',
          )}
          >
            {data.map((item) => (
              <Listbox.Option
                key={item.value}
                className={({ active }) => `relative z-50 cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                }`}
                value={translation ? lang.get(`msg.${item.label}`) : item.label}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {translation ? lang.get(`msg.${item.label}`) : item.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <IconCheck className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default DropdownProjectListLabel;

import { IconLoader2 } from '@tabler/icons-react';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import useTranslation from '@/hooks/useTranslation';
import { Button } from '../ui/button';

interface IModal {
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  openEditModal: boolean;
  handleManualCategoryEdit: () => Promise<void>;
  isPending: boolean;
}

function ManualCategoryEditModal({ openEditModal, setOpenEditModal, handleManualCategoryEdit, isPending }: IModal) {
  const { lang } = useTranslation();

  return (
    <AlertDialog open={openEditModal}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {lang.get('msg.confirmManualCategoryChange')}
          </AlertDialogTitle>
          <div className="flex flex-col pt-3 pb-12">
            <p>
              {lang.get('msg.manualCategoryChageDescription')}
              <br />
              <br />
              {lang.get('msg.doYouWishToProceedWithThisChange')}
            </p>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isPending} onClick={() => setOpenEditModal(false)}>{lang.get('msg.cancel')}</AlertDialogCancel>
          <Button
            disabled={isPending}
            variant="default"
            type="submit"
            onClick={handleManualCategoryEdit}
          >
            {isPending ? <IconLoader2 className="animate-spin" size={20} />
              : lang.get('msg.yesChangeCategory')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ManualCategoryEditModal;

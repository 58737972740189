import { useContext, useEffect, useRef, useState } from 'react';
import { IconCode, IconInfoCircle, IconQrcode } from '@tabler/icons-react';
import { QRCodeCanvas } from 'qrcode.react';
import { useParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { LangContext } from '@/context/LangProvider';
import { ECompanyThemeName, EProjectStatus } from '@/enums';

interface IProjectShare {
  singleProjectData: IProjectProps;
}

function ProjectShareLink({ singleProjectData }: IProjectShare) {
  const { currentTheme, themeName } = useTheme();
  const { lang } = useContext(LangContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefSandbox = useRef<HTMLInputElement>(null);
  const { project_id } = useParams();
  const [embedDialogOpen, setEmbedDialogOpen] = useState(false);
  const [projectLink, setProjectLink] = useState('');
  const [projectSandboxLink, setProjectSandboxLink] = useState('');

  const PROJECT_STATUS = singleProjectData?.status;
  const showSandboxLink = PROJECT_STATUS !== EProjectStatus.DRAFT && PROJECT_STATUS !== EProjectStatus.COMPLETED;

  const iframeCode = `<iframe src="${projectLink}" allow="camera; microphone; autoplay;" title="Tellet" width="400px" height="800px" frameBorder="0"></iframe>`;

  const handleCopyClick = async () => {
    if (inputRef.current) {
      try {
        await navigator.clipboard.writeText(inputRef.current.value);
        toast({ description: `✅ ${lang.get('msg.linkCopied')}!` });
      } catch (err) {
        toast({ title: `${lang.get('msg.linkNotCopied')}`, variant: 'destructive' });
      }
    }
  };

  const handleCopyClickSandbox = async () => {
    if (inputRefSandbox.current) {
      try {
        await navigator.clipboard.writeText(inputRefSandbox.current.value);
        toast({ description: `✅ ${lang.get('msg.linkCopied')}!` });
      } catch (err) {
        toast({ title: `${lang.get('msg.linkNotCopied')}`, variant: 'destructive' });
      }
    }
  };

  const copyCode = (link: string) => {
    try {
      navigator.clipboard.writeText(link);
      toast({ description: `✅ ${lang.get('msg.codeCopied')}!` });
      setEmbedDialogOpen(false);
    } catch (err) {
      toast({ title: `${lang.get('msg.codeNotCopied')}`, variant: 'destructive' });
    }
  };

  const downloadQR = (el: string) => {
    const canvas = document.getElementById(`${el}`) as HTMLCanvasElement | null;

    if (!canvas) {
      toast({
        title: 'Error. Please try again later.',
        variant: 'destructive',
      });
      return;
    }

    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');

    downloadLink.href = pngUrl;
    downloadLink.download = 'telletQRCode.png';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    const PROJECT_LINK = `${import.meta.env.VITE_ENVIRONMENT_CHAT}/${project_id}`;
    const PROJECT_LINK_EMPATHY = `${import.meta.env.VITE_ENVIRONMENT_CHAT_EMPATHY}/${project_id}`;

    const SANDBOX_PROJECT_LINK = `${import.meta.env.VITE_ENVIRONMENT_CHAT}/${project_id}?environment=sandbox`;
    const SANDBOX_PROJECT_LINK_EMPATHY = `${import.meta.env.VITE_ENVIRONMENT_CHAT_EMPATHY}/${project_id}?environment=sandbox`;

    const link = themeName === ECompanyThemeName.EMPATHY ? PROJECT_LINK_EMPATHY : PROJECT_LINK;
    const linkSandbox = themeName === ECompanyThemeName.EMPATHY ? SANDBOX_PROJECT_LINK_EMPATHY : SANDBOX_PROJECT_LINK;

    setProjectSandboxLink(linkSandbox);
    setProjectLink(link);
  }, [themeName, project_id]);

  return (
    <div className="flex flex-col w-full gap-5">
      {/* REAL CHAT */}
      <div className="flex flex-col gap-2">
        <p className="text-base font-medium">
          Link
        </p>
        <div
          style={{ backgroundColor: currentTheme?.['secondary-background'] }}
          className="flex flex-col items-center justify-center w-full gap-6 p-4 rounded-md"
        >
          <div className="flex w-full">
            <Input
              type="text"
              ref={inputRef}
              readOnly
              defaultValue={projectLink}
              className="rounded-tr-none rounded-br-none"
            />
            <Button
              variant="default"
              onClick={handleCopyClick}
              className="border-none rounded-tl-none rounded-bl-none"
            >
              Copy link
            </Button>
          </div>
          <div className="flex justify-center w-full gap-2">
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  variant="default"
                  className="flex items-center gap-1 px-6"
                  type="button"
                >
                  <IconQrcode size={20} />
                  {lang.get('msg.getQRCode')}
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle className="mb-1">{lang.get('msg.getQRCode')}</DialogTitle>
                  <div className="text-dark-text">
                    <p className="text-sm">
                      {themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.scanQRCodeToGetTellet_empathy')
                        : lang.get('msg.scanQRCodeToGetTellet')}
                    </p>
                    <div className="flex items-center justify-center w-full my-4">
                      <QRCodeCanvas
                        id="qrElement"
                        value={projectLink}
                        size={280}
                        bgColor="#ffffff"
                        fgColor={currentTheme?.primary}
                        level="M"
                        includeMargin
                      />
                    </div>
                  </div>
                  <Button
                    variant="default"
                    onClick={() => downloadQR('qrElement')}
                  >
                    {lang.get('msg.downloadQRCode')}
                  </Button>
                </DialogHeader>
              </DialogContent>
            </Dialog>
            <Dialog open={embedDialogOpen} onOpenChange={(isOpen) => setEmbedDialogOpen(isOpen)}>
              <DialogTrigger asChild>
                <Button
                  variant="default"
                  className="flex items-center gap-1 px-6"
                  type="button"
                >
                  <IconCode size={20} />
                  {lang.get('msg.embed')}
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-xl bg-gray-100">
                <DialogHeader>
                  <DialogTitle className="mb-1">{lang.get('msg.getIframeCode')}</DialogTitle>
                  <div className="py-4 text-dark-text center">
                    <code className="px-4 py-10 bg-white rounded-xl">{iframeCode}</code>
                  </div>
                  <Button
                    variant="default"
                    onClick={() => copyCode(iframeCode)}
                  >
                    {lang.get('msg.copyCode')}
                  </Button>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>

      {/* INFO MESSAGE */}
      {!showSandboxLink && PROJECT_STATUS !== EProjectStatus.COMPLETED && (
        <div className="flex flex-col w-full gap-2">
          <p className="text-sm flex items-center gap-1.5 font-medium">
            <IconInfoCircle size={17} style={{ color: currentTheme?.primary }} />
            Test link will be generated once all of the required project fields are fulfilled.
          </p>
        </div>
      )}

      {/* SANDBOX TEST CHAT */}
      {showSandboxLink && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-col w-full gap-1">
            <p className="text-base font-medium">
              Test link
            </p>
            <p
              style={{ color: currentTheme?.['primary-text'] }}
              className="text-sm flex items-center gap-1.5 font-medium"
            >
              <IconInfoCircle
                size={17}
                style={{ color: currentTheme?.primary }}
              />
              This test link is solely for testing purposes. Data from test conversations will be excluded from any analysis.
            </p>
          </div>

          <div
            style={{ backgroundColor: currentTheme?.['secondary-background'] }}
            className="flex flex-col items-center justify-center w-full gap-6 p-4 rounded-md"
          >
            <div className="flex w-full">
              <Input
                type="text"
                ref={inputRefSandbox}
                readOnly
                defaultValue={projectSandboxLink}
                className="rounded-tr-none rounded-br-none"
              />
              <Button
                variant="default"
                onClick={handleCopyClickSandbox}
                className="border-none rounded-tl-none rounded-bl-none"
              >
                Copy test link
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectShareLink;

import { create } from 'zustand';

type State = {
  showError: boolean;
};

type Action = {
  setShowError: (value: boolean) => void;
};

const useErrorStore = create<State & Action>((set) => ({
  showError: false,
  setShowError: (value: boolean) => set({ showError: value }),
}));

export default useErrorStore;

import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconLoader2 } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import ToolTip from '@/components/tooltip/ToolTip';
import { LangContext } from '@/context/LangProvider';
import { useMutateProjectUpdate } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import { EProjectStatus, EStatusCode } from '@/enums';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import Switch from '@/components/switch/Switch';
import { Button } from '@/components/ui/button';

interface IPanel {
  is_integrated: boolean;
  provider_name?: string;
  confirmation_link: string;
}

interface IFormValues {
  panel: IPanel;
}

const validationSchema = Yup.object().shape({
  panel: Yup.object().shape({
    confirmation_link: Yup.string().required(),
    is_integrated: Yup.boolean().required(),
    provider_name: Yup.string(),
  }),
});

interface IProjectPanel {
  singleProjectData: IProjectProps;
}

function ProjectSharePanel({ singleProjectData }: IProjectPanel) {
  const { lang } = useContext(LangContext);
  const { project_id, workspace_id } = useParams();
  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const [isIntegrated, setIsIntegrated] = useState(singleProjectData?.panel?.is_integrated ?? false);
  const [formChanged, setFormChanged] = useState(false);
  const { currentTheme } = useTheme();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<IFormValues>({ defaultValues: {
    panel: {
      confirmation_link: singleProjectData?.panel?.confirmation_link || '',
      provider_name: singleProjectData?.panel?.provider_name || '',
      is_integrated: singleProjectData?.panel?.is_integrated || false,
    },
  },
  resolver: yupResolver(validationSchema) });
  const { mutateAsync } = useMutateProjectUpdate();

  const onSubmit: SubmitHandler<IFormValues> = async (data: IFormValues) => {
    try {
      const formDataWithoutIsIntegrated = {
        _id: project_id,
        panel: {
          confirmation_link: data.panel.confirmation_link,
          provider_name: data.panel.provider_name,
          is_integrated: true,
        },
      };
      await mutateAsync(formDataWithoutIsIntegrated);
      setFormChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  // edge case for required strings
  const handleToggle = async () => {
    try {
      const formDataWithoutIsIntegrated = {
        _id: project_id,
        panel: {
          is_integrated: !isIntegrated,
          confirmation_link: '',
          provider_name: '',
        },
      };

      await mutateAsync(formDataWithoutIsIntegrated);
      reset({
        panel: {
          confirmation_link: '',
          provider_name: '',
          is_integrated: !isIntegrated,
        },
      });
      setFormChanged(false);
      setIsIntegrated(!isIntegrated);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const handleFormChanged = () => {
    setFormChanged(true);
  };

  return (
    <div className="w-full h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-6 pb-10">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 w-fit">
              <ToolTip text="Select panel integration" className="ml-5" />
              <p className="flex items-center gap-1 mr-2 text-base font-medium">
                {lang.get('msg.panelIntegration')}
              </p>
              {/* toggle */}
              <Switch func={handleToggle} value={isIntegrated} isDisabled={isUserViewer || isProjectPublished || isProjectCompleted} />
            </div>
          </div>
        </div>

        {isIntegrated
         && (
         <div className="flex flex-col w-full gap-3">
           <div
             style={{ backgroundColor: currentTheme?.['secondary-background'] }}
             className="flex flex-col w-full gap-1 p-4 rounded-md"
           >
             <div
               style={{ color: currentTheme?.['primary-text'] }}
               className="flex flex-col w-full gap-4 text-dark-text"
             >
               <label className="flex flex-col gap-1">
                 <p
                   className="text-sm font-medium"
                 >
                   Confirmation link
                   <span className="ml-0.5 text-red-600">*</span>
                 </p>
                 <Input
                   hasError={!!errors?.panel?.confirmation_link?.message}
                   placeholder={lang.get('msg.confirmationLink')}
                   {...register('panel.confirmation_link')}
                   onChange={handleFormChanged}
                   disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                 />
                 <p className={cn(errors?.panel?.confirmation_link?.message ? 'flex' : 'hidden', 'text-red-600 text-xs font-semibold')}>
                   Confirmation link is required field
                 </p>
               </label>
               <label className="flex flex-col gap-1">
                 <p className="text-sm font-medium">Provider name</p>
                 <Input
                   hasError={!!errors?.panel?.provider_name?.message}
                   placeholder={lang.get('msg.providerName')}
                   {...register('panel.provider_name')}
                   onChange={handleFormChanged}
                   disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                 />
               </label>
             </div>
             <p className={cn(errors?.panel?.provider_name?.message ? 'flex' : 'hidden', 'text-red-600 text-xs font-semibold')}>
               Provider name is required field
             </p>
           </div>
           <Button
             variant="default"
             type="submit"
             disabled={isSubmitting || !formChanged || isUserViewer || isProjectPublished || isProjectCompleted}
           >
             {isSubmitting && <IconLoader2 size={18} className="mr-2 animate-spin" />}
             {formChanged ? lang.get('msg.save') : lang.get('msg.allSaved')}
           </Button>
         </div>
         )}
      </form>
    </div>
  );
}

export default ProjectSharePanel;

import { useContext, useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { Control, FieldValues, UseFormRegister, UseFormWatch, useFieldArray } from 'react-hook-form';
import { LangContext } from '@/context/LangProvider';
// import ToolTip from '@/components/tooltip/ToolTip';
import { Textarea } from '@/components/ui/textarea';
import RequiredLabel from '@/components/labels/RequiredLabel';

interface ISpippetsProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  isUserViewer: boolean;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
  handleFormChanged: () => void;
}

function Snippets({ control, watch, register, isUserViewer, isProjectPublished, isProjectCompleted, handleFormChanged }: ISpippetsProps) {
  const { lang } = useContext(LangContext);
  const [snippetsCharacters, setSnippetsCharacters] = useState(['']);

  const { fields, append, remove } = useFieldArray({
    name: 'snippets',
    control,
  });

  const watchFieldArray = watch('snippets');
  const controlledFields = watchFieldArray ? fields.map((field, index) => ({
    ...field,
    index,
  })) : [];

  const handleRemove = (index: number) => {
    const updatedSnippetsCharacters = [...snippetsCharacters];
    updatedSnippetsCharacters.splice(index, 1);
    setSnippetsCharacters(updatedSnippetsCharacters);
    remove(index);
  };

  useEffect(() => {
    if (watchFieldArray) {
      setSnippetsCharacters(watchFieldArray?.map((snippet: string) => snippet || ''));
    }

    if (!watchFieldArray?.length) {
      append('');
    }
  }, [watch, append, watchFieldArray]);

  return (
    <label className="flex flex-col gap-2">
      <div className="flex flex-col gap-4 mt-2">
        {controlledFields.map((field, index) => (
          <div className="flex flex-col justify-between w-full relative gap-1.5" key={field.id}>
            {/* remove btn */}
            {index > 0 && (
            <button
              onClick={() => handleRemove(index)}
              className="absolute right-0 p-0.5 text-white bg-red-700 rounded-full -top-5"
              type="button"
            >
              <IconX size={10} />
            </button>
            )}
            {/* content */}
            <RequiredLabel notRequired title="context" tooltip="It's important to provide Tellet's AI with useful contextual information that will make the interviews a success. Are their specific spellings of names or brands it should know? Or perhaps places, people, items or concepts that it should be aware of? What information would you provide to a human interviewer before it spoke with your customers?">
              <Textarea
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register(`snippets.${index}`)}
                value={snippetsCharacters[index]}
                maxLength={1400}
                onChange={(e) => {
                  const updatedCharacters = [...snippetsCharacters];
                  updatedCharacters[index] = e.target.value;
                  setSnippetsCharacters(updatedCharacters);
                  handleFormChanged();
                }}
                className="h-24 rounded-xl"
                placeholder={lang.get('msg.contextPlaceholder')}
              />
            </RequiredLabel>
            {/* counter */}
            <p className="text-xs text-light-dark">
              {1400 - (snippetsCharacters[index]
               && snippetsCharacters[index].length ? snippetsCharacters[index].length : 0)}
              {' '}
              {lang.get('msg.charactersLeft')}
            </p>
          </div>
        ))}
      </div>
      {/* add more btn */}
      {/* -- COMING SOON -- */}
      {/* {!isUserViewer
      && (
      <button
        disabled
        type="button"
        onClick={() => append('')}
        className="flex items-center self-end gap-1 -mt-1 font-semibold ease-in-out opacity-60 transiti on-all hover:text-primary"
      >
        <IconPlus size={15} />
        Add more context
      </button>
      )} */}
    </label>
  );
}

export default Snippets;

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Lang from 'lang.js';
import langMessages from 'src/lang/langMessages';
import { useGetUserData } from '@/reactQuery/get';

interface ILangContext {
  lang: Lang;
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
}

export const LangContext = createContext<ILangContext>({
  lang: new Lang({}),
  selectedLanguage: 'en',
  setSelectedLanguage: () => {},
});

export function LangProvider({ children }: { children: ReactNode }) {
  const { data: user } = useGetUserData();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    () => user?.language || localStorage.getItem('selectedLanguage') || 'en',
  );

  useEffect(() => {
    if (user && user?.language) {
      setSelectedLanguage(user?.language);
    }
  }, [user]);

  useEffect(() => {
    // Save selectedLanguage to localStorage
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lang = new Lang({
    messages: langMessages,
  });

  lang.setLocale(selectedLanguage);

  const memoValues = useMemo(() => ({
    lang,
    selectedLanguage,
    setSelectedLanguage,
  }), [lang, selectedLanguage, setSelectedLanguage]);

  return <LangContext.Provider value={memoValues}>{children}</LangContext.Provider>;
}

import { IconFileInvoice, IconPlus, IconWand, IconX } from '@tabler/icons-react';
import React from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { cn } from '@/lib/utils';
import useTranslation from '@/hooks/useTranslation';
import { ECompanyThemeName } from '@/enums';

interface IButtons {
  icon: JSX.Element;
  title: string;
  description: string;
  icon_color: string;
  bg_color: string;
  type: string;
  coming_soon: boolean;
}

interface ICreationTypeProps {
  setCreationType: React.Dispatch<React.SetStateAction<string>>
  closeModalAndRemoveData: () => void;
}

function ChooseProjectCreationType(
  { closeModalAndRemoveData, setCreationType }: ICreationTypeProps,
) {
  const { lang } = useTranslation();
  const { themeName } = useTheme();

  const createTelletChoices: IButtons[] = [
    {
      icon: <IconPlus size={55} />,
      title: lang.get('msg.startFromScratch'),
      description: lang.get('msg.jumpRightInAndBuildSomethingBeautiful'),
      icon_color: 'text-blue-800',
      bg_color: 'bg-blue-200',
      type: 'scratch',
      coming_soon: false,
    },
    {
      icon: <IconFileInvoice size={55} />,
      title: lang.get('msg.fromTemplate'),
      description: themeName === ECompanyThemeName.EMPATHY
        ? lang.get('msg.convertAnExistingTelletTemplate_empathy') : lang.get('msg.convertAnExistingTelletTemplate'),
      icon_color: 'text-orange-800',
      bg_color: 'bg-orange-200',
      type: 'template',
      coming_soon: true,
    },
    {
      icon: <IconWand size={55} />,
      title: lang.get('msg.createWithAI'),
      description: lang.get('msg.coCreateYourFirstDraftWithAI'),
      icon_color: 'text-purple-800',
      bg_color: 'bg-purple-200',
      type: 'ai',
      coming_soon: true,
    },
  ];

  return (
    <div className="relative flex flex-col items-center justify-center w-full h-full gap-10 overflow-hidden overflow-y-auto text-center">
      {/* close modal */}
      <button className="absolute top-2 right-2" type="button" onClick={closeModalAndRemoveData}><IconX size={24} /></button>
      {/* modal content */}
      <div className="flex flex-col items-center justify-center gap-2">
        <IconPlus size={50} />
        <h3 className="text-xl font-bold">
          {themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.createNewTellet_empathy')
            : lang.get('msg.createNewTellet')}
        </h3>
        <p className="mt-4">
          {themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.createYourTelletFromScratchOrUseAiAssistent_empathy')
            : lang.get('msg.createYourTelletFromScratchOrUseAiAssistent')}
        </p>
      </div>
      {/* pick creation choice */}
      <div className="flex flex-wrap justify-center gap-5">
        {React.Children.toArray(createTelletChoices.map((button) => (
          <div className="relative rounded-md">
            {/* coming soon overlay */}
            <div className={cn(
              'absolute top-0 bottom-0 select-none cursor-pointer rounded-md left-0 items-center justify-center right-0 z-50 w-full h-full bg-slate-500/60',
              button?.coming_soon ? 'flex' : 'hidden',
            )}
            >
              <p className="text-2xl font-bold text-white rotate-[45deg] opacity-60">
                {lang.get('msg.comingSoon')}
                ...
              </p>
            </div>

            <button
              disabled={button?.coming_soon}
              onClick={() => setCreationType(button.type)}
              className="w-[260px] h-[260px] border shadow border-dark-text/30 hover:border-dark-text/50
               rounded-md hover:shadow-md transition-all ease-in-out hover:scale-[1.02] flex flex-col items-center justify-center text-center"
              type="button"
            >
              <div className={`p-1 ${button.bg_color} ${button.icon_color} mb-7 rounded-xl flex items-center justify-center w-[65px] h-[65px]`}>
                {button.icon}
              </div>
              <p className="mb-3 text-lg font-semibold">{button.title}</p>
              <p className="px-8 text-sm text-light-gray">
                {button.description}
              </p>
            </button>
          </div>
        )))}
      </div>
    </div>
  );
}

export default ChooseProjectCreationType;

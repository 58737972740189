/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconDownload, IconUserFilled, IconMessages, IconLoader2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import dayjs from 'dayjs';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import axiosInstance from '@/axios/axiosInstance';
import { cn } from '@/lib/utils';
import { getInitials } from '@/utils/stringHelpers';
import useTranslation from '@/hooks/useTranslation';
import ConversationMessage from '@/components/chat/ConversationMessage';
import { useMutateDownloadAllConversationsAsCSV, useMutateDownloadConversationAsCSV } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import useResultTabStore from '@/store/ResultsTabStore';

function TranscriptsConversations() {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const currentChatID = searchParams.get('for');
  const { lang } = useTranslation();
  const filter_id = searchParams.get('fid');
  const question_id = searchParams.get('qid');
  const search_page = searchParams.get('qpage');
  const navigate = useNavigate();
  const [filterById, setFilderById] = useState('');
  const { currentTheme } = useTheme();
  const activeTab = useResultTabStore((state) => state.activeTab);
  const location = useLocation();

  const { mutateAsync: mutateAsyncDownloadAsCSV } = useMutateDownloadConversationAsCSV();
  const { mutateAsync: mutateAsyncDownloadAllAsCSV } = useMutateDownloadAllConversationsAsCSV();

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data'],
    select: (data) => data?.data,
  });

  const { data: allConversations, isLoading: isAllConversationsLoading, isFetching } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/get_conversations${filter_id ? `?filter_id=${filter_id}` : ''}
    `),
    queryKey: ['single_conversations_data', { filter_id }],
    select: (data) => data?.data,
    enabled: activeTab === 'transcripts_tab',
  });

  const chatDataByID = allConversations?.filter((item: { _id: string }) => item?._id === currentChatID);

  const downloadCSV = ({ conversation_data, id }: { conversation_data: any, id: string }) => {
    const csvContent = conversation_data.map((row: any) => row.join('\t')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `tellet_conversation_${id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsCSVAllConversations = ({ conversation_data }: any) => {
    let csvContent = '';

    csvContent += `${conversation_data[0].join(',')}\n`;

    conversation_data.slice(1).forEach((conversation: any) => {
      conversation.forEach((row: any[]) => {
        csvContent += `${row.join(',')}\n`;
      });
    });

    // Create a blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link = document.createElement('a');

    // Create a URL for the blob and set it as the href attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);

    // Set the download attribute with a file name
    link.setAttribute('download', 'conversations.csv');

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download by programmatically clicking the link
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downlaodConversationAsCSV = async () => {
    try {
      const result = await mutateAsyncDownloadAsCSV({ conversation_id: currentChatID });
      const conversationData = result.data;
      downloadCSV({ conversation_data: conversationData, id: currentChatID as string });
      toast({ description: 'Conversation successfully downloaded.' });
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const downlaodAllConversationsAsCSV = async () => {
    try {
      const result = await mutateAsyncDownloadAllAsCSV({ project_id });
      const conversationData = result.data;
      downloadAsCSVAllConversations({ conversation_data: conversationData });
      toast({ description: 'All conversations successfully downloaded.' });
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const setParams = (conversation_id: string) => {
    const params: { [key: string]: string | null } = {
      fid: filter_id,
      qid: question_id,
      qpage: search_page,
      for: conversation_id,
    };

    // Filter out null values
    const filteredParams = Object.entries(params)
      .filter(([, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `?${filteredParams}`;
  };

  const filteredConversations = allConversations?.filter(
    (item: { _id: string }) => item?._id.toLowerCase().includes(filterById?.toLowerCase()),
  );

  const openFirstConversation = () => {
    const firstConversation = allConversations?.[0]?._id ?? '';
    if (currentChatID) return null;
    if (location?.search !== '') {
      return navigate(`${location?.pathname}${location?.search}&for=${firstConversation}`);
    }
    return navigate(`${location?.pathname}?for=${firstConversation}`);
  };

  useEffect(() => {
    if (!isAllConversationsLoading && !isFetching) openFirstConversation();
  }, [activeTab, isAllConversationsLoading, isFetching]);

  return (
    <div className="flex lg1000:flex-col max-w-[1300px] mx-auto w-full gap-4 h-[650px] lg1000:h-fit relative">
      {/* sidebar with all users */}
      <aside className="flex flex-col text-dark-text lg1000:w-full lg1000:h-[400px] shadow-sm border-dark-text/10 border bg-secondary-background p-2 w-[500px] max1200:w-[300px] rounded-tl-lg rounded-bl-lg">
        {/* header */}
        <div className="flex items-center w-full h-20 gap-3 px-4 mb-1 bg-white border rounded-md">
          <Avatar className="w-12 h-12 border rounded-full shadow-md">
            <AvatarFallback
              style={{ backgroundColor: currentTheme?.primary }}
              className="text-lg font-semibold text-white"
            >
              {getInitials(singleProjectData?.title)}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p className="text-base font-bold">{singleProjectData?.company_name}</p>
            <p className="text-sm font-medium">
              {allConversations?.length}
              {' '}
              {lang.get('msg.totalConversations')}
            </p>
          </div>
        </div>

        <div className="flex flex-col w-full gap-1 mb-2">
          <Button
            variant="default"
            onClick={downlaodAllConversationsAsCSV}
            className="flex items-center gap-1"
          >
            <IconDownload size={18} />
            Export all
          </Button>
          <Input
            placeholder="Search by ID"
            className="bg-white"
            onChange={(e) => setFilderById(e.target.value)}
          />
        </div>

        {/* convos */}
        <div className="flex flex-col w-full gap-0.5 h-full overflow-hidden overflow-y-auto">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {!isAllConversationsLoading
            ? filteredConversations?.map((item: { _id: string, messages: any, status: string }) => (
              <button
                type="button"
                onClick={() => navigate(setParams(item?._id))}
                key={item?._id}
                className={cn(
                  'flex text-start items-center w-full gap-3 p-3 transition-all ease-in-out border-2 border-transparent hover:border-primary/30 rounded-md cursor-pointer hover:bg-white/40 hover:shadow/40',
                  currentChatID === item?._id && '!bg-white !shadow !border-primary/40',
                )}
              >
                <div className="relative">
                  <div className={cn('w-3 h-3 rounded-full absolute top-0 right-0 z-10', item?.status === 'digested' ? 'bg-green-400' : 'bg-orange-400')}></div>
                  <Avatar className="relative w-10 h-10 border rounded-full shadow-md">
                    <AvatarFallback>
                      <IconUserFilled
                        style={{ color: currentTheme?.primary }}
                        size={22}
                      />
                    </AvatarFallback>
                  </Avatar>
                </div>
                <div className="flex flex-col text-sm">
                  <p className="font-bold max-w-[220px] max1200:max-w-[160px] truncate">
                    {item?._id}
                  </p>
                  <p className="max-w-[220px] truncate max1200:max-w-[160px]">
                    {item?.messages?.[item.messages.length - 1]?.text}
                  </p>
                </div>
              </button>
            )) : (
              <div className="flex flex-col w-full gap-1.5">
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <Skeleton key={item} className="w-full rounded-md h-[67.5px]" />
                ))}
              </div>
            )}
        </div>
      </aside>
      {/* user conversation box */}
      <div className="relative flex w-full overflow-hidden border lg1000:h-[600px] lg1000:min-h-full rounded-tr-lg rounded-br-lg shadow-sm border-dark-text/10">
        {/* chat */}
        <div className="flex flex-col w-full h-full p-3 bg-secondary-background">
          {/* chat header */}
          <div className="flex items-center justify-between gap-2 px-6 py-3 bg-white border-2 rounded-full shadow-sm md700:rounded-md md700:flex-col border-dark-text/10">
            <div className="flex items-center gap-2">
              <div className="flex flex-wrap gap-3 text-sm gap-y-1 text-dark-text">
                {/* conv id */}
                <p className="font-medium">
                  {lang.get('msg.conversation')}
                  :
                  {' '}
                  <span className="font-bold">
                    {chatDataByID?.[0]?._id}
                  </span>
                </p>
                {/* other data */}
                {chatDataByID?.[0]?._id && Object?.entries(chatDataByID?.[0]?.metadata)?.map(([key, value]) => (
                  <div className="flex font-medium whitespace-nowrap" key={key}>
                    <p className="capitalize">{key}</p>
                    :
                    <p className="ml-1 font-bold">
                      {String(value)}
                    </p>
                  </div>
                ))}
                <div className="flex font-medium whitespace-nowrap">
                  <p className="capitalize">Start date</p>
                  :
                  <p className="ml-1 font-bold">
                    {dayjs(chatDataByID?.[0]?.createdAt).format('DD/MMM/YYYY - hh:mm A')}
                  </p>
                </div>
              </div>
            </div>
            {/* buttons */}
            <div className="flex items-center gap-3">
              {/* export */}
              <DropdownMenu>
                <DropdownMenuTrigger
                  style={{ backgroundColor: currentTheme?.primary }}
                  className="flex gap-1.5 font-medium hover:shadow items-center p-2 text-sm text-white rounded-md outline-none ring-0 ring-transparent"
                >
                  <IconDownload size={20} />
                  Export
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-1 min-w-[15rem] mr-[70px]">
                  <DropdownMenuLabel>Export conversation</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={downlaodConversationAsCSV} className="cursor-pointer">Export as CSV</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

          </div>
          {/* chat boxes */}
          <div className="relative flex flex-col w-full h-full px-2 overflow-hidden overflow-y-auto">
            <div className="flex flex-col w-full gap-4 pb-2 mt-8">
              {!currentChatID && !isAllConversationsLoading && !isFetching && (
                <div className="absolute flex flex-col items-center justify-center w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <IconMessages size={120} />
                  <p className="mt-1.5 text-3xl font-bold">
                    Select conversation
                  </p>
                </div>
              )}
              {(isAllConversationsLoading || isFetching) && !allConversations && (
                <div className="absolute flex flex-col items-center justify-center w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <IconLoader2 size={30} className="animate-spin" />
                </div>
              )}
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {chatDataByID?.[0]?.messages?.map((item: { _id: string, role: string, text: string, data: any, createdAt: string; }) => (
                <ConversationMessage key={item?._id} data={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TranscriptsConversations;

import { cn } from '@/lib/utils';
import VideoPlayer from './VideoJsPlayer';

function VideoMessage({ url }: TMessageAtachment) {
  const videoJsOptions = {
    sources: [
      {
        src: url,
        type: 'video/mp4',
      },
    ],
  };

  return (
    <div className={cn('flex items-center max-w-full w-[40rem]')}>
      <VideoPlayer options={videoJsOptions} />
    </div>
  );
}

export default VideoMessage;

import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Routes from './router/routes';

export default function App() {
  const navigate = useNavigate();
  const token = Cookies.get('token');
  const main_organization = Cookies.get('main_organization');
  const main_workspace = Cookies.get('fallback_workspace');

  useEffect(() => {
    if (token) {
      navigate(`${Routes.organization.path}/${main_organization}${Routes.workspace.path}/${main_workspace}`);
    } else {
      navigate(Routes.login.path);
    }
  }, [navigate, token, main_organization, main_workspace]);

  return null;
}

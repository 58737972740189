import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import axiosInstance from '@/axios/axiosInstance';

const useGetAllWorkspaces = () => {
  const { organization_id } = useParams();
  // list of all private and public workspaces
  const { data: allWorkspacesData, isLoading: isWorkspaceLoading } = useQuery<IWorkspaceAll>({
    queryFn: () => axiosInstance.get(`workspaces/get_all/${organization_id}`),
    queryKey: ['get_all_workspaces', { organization_id }],
    enabled: !!organization_id,
  });

  // list of private and public workspaces merged to the one array
  const mergedWorkspacesList = [
    ...(allWorkspacesData?.data?.priv || []),
    ...(allWorkspacesData?.data?.shared || []),
  ];

  // first workspace of merged list
  const firstWorkspaceInList = mergedWorkspacesList[0]?._id;
  const organizationIDFromWorkspace = mergedWorkspacesList[0]?.organization_id;

  return { allWorkspacesData,
    mergedWorkspacesList,
    isWorkspaceLoading,
    firstWorkspaceInList,
    organizationIDFromWorkspace };
};

export default useGetAllWorkspaces;

import { Skeleton } from '@/components/ui/skeleton';

function DashboardTestChatSkeletonLoading() {
  return (
    <div className="flex flex-col w-full h-[95%] mt-3 gap-3">
      <Skeleton className="w-full h-[95%] rounded-md" />
      <div className="flex w-full gap-3">
        <Skeleton className="w-full rounded-md h-[56px]" />
        <div className="flex gap-2">
          <Skeleton className="rounded-md w-[56px] h-[56px]" />
          <Skeleton className="rounded-md w-[56px] h-[56px]" />
        </div>
      </div>
    </div>
  );
}

export default DashboardTestChatSkeletonLoading;

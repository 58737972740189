import { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import {
  Card,
  CardContent,
} from '@/components/ui/card';
import { Skeleton } from '../ui/skeleton';
import { translateQuote } from '@/pages/projects/single-project/Content/project_results/resultsPageTranslations';

interface IData {
  quote: string;
  id: string;
}

interface IQuotes {
  data: IData[];
  isLoading?: boolean;
  analysisLanguage: string;
}

function InterestingQuotes({ data, isLoading, analysisLanguage }: IQuotes) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    !isLoading ? (
      <Carousel setApi={setApi} className="flex flex-col items-center justify-center w-full max-w-5xl mb-6">
        <CarouselContent>
          {data?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <CarouselItem key={index}>
              <div className="p-1">
                <Card className="!border-none shadow-none cursor-grab">
                  <CardContent className="flex flex-col items-center justify-center w-full py-6">
                    <div className="flex items-center justify-center w-full mx-auto text-base font-semibold text-center">
                      <p className="md900:max-w-2xl md600:max-w-lg md700:max-w-xl min500:max-w-md">{item.quote}</p>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="mt-10 text-dark-text bg-[#E4E4E4] flex items-center justify-center rounded-full max-w-[315px] w-full py-2 px-5 font-semibold">
          <p>
            {translateQuote[analysisLanguage] || translateQuote.en}
            {' '}
            {current}
            /
            {data?.length}
          </p>
        </div>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    ) : (
      <div className="flex flex-col items-center justify-center w-full max-w-5xl">
        <div className="flex flex-col items-center w-full gap-2">
          <Skeleton className="w-[50%] h-4 rounded-sm" />
          <Skeleton className="w-[30%] h-4 rounded-sm" />
        </div>
        <Skeleton className="w-[30%] h-7 rounded-full mt-6" />
      </div>
    )
  );
}

export default InterestingQuotes;

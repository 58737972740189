import { IconInfoCircleFilled } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

interface IText {
  text: string
  className?: string;
}

function ToolTip({ text, className }: IText) {
  const { currentTheme } = useTheme();

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild className="cursor-help">
          <IconInfoCircleFilled size={20} style={{ color: currentTheme?.primary }} />
        </TooltipTrigger>
        <TooltipContent
          style={{ border: `3px solid ${currentTheme?.primary}` }}
          className={cn('mr-5', className)}
        >
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default ToolTip;

/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Button, buttonVariants } from '@/components/ui/button';
import Image from '@/components/atoms/Image';
import TeletLogo from '@/assets/logo/tellet-logo-main.svg';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import { toast } from '@/components/ui/use-toast';
import useAuthStore from '@/store/AuthStore';

const defaultValues = {
  email: '',
};

type TForgotPasswordForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

function ForgotPassword() {
  const {
    register,
    handleSubmit, reset,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const authToken = useAuthStore((state) => state.authToken);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const onSubmit = async (_data: TForgotPasswordForm) => {
    try {
      await axiosInstanceNoAuth.post('/users/forgot_password', _data);
      toast({
        title: '✅ We have sent you an email with a password reset link.',
      });
      reset();
    } catch {
      toast({
        variant: 'destructive',
        title: 'Error. Please try again.',
      });
    }
  };

  useEffect(() => {
    if (authToken) {
      navigate(from, { replace: true });
    }
  }, [authToken, navigate, from]);

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[550px] min-h-screen center border-0 py-4 px-2 min450:max-w-full min450:bg-white">
        <div className="flex flex-col items-center w-full max-w-[550px] gap-10 bg-white rounded-md shadow-md min450:shadow-none h-fit p-7 min450:p-5">
          <div className="flex flex-col items-center justify-center gap-4 text-center">
            <Image effect="blur" src={TeletLogo} alt="logo" width={120} height={40} />
            <p className="text-[#5a6a85] leading-6 mt-6">
              Please enter the email address associated with your account and we will email you a
              link to reset your password.
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-start w-full h-fit"
          >
            <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
              Email
              <Input hasError={!!errors.email} {...register('email')} type="email" />
              {errors.email?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.email?.message}
                </p>
              )}
            </label>
            <Button type="submit" className="w-full mt-5 text-sm font-semibold rounded-md">
              Forgot Password
            </Button>
            <NavLink
              to="/login"
              className={buttonVariants({
                variant: 'ghost',
                className: 'w-full text-center font-semibold text-sm mt-4 mb-6',
              })}
            >
              Back to Login
            </NavLink>
          </form>
        </div>
      </div>
    </main>
  );
}

export default ForgotPassword;

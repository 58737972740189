import { FC, useEffect, useRef } from 'react';
import videojs from 'video.js';

// Styles
import 'video.js/dist/video-js.css';

const initialOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};
interface VideoJsPlayer {
  dispose: () => void;
  ready: (callback: () => void) => void;
}

// eslint-disable-next-line react/function-component-definition, @typescript-eslint/no-explicit-any
const VideoPlayer: FC<any> = ({ options }) => {
  const videoNode = useRef<HTMLVideoElement | null>(null);
  const player = useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    if (videoNode.current) {
      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...options,
      }).ready(() => {
        // console.log('onPlayerReady', this);
      });
    }

    return () => {
      if (player.current) {
        player?.current.dispose();
      }
    };
  }, [options]);

  return (
    <video
      style={{
        objectFit: 'cover',
        aspectRatio: '16 / 9',
      }}
      ref={videoNode}
      className="video-js"
    >
      <track kind="captions" />
    </video>
  );
};

export default VideoPlayer;

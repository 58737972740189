import { ChangeEvent, useState } from 'react';
import { IconPencil } from '@tabler/icons-react';
import Image from '../Image';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { getInitials } from '@/utils/stringHelpers';

export interface IAvatarWithImageUploadProps {
  onImageUpload: (image: File | undefined) => Promise<void>;
  name: string;
  src: string | undefined;
  className: string | undefined;
}

function AvatarWithImageUpload({
  onImageUpload,
  name,
  className,
  src,
}: IAvatarWithImageUploadProps) {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setIsUploading(true);
    await onImageUpload(file);
    setIsUploading(false);
    setImagePreview(URL.createObjectURL(file));
  };

  return (
    <div className={`${className || ''} relative`}>
      <label htmlFor="avatarID">
        <div
          className={`absolute w-28 h-28 border-t-2 border-primary rounded-full ${
            isUploading ? 'animate-spin z-10' : ''
          }`}
        />
        <Avatar className="w-28 h-28 border-light-gray border">
          {src || imagePreview ? (
            <Image
              src={src || imagePreview || ''}
              alt="avatar"
              style={{
                objectFit: 'cover',
              }}
            />
          ) : null}
          <AvatarFallback className="border border-light-gray bg-gray-50 font-bold">
            <span className="text-[#2a3547] text-4xl">{getInitials(name)}</span>
          </AvatarFallback>
        </Avatar>
        <input
          id="avatarID"
          multiple={false}
          className="hidden"
          type="file"
          onChange={handleUpload}
        />
        <div className="absolute bottom-3 right-0.5 z-10 w-6 h-6 bg-light-gray rounded-full center">
          <IconPencil size={14} className="text-gray-50" />
        </div>
      </label>
    </div>
  );
}

export default AvatarWithImageUpload;

import { useEffect, useState } from 'react';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import * as Yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import Image from '@/components/atoms/Image';
import Routes from '@/router/routes';
import { toast } from '@/components/ui/use-toast';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import useAuthStore from '@/store/AuthStore';
import { cn } from '@/lib/utils';

interface FormValues {
  name: string;
  last_name: string;
  phone_number: string;
  email: string;
  password: string;
  confirm_password: string;
  tos: boolean;
}

const defaultValues = {
  name: '',
  last_name: '',
  phone_number: '',
  email: '',
  password: '',
  confirm_password: '',
  tos: false,
};

type TSignUpForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  phone_number: Yup.string().required('Phone number is required').min(8, 'Invalid phone number').max(20, 'Invalid phone number'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('This field is required'),
  tos: Yup.bool()
    .oneOf([true], 'Please accept the Terms of Service.')
    .required('Please accept the Terms of Service.'),
});

function SignUp() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const [signUpError, setSignUpError] = useState('');
  const authToken = useAuthStore((state) => state.authToken);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  // eslint-disable-next-line consistent-return
  const onSubmit: SubmitHandler<FormValues> = async (formData: TSignUpForm) => {
    try {
      const email = formData?.email?.toLowerCase();
      const data = { ...formData, email };
      const response = await axiosInstanceNoAuth.post('/users/register', data);
      toast({
        title: 'Please verify your email',
        description: 'Please check your email to confirm registration.',
      });
      setSignUpError('');
      navigate(`${Routes.check_your_email.path}?email=${response?.data}`);
      return response;
    } catch (err) {
      setSignUpError('This email is already in use.');
    }
  };

  useEffect(() => {
    if (authToken) {
      navigate(from, { replace: true });
    }
  }, [authToken, navigate, from]);

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[700px] min-h-screen center border-0 py-4 px-3 min450:max-w-full min450:bg-white">
        <div className="flex flex-col items-center w-full max-w-[700px] gap-10 bg-white rounded-md h-fit p-7 min450:p-5">
          <Image effect="blur" src={TeletLogo} alt="logo" width={120} height={40} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="relative flex flex-col items-start w-full gap-5 h-fit"
          >
            <div className="flex items-center w-full gap-4">
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                First name
                <Input hasError={!!errors.name} {...register('name')} type="text" />
                {errors.name?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.name?.message}
                </p>
                )}
              </label>
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Last name
                <Input hasError={!!errors.last_name} {...register('last_name')} type="text" />
                {errors.last_name?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.last_name?.message}
                </p>
                )}
              </label>
            </div>

            <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
              Phone number
              <div className="flex items-center w-full">
                <Controller
                  name="phone_number"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      className={cn(
                        'rounded-md border border-natural-700 transition-all gap-2 flex h-10 w-full shadow-sm text-gray-700 focus-within:text-gray-900 bg-gray-50 px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary disabled:pointer-events-none disabled:bg-gray-200 disabled:text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900',
                        !!errors.phone_number && 'border-red-600',
                      )}
                      placeholder="Enter phone number"
                      value={field?.value}
                      onChange={field?.onChange}
                      defaultCountry="NL"
                      international
                      countryCallingCodeEditable={false}
                    />
                  )}
                />
              </div>
              {errors.phone_number?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.phone_number?.message}
                </p>
              )}
            </label>
            <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
              Email
              <Input
                hasError={!!errors.email}
                {...register('email')}
                type="text"
              />
              {errors.email?.message && (
                <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
                  {errors.email?.message}
                </p>
              )}
            </label>
            <div className="flex items-center w-full gap-4 mb-2">
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Password
                <Input
                  hasError={!!errors.password}
                  {...register('password')}
                  type="password"
                />
                {errors.password?.message && (
                <p className="absolute left-0 text-sm text-red-600 -bottom-5">
                  {errors.password?.message}
                </p>
                )}
              </label>
              <label className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
                Confirm Password
                <Input
                  hasError={!!errors.confirm_password}
                  {...register('confirm_password')}
                  type="password"
                />
                {errors.confirm_password?.message && (
                <p className="absolute left-0 text-sm text-red-600 -bottom-5">
                  {errors.confirm_password?.message}
                </p>
                )}
              </label>
            </div>

            <label className="relative flex flex-col items-start w-fit h-fit">
              <div className="flex cursor-pointer items-center gap-1.5 whitespace-nowrap">
                <Input
                  {...register('tos')}
                  type="checkbox"
                  className="cursor-pointer mt-0.5 h-fit"
                />
                <p className="text-sm select-none text-dark-text">
                  I agree on the
                  {' '}
                  <a
                    target="_blank"
                    href="https://tellet.ai/terms-of-service"
                    className="underline text-primary"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </p>
              </div>
              {errors?.tos?.message && (
                <p className="absolute left-0 text-sm text-red-600 whitespace-nowrap -bottom-5">
                  {errors?.tos?.message}
                </p>
              )}
            </label>
            {signUpError && (
              <p className="absolute text-sm font-medium text-red-600 left-0 bottom-[50px]">
                {signUpError}
              </p>
            )}
            <Button
              disabled={isSubmitting}
              variant="default"
              type="submit"
              className="flex items-center gap-0.5 w-full mt-1.5 mb-1 text-sm font-semibold text-center ease-in rounded-md"
            >
              {isSubmitting && <IconLoader2 className="w-4 h-4 mr-2 animate-spin" />}
              Sign Up
            </Button>
          </form>
          <p className="text-sm">
            Already a member?
            {' '}
            <NavLink to="/login" className="font-semibold text-primary hover:underline">
              Login now.
            </NavLink>
          </p>
        </div>
      </div>
    </main>
  );
}

export default SignUp;

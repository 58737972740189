import React, { useState } from 'react';
import { IconArrowBack, IconBulb, IconSearch, IconX } from '@tabler/icons-react';
import { Input } from '../ui/input';

interface IScratchProps {
  setCreationType: React.Dispatch<React.SetStateAction<string>>
  closeModalAndRemoveData:() => void;
}

const allTemplates = [
  {
    name: 'Product feedback',
  },
  {
    name: 'Popular questions',
  },
  {
    name: 'Marketing research',
  },
  {
    name: 'Template 1',
  },
  {
    name: 'Template 2',
  },
];

function CreateFromTemplate({ setCreationType, closeModalAndRemoveData }: IScratchProps) {
  const [templateSearch, setTemplateSearch] = useState('');

  const filteredTemplates = templateSearch
    ? allTemplates.filter(
      (item) => item.name.toLowerCase().includes(templateSearch.toLowerCase()),
    )
    : allTemplates;

  return (
    <div className="relative flex flex-col w-full h-full overflow-y-auto">
      <div className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between w-full pr-12 bg-white pl-7 h-14">
        {/* go back */}
        <button type="button" onClick={() => setCreationType('')}><IconArrowBack size={24} /></button>
        {/* close modal */}
        <button type="button" onClick={closeModalAndRemoveData}><IconX size={24} /></button>
      </div>
      {/* content */}
      <div className="flex w-full h-full mt-10 gap-7">
        {/* templates and search */}
        <div className="flex flex-col w-full h-full">
          {/* search */}
          <div className="flex flex-col gap-2">
            <h3 className="font-medium">Your templates</h3>
            <label className="relative w-full h-fit">
              <Input
                value={templateSearch}
                onChange={(e) => setTemplateSearch(e.target.value)}
                placeholder="Search all templates"
                className="w-full border-dark-text/30"
              />
              <IconSearch className="absolute cursor-pointer top-2 text-light-gray right-4" />
            </label>
          </div>
          <div className="grid w-full h-full grid-cols-4 max1300:grid-cols-3 md800:grid-cols-1 max1050:grid-cols-2 gap-6 mt-6 px-0.5">
            {React.Children.toArray(filteredTemplates.map((item) => (
              <button type="button" className="flex hover:scale-[1.015] shadow hover:shadow-md transition-all ease-in flex-col items-center justify-between w-full border-2 rounded-xl h-60">
                <div className="flex items-center justify-center w-full h-full p-3 bg-white rounded-t-xl">
                  <p className="font-medium text-dark-text">{item.name}</p>
                </div>
                <div className="flex items-center justify-center w-full h-20 bg-primary rounded-b-xl">
                  <p className="text-sm font-medium text-white">Use template</p>
                </div>
              </button>
            )))}
          </div>
        </div>
        {/* tips */}
        <div className="overflow-hidden w-96">
          <div className="flex flex-col w-full h-full gap-6 pt-8 pr-2">
            <div className="w-full px-3 py-4 border-2 h-fit rounded-xl">
              <p className="flex items-center text-[15px] gap-1.5 font-medium">
                <IconBulb size={26} className="mb-1" />
                Template tips
              </p>
              <ul className="w-full pl-6 mt-3 overflow-hidden list-disc">
                <li className="text-sm">Some tip</li>
                <li className="text-sm">Another awesome tip</li>
              </ul>
            </div>
            <div className="w-full px-3 py-4 border-2 h-fit rounded-xl">
              <p className="flex items-center text-[15px] gap-1.5 font-medium">
                <IconBulb size={26} className="mb-1" />
                Lorem ipsum dolor
              </p>
              <ul className="w-full pl-6 mt-3 overflow-hidden list-disc">
                <li className="text-sm">Some text</li>
                <li className="text-sm">Another awesome text</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFromTemplate;

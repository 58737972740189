import { IconX } from '@tabler/icons-react';
import { useCallback, useEffect } from 'react';
import Image from '@/components/atoms/Image';

interface IProps {
  setOpenMediaPreview: (data: boolean) => void;
  mediaPreview: string | undefined;
  setMediaPreview: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function QuestionMediaPreview({ setOpenMediaPreview, mediaPreview, setMediaPreview }: IProps) {
  const handleClosePreview = useCallback(() => {
    setOpenMediaPreview(false);
    setMediaPreview('');
  }, [setOpenMediaPreview, setMediaPreview]);

  const fileExtension = mediaPreview ? mediaPreview.split('.').pop()?.split('?')[0]?.toLowerCase() : '';
  const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension as string);
  const isVideo = ['mp4', 'webm'].includes(fileExtension as string);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClosePreview();
      }
    };

    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [handleClosePreview]);

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full bg-black/40 z-100"
    >
      <div className="relative flex items-center justify-center w-full h-full p-5 select-none">
        <button
          type="button"
          onClick={handleClosePreview}
          className="absolute right-10 top-10"
        >
          <IconX size={35} className="text-white" />
        </button>
        {
          isImage
          && <Image effect="blur" src={mediaPreview} width={700} height="auto" alt="image preview" />
        }
        {
          isVideo && (
            <video controls width={700}>
              <source src={mediaPreview} type={`video/${fileExtension}`} />
              <track kind="captions" srcLang="en" label="English captions" />
              Your browser does not support the video tag.
            </video>
          )
        }
      </div>
    </div>
  );
}

export default QuestionMediaPreview;

import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ToolTip from '@/components/tooltip/ToolTip';
import { LangContext } from '@/context/LangProvider';
import { useMutateProjectUpdate } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import { EProjectStatus } from '@/enums';
import { Input } from '@/components/ui/input';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { cn } from '@/lib/utils';

interface IformValues {
  respondents_max: number | null;
}

const validationSchema = Yup.object().shape({
  respondents_max: Yup.number().required('This field is required')
    .min(1, 'Number must be greater than 0').max(5000, 'Number must be less than 5000')
    .nullable()
    .typeError('This field is required'),
});

interface IProjectShareMax {
  singleProjectData: IProjectProps;
}

function ProjectShareMaxRespondets({ singleProjectData }: IProjectShareMax) {
  const { lang } = useContext(LangContext);
  const { project_id, workspace_id } = useParams();
  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const { isUserViewer } = useWorkspaceRoles(workspace_id);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { respondents_max: singleProjectData?.respondents_max || null,
    },
    resolver: yupResolver(validationSchema),
  });
  const { mutateAsync } = useMutateProjectUpdate();

  const onSubmit: SubmitHandler<IformValues> = async (data: IformValues) => {
    try {
      await mutateAsync({
        _id: project_id,
        ...data,
      });
    } catch (error) {
      toast({ description: lang.get('msg.errorPleaseTryAgain') });
    }
  };

  return (
    <div className="w-full h-full">
      <form className="flex flex-col w-full gap-6 pb-10">
        <label className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <ToolTip text="Add max number of the respondents" className="ml-5" />
            <p className="flex items-center gap-1 text-base font-medium">
              {lang.get('msg.maximumNumberOfRespondets')}
              <span className="text-red-600">*</span>
            </p>
          </div>
          <Input
            type="number"
            disabled={isUserViewer || isProjectPublished || isProjectCompleted}
            {...register('respondents_max', {
              onChange: () => setTimeout(handleSubmit(onSubmit), 2000),
            })}
            placeholder="Add maximum number of the respondents"
            hasError={!!errors?.respondents_max?.message}
          />
          <p className={cn(errors?.respondents_max?.message ? 'flex' : 'hidden', 'text-red-600 text-xs font-semibold')}>
            {errors?.respondents_max?.message as string}
          </p>
        </label>
      </form>
    </div>
  );
}

export default ProjectShareMaxRespondets;
